import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/Context";
import PropTypes from "prop-types";
import ProjectsService from "../../Services/ProjectsService";
import SwitchButton from "../SwitchButton";
import "./style.scss";
import Loading from "../Loading";

const CardReport = ({ dataFirebase, projectId, reportData }) => {
  const { userInformation } = useContext(Context);
  const [statusActiveSurvey, setStatusActiveSurvey] = useState(null);
  const [answerAvgTime, setAnswerAvgTime] = useState(0);
  const [loading, setLoading] = useState(true);

  const getAvgTime = async (dataFirebase) => {
    if (dataFirebase.questions.length === 0) {
      return;
    }

    let lastQuestion =
      dataFirebase.questions[dataFirebase.questions.length - 1].number;
    let avgAnswer = await ProjectsService.getAvgTimeAnswer(
      projectId,
      userInformation.email,
      lastQuestion < 10 ? `q_0${lastQuestion}` : `q_${lastQuestion}`
    );
    setAnswerAvgTime(avgAnswer.avg);
    setLoading(false);
  };

  useEffect(() => {
    if (dataFirebase !== null) {
      getAvgTime(dataFirebase);
    }
    // eslint-disable-next-line
  }, [dataFirebase]);

  const getProjectIsOpen = async () => {
    let responseGetStatusActiveFromProjects =
      await ProjectsService.getStatusActiveFromProjects(
        [projectId],
        userInformation.email,
        userInformation.subscription
      );
    if (responseGetStatusActiveFromProjects !== false) {
      if (responseGetStatusActiveFromProjects.data[0].status_closed === 1) {
        setStatusActiveSurvey(false);
      } else {
        setStatusActiveSurvey(true);
      }
    }
  };

  useEffect(() => {
    if (userInformation !== null && statusActiveSurvey === null) {
      getProjectIsOpen();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  /*****************************************************************************
   * Functions aux here
   *****************************************************************************/

  const handleSearchIsOpen = () => {
    ProjectsService.setClosingProject(
      projectId,
      userInformation.email,
      !statusActiveSurvey ? 0 : 1,
      userInformation.subscription
    );
    setStatusActiveSurvey(!statusActiveSurvey);
  };

  const handleAvgAnswer = () => {
    return reportData?.answers.day.length > 0
      ? parseInt(answerAvgTime) > 60
        ? `${Math.round(parseInt(answerAvgTime) / 60)} min`
        : `${parseInt(answerAvgTime)} seg`
      : "0";
  };

  const handleQtdAnswer = () => {
    return reportData?.answers.year.length > 0
      ? reportData?.answers.year.reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue.qtd);
        }, 0)
      : "0";
  };

  const percentComplete = () => {
    if (reportData !== null) {
      let lastQuestionValues = Object.values(reportData).pop();
      let lastQuestionKey = Object.keys(reportData).pop();
      let lastQuestionTotal = lastQuestionValues.find(
        (element) => element[lastQuestionKey] === "Total"
      );
      let firstQuestionTotal = reportData["q_01"].find(
        (element) => element["q_01"] === "Total"
      );

      return ((lastQuestionTotal.qtd / firstQuestionTotal.qtd) * 100).toFixed(
        0
      );
    }
  };

  if (loading) {
    return (
      <div className="compCardReport">
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="compCardReport">
        <div className="header">
          {statusActiveSurvey ? (
            <span className="text">Pesquisa ativa</span>
          ) : (
            <span className="text desativated">Pesquisa desativada</span>
          )}
          <div className="toogle">
            <SwitchButton
              active={statusActiveSurvey}
              type="default"
              onChange={handleSearchIsOpen}
            />
            <span>Aceitar respostas</span>
          </div>
        </div>
        <span className="title">Satisfação Atendimento</span>
        <div className="line">
          <div className="item">
            <span className="value">{handleAvgAnswer()}</span>
            <span className="name">Tempo médio</span>
          </div>
          <div className="item">
            <span className="value">{dataFirebase.questions.length}</span>
            <span className="name">Perguntas</span>
          </div>
        </div>
        <div className="line">
          <div className="item">
            <span className="value">{handleQtdAnswer()}</span>
            <span className="name">Respostas</span>
          </div>
          <div className="item">
            <span className="value">{percentComplete()}%</span>
            <span className="name">Completo</span>
          </div>
        </div>
      </div>
    );
  }
};

CardReport.propTypes = {
  dataFirebase: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  reportData: PropTypes.object.isRequired,
};

export default CardReport;
