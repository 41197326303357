import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as BookmarkRr } from "../../Assets/img/regular/fi-rr-bookmark.svg";
import { ReactComponent as BookmarkSr } from "../../Assets/img/solid/fi-sr-bookmark.svg";
import "./style.scss";

const Notification = ({
  title,
  message,
  date,
  link,
  linkName,
  newMessage,
  saved,
  onClickSaved,
}) => {
  const handleLink = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  return (
    <div
      className={
        newMessage ? "compNotification unread" : "compNotification read"
      }
      data-testid={newMessage ? "unread-notification" : "read-notification"}
    >
      <div className="contentNotification">
        {newMessage === 1 && <div className="dot"></div>}
        <div className="textNotification">
          <div className="divText">
            <span className="title">{title}</span>
            <span className="message">{message}</span>
            {link && (
              <button className="link" onClick={() => handleLink(link)}>
                {linkName}
              </button>
            )}
          </div>
          <span className="date">{date}</span>
        </div>
      </div>
      <button
        className="divIcon"
        onClick={onClickSaved}
        data-testid="bookmark-button"
      >
        {saved ? (
          <BookmarkSr
            className="icon saved"
            data-testid="bookmark-solid-icon"
          />
        ) : (
          <BookmarkRr className="icon" data-testid="bookmark-regular-icon" />
        )}
      </button>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  newMessage: PropTypes.number.isRequired,
  saved: PropTypes.number.isRequired,
  onClickSaved: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  message: "",
  date: "",
  newMessage: 0,
  saved: 0,
  onClickSaved: () => {},
};

export default Notification;
