import React, { useContext, useEffect, useState } from "react";
import "../style.scss";
import { Context } from "../../../Context/Context";
import MenuEditQuestionOff from "../../MenuEditQuestionOff";
import MenuEditQuestionOn from "../../MenuEditQuestionOn";
import JsonDictionaryService from "../../../Services/JsonDictionaryService";

const DisclaimerInitial = (props) => {
  const {
    addQuestion,
    currentLastQuestion,
    lastQuestion,
    projectId,
    questionNumber,
    questionValues,
    setQuestionValues,
    validQuestions,
  } = props;
  const { chatbot, setChatbot } = useContext(Context);
  const [error, setError] = useState(false);

  const handleCancel = () => {
    if (chatbot.disclaimer_initial.value !== "") {
      setQuestionValues({
        ...chatbot,
        disclaimer_initial: {
          ...chatbot.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
    }
  };

  const handleSave = async () => {
    let errorExists = validQuestions();
    if (!errorExists) {
      setChatbot({
        ...chatbot,
        disclaimer_initial: {
          edit: 0,
          value: questionValues.disclaimer_initial.value,
          error: false,
        },
      });
      setQuestionValues({
        ...questionValues,
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      let projectJson = JSON.stringify({
        ...questionValues,
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const handleSaveAndNew = async () => {
    let errorExists = validQuestions();
    if (!errorExists) {
      let arrayTemp = [...questionValues.questions];
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        edit: 0,
      };
      setChatbot({
        ...chatbot,
        questions: arrayTemp,
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      setQuestionValues({
        ...questionValues,
        questions: [
          {
            message: "",
            number: parseInt(questionValues.questions.length) + 1,
            options: [],
            type: "",
            edit: 1,
          },
          ...arrayTemp,
        ],
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      let projectJson = JSON.stringify({
        ...questionValues,
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 0,
          error: false,
        },
      });
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const handleEdit = () => {
    let errorExists = validQuestions();
    if (!errorExists) {
      setQuestionValues({
        ...questionValues,
        disclaimer_initial: {
          ...questionValues.disclaimer_initial,
          edit: 1,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e !== null) {
      if (e.target !== null) {
        e.target.style.height = `${e.target.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    const validError = () => {
      if (questionValues.disclaimer_initial.value === "") {
        setError(true);
      }
    };

    if (questionValues.disclaimer_initial.error) {
      validError();
    } else {
      setError(false);
    }
    // eslint-disable-next-line
  }, [questionValues]);

  if (!questionValues.disclaimer_initial.edit) {
    return (
      <div className="defaultQuestionLayout">
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility: lastQuestion === 0 ? "visible" : "hidden",
          }}
        />
        <div className="mainAreaDefault">
          <div className="titleDefault">Boas Vindas</div>
          <div className="mainSpace">
            <p className="mainText">
              {questionValues.disclaimer_initial.value}
            </p>
          </div>
          <MenuEditQuestionOff
            disclaimerInitial={true}
            onClickEdit={handleEdit}
            onClickAdd={() => addQuestion("welcome")}
            lastQuestion={currentLastQuestion === 0 ? true : false}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="defaultQuestionLayout">
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility: lastQuestion === 0 ? "visible" : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="mainAreaDefault">
            <div className="titleDefault">Boas Vindas</div>
            <div className="mainSpace" id="DisclaimerInicial">
              <textarea
                rows="2"
                className={
                  error
                    ? "textareaDefault textareaDefaultRed"
                    : "textareaDefault"
                }
                value={questionValues.disclaimer_initial.value}
                placeholder="Compartilhe sua voz, suas opiniões e ideias. Juntos, podemos criar algo extraordinário."
                onChange={(event) => {
                  setError(false);
                  setQuestionValues({
                    ...questionValues,
                    disclaimer_initial: {
                      ...questionValues.disclaimer_initial,
                      value: event.target.value,
                      error: false,
                    },
                  });
                  handleKeyDown(event);
                }}
              />
            </div>
            <MenuEditQuestionOn
              onClickCrossCircle={handleCancel}
              onClickCheck={handleSave}
              onClickAdd={handleSaveAndNew}
              lastQuestion={currentLastQuestion === 0 ? true : false}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default DisclaimerInitial;
