import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import AuthService from "../../Services/AuthService";
import Button from "../../Components/Button";
import Input from "../../Components/Input";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleForgetPassword = async () => {
    let loginValues = {
      emailLogin: email,
    };

    if (email.match(/.+@.+\..+/g)) {
      await AuthService.resetPassword(loginValues.emailLogin).then(() => {
        history.goBack();
      });
    }
  };

  return (
    <div className="CompForgotPassword">
      <header>
        <h1>Recuperar senha</h1>
        <p>
          Informe seu e-mail cadastrado que vamos enviar um link para você criar
          uma nova senha.
        </p>
      </header>
      <div className="content">
        <Input
          label="E-mail cadastrado"
          layout={
            email.match(/.+@.+\..+/g) || email === "" ? "default" : "error"
          }
          placeholder="Ex: usuario@teste.com.br"
          setValue={setEmail}
          type="text"
          value={email}
        />
      </div>
      <footer>
        <Button
          dataTestid="submit-button"
          type="default"
          size="large"
          variant={email.match(/.+@.+\..+/g) ? "primary" : "disable"}
          onClick={email.match(/.+@.+\..+/g) ? handleForgetPassword : null}
        >
          Enviar instruções
        </Button>
        <Button
          type="auxiliary"
          size="large"
          variant="primary"
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </footer>
    </div>
  );
};

export default ForgotPassword;
