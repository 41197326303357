import React, { useState } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { ReactComponent as FiSrShieldCheck } from "../../Assets/img/solid/fi-sr-shield-check.svg";
import { ReactComponent as FiSrCommentInfo } from "../../Assets/img/solid/fi-sr-comment-info.svg";
import { ReactComponent as FiSrShieldExclamation } from "../../Assets/img/solid/fi-sr-shield-exclamation.svg";
import { ReactComponent as FfRiCrossCircle } from "../../Assets/img/regular/fi-rr-cross-circle.svg";

/*******************************************************************************
 * How to use:
 * <Alerts type="Success" message="sucess" open={true} />
 * <Alerts type="Info" message="info" open={true} />
 * <Alerts type="Error" message="error" open={true} />
 * <Alerts type="Warning" message="warning" open={true} />
 ******************************************************************************/

const Alerts = (props) => {
  const { message, open, removeClose, type, toggle } = props;
  const [openAlerts, setOpenAlerts] = useState(open);

  const handleType = (type) => {
    switch (type.toString().toLowerCase()) {
      case "success":
        return (
          <FiSrShieldCheck className="success" data-testid="success-icon" />
        );
      case "info":
        return <FiSrCommentInfo className="info" data-testid="info-icon" />;
      case "error":
        return (
          <FiSrShieldExclamation className="error" data-testid="error-icon" />
        );
      case "warning":
        return (
          <FiSrShieldExclamation
            className="warning"
            data-testid="warning-icon"
          />
        );
      default:
        return null;
    }
  };

  const toggleIn = () => {
    if (toggle !== undefined) {
      toggle();
    }
    setOpenAlerts(false);
  };

  if (openAlerts) {
    return (
      <div className={`compAlerts ${type.toString().toLowerCase()}Alerts`}>
        {handleType(type)}
        <span>{message}</span>
        {removeClose ? null : (
          <FfRiCrossCircle
            className="close"
            data-testid="close-icon"
            onClick={toggleIn}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

Alerts.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["Success", "Info", "Error", "Warning"]).isRequired,
};

export default Alerts;
