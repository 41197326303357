import React from "react";
import "./style.scss";
import OptionConfiguration from "../OptionConfiguration";
import Button from "../../Button";
import { ReactComponent as FiSrShumbsDown } from "../../../Assets/img/solid/fi-sr-thumbs-down.svg";
import { ReactComponent as FiSrShumbsUp } from "../../../Assets/img/solid/fi-sr-thumbs-up.svg";

const LikeDislike = (props) => {
  const {
    handleAddOption,
    handleClickInput,
    handleColor,
    handleKeyDown,
    handleOptionValue,
    handleRemoveOption,
    moveDownOption,
    moveUpOption,
    questionNumber,
    questionValues,
    setTooltipOpen,
  } = props;

  return (
    <div className="compLikeDislike">
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 1:</div>
        <FiSrShumbsUp className="iconDefault likeIcon" />
        <div>(Like/Curti/Gostei)</div>
      </div>
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 2:</div>
        <FiSrShumbsDown className="iconDefault dislikeIcon" />
        <div>(Dislike/Não Curti/Não Gostei)</div>
      </div>
      {questionValues.questions[questionNumber].options.map((option, index) => {
        if (index > 1) {
          return (
            <div key={index}>
              <OptionConfiguration
                option={option}
                index={index}
                questionNumber={questionNumber}
                handleColor={handleColor}
                setTooltipOpen={setTooltipOpen}
                handleOptionValue={handleOptionValue}
                handleClickInput={handleClickInput}
                handleKeyDown={handleKeyDown}
                handleRemoveOption={handleRemoveOption}
                moveDownOption={moveDownOption}
                moveUpOption={moveUpOption}
              />
            </div>
          );
        }
        return null;
      })}
      <Button
        onClick={() => handleAddOption(questionNumber)}
        size="small"
        type="auxiliary"
        variant="primary"
      >
        Adicionar Opção
      </Button>
    </div>
  );
};

export default LikeDislike;
