import { ReactComponent as FiRrTrash } from "../../Assets/img/regular/fi-rr-trash.svg";
import { ReactComponent as FiRrCopy } from "../../Assets/img/regular/fi-rr-copy.svg";
import { ReactComponent as FiSrArrowDown } from "../../Assets/img/solid/fi-sr-arrow-down.svg";
import { ReactComponent as FiSrArrowUp } from "../../Assets/img/solid/fi-sr-arrow-up.svg";
import { ReactComponent as FiRrEdit } from "../../Assets/img/regular/fi-rr-edit.svg";
import { ReactComponent as FiRrAdd } from "../../Assets/img/regular/fi-rr-add.svg";
import "./style.scss";

function MenuEditQuestionOff(props) {
  const {
    disclaimerInitial,
    onClickTrash,
    onClickCopy,
    onClickArrowDown,
    onClickArrowUp,
    onClickEdit,
    onClickAdd,
    lastQuestion,
  } = props;

  return (
    <div className="compMenuEditQuestionOff">
      {!disclaimerInitial && (
        <FiRrTrash
          className="iconCompMenuEditQuestionOff red"
          onClick={onClickTrash}
        />
      )}
      {!disclaimerInitial && (
        <FiRrCopy
          className="iconCompMenuEditQuestionOff"
          onClick={onClickCopy}
        />
      )}
      {!disclaimerInitial && (
        <FiSrArrowDown
          className="iconCompMenuEditQuestionOff"
          onClick={onClickArrowDown}
        />
      )}
      {!disclaimerInitial && (
        <FiSrArrowUp
          className="iconCompMenuEditQuestionOff"
          onClick={onClickArrowUp}
        />
      )}
      <FiRrEdit className="iconCompMenuEditQuestionOff" onClick={onClickEdit} />
      <FiRrAdd
        className={
          lastQuestion
            ? "iconCompMenuEditQuestionOff blue"
            : "iconCompMenuEditQuestionOff"
        }
        onClick={onClickAdd}
      />
    </div>
  );
}

export default MenuEditQuestionOff;
