import React from "react";
import Button from "../Button";
import "./style.scss";

const SwitchPrice = ({
  selected,
  setSelected,
}) => {
  const discountTag = "16%";

  return (
    <div className="compSwitchPrice">
      { selected === "anual" &&
        <div className="tag">
          <span>{discountTag}</span>
        </div>
      }
      <Button
        type={
          selected === "anual"
          ? "default"
          : "auxiliary"
        }
        variant="primary"
        size="medium"
        onClick={() => setSelected("anual")}
      >
        Anual
      </Button>
      <Button
        type={selected === "mensal"
        ? "default"
        : "auxiliary"
      }
        variant="primary"
        size="medium"
        onClick={() => setSelected("mensal")}
      >
        Mensal
      </Button>
    </div>
  )
}

export default SwitchPrice;
