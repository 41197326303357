import "./style.scss";
import Button from "../Button";
import ProgressBar from "../ProgressBar";
import PropTypes from "prop-types";

import { ReactComponent as FiRrAdd } from "../../Assets/img/regular/fi-rr-add.svg";
import Hazo from "../../Assets/img/hazo-cortado.png";

/*******************************************************************************
 * How to use
 * <BottomTabHome
    max={100}
    onClickNewInteraction={() => {return null;}}
    value={50}
  />
 ******************************************************************************/

const BottomTabHome = (props) => {
  const { value, max, onClickNewInteraction } = props;

  const monthList = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const date = new Date();
  const year = date.getFullYear();
  const monthIndex = date.getMonth();

  return (
    <div className="compBottomTabHome">
      <div className="textArea">
        <p className="date">{`${monthList[monthIndex]} de ${year}`}</p>
        <ProgressBar value={value} max={max} />
        <p className="interactions">
          {`${value}/${max}`} <span>interações</span>
        </p>
      </div>
      <img src={Hazo} alt="Hazo" />
      <Button
        size="medium"
        type="default"
        variant="primary"
        buttonStyle={{ width: "175px", height: "55px" }}
        data-testid="button-create-new-interation"
        onClick={onClickNewInteraction}
      >
        <FiRrAdd className="icon" data-testid="add-icon" />
        Nova interação
      </Button>
    </div>
  );
};

BottomTabHome.propTypes = {
  max: PropTypes.number.isRequired,
  onClickNewInteraction: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

BottomTabHome.defaultProps = {
  max: 100,
  onClickNewInteraction: () => {},
  value: 0,
};

export default BottomTabHome;
