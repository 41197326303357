import { ReactComponent as FiRrCrossCircle } from "../../Assets/img/regular/fi-rr-cross-circle.svg";
import { ReactComponent as FiSrCheck } from "../../Assets/img/solid/fi-sr-check.svg";
import { ReactComponent as FiRrAdd } from "../../Assets/img/regular/fi-rr-add.svg";
import "./style.scss";

function MenuEditQuestionOn(props) {
  const { lastQuestion, onClickCrossCircle, onClickCheck, onClickAdd } = props;

  return (
    <div className="compMenuEditQuestionOn">
      <FiRrCrossCircle
        className="iconCompMenuEditQuestionOn red"
        onClick={onClickCrossCircle}
      />
      <FiSrCheck
        className="iconCompMenuEditQuestionOn"
        onClick={onClickCheck}
      />
      <FiRrAdd
        className={
          lastQuestion
            ? "iconCompMenuEditQuestionOn blue"
            : "iconCompMenuEditQuestionOn"
        }
        onClick={onClickAdd}
      />
    </div>
  );
}

export default MenuEditQuestionOn;
