import React, { useContext, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";
import Alerts from "../../Components/Alerts";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import AuthService from "../../Services/AuthService";

import { ReactComponent as FiRrCheck } from "../../Assets/img/regular/fi-rr-check.svg";
import { ReactComponent as FiRrEye } from "../../Assets/img/regular/fi-rr-eye.svg";
import { ReactComponent as FiRrEyeCrossed } from "../../Assets/img/regular/fi-rr-eye-crossed.svg";

const SignUp = () => {
  const { alert, setAlert } = useContext(Context);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  useState(false);
  const [termCheck, setTermCheck] = useState(false);

  const handleSignUp = async () => {
    let dataSignUp = {
      name: username,
      email: email.replaceAll(" ", ""),
      phone: phone,
      password: password,
      passwordConfirmation: passwordConfirmation,
    };

    if (
      dataSignUp.name === "" ||
      dataSignUp.name.length <= 3 ||
      dataSignUp.email === "" ||
      !dataSignUp.email.match(/.+@.+\..+/g) ||
      dataSignUp.phone === "" ||
      !dataSignUp.phone.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g) ||
      dataSignUp.password === "" ||
      dataSignUp.password.length < 6 ||
      dataSignUp.passwordConfirmation === "" ||
      dataSignUp.passwordConfirmation.length < 6 ||
      dataSignUp.password !== dataSignUp.passwordConfirmation
    ) {
      return;
    }

    let response = await AuthService.signUp(dataSignUp);
    if (response === "success") {
      history.push("/projects");
    } else {
      if (response === "This user already exists") {
        setAlert({
          active: true,
          type: "Error",
          message: "Esse email já está cadastrado",
        });
      }
    }
  };

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="CompSignUp">
      <div className="header">
        <h1>Cadastro</h1>
        <p>Realize o seu cadastro em menos de 1 minuto. </p>
      </div>
      {alert.active && (
        <div className="alert">
          <Alerts
            open={alert.active}
            type={alert.type}
            message={alert.message}
            toggle={() =>
              setAlert({
                active: false,
                type: "Error",
                message: "Esse email já está cadastrado",
              })
            }
          />
        </div>
      )}
      <div className="content">
        <Input
          label="Nome"
          layout={username.length > 3 || username === "" ? "default" : "error"}
          secondIcon={
            username.length > 3 ? (
              <FiRrCheck className="icon" data-testid="username-check-icon" />
            ) : null
          }
          placeholder="Nome e sobrenome"
          setValue={setUsername}
          type="text"
          value={username}
        />
        <Input
          label="E-mail"
          layout={
            email.match(/.+@.+\..+/g) || email === "" ? "default" : "error"
          }
          secondIcon={
            email.match(/.+@.+\..+/g) ? (
              <FiRrCheck className="icon" data-testid="email-check-icon" />
            ) : null
          }
          placeholder="Ex: usuario@teste.com.br"
          setValue={setEmail}
          type="text"
          value={email}
        />
        <Input
          label="Telefone"
          layout={
            phone.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g) || phone === ""
              ? "default"
              : "error"
          }
          secondIcon={
            phone.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g) ? (
              <FiRrCheck className="icon" data-testid="phone-check-icon" />
            ) : null
          }
          placeholder="Ex: (00)00000-0000"
          setValue={setPhone}
          type="number"
          value={phone}
        />
        <Input
          label="Senha"
          layout={password.length >= 6 || password === "" ? "default" : "error"}
          secondIcon={
            passwordVisible ? (
              <FiRrEye
                className="eyeicon"
                data-testid="eye-visible-icon"
                onClick={handleShowPassword}
              />
            ) : (
              <FiRrEyeCrossed
                className="eyeicon"
                data-testid="eye-invisible-icon"
                onClick={handleShowPassword}
              />
            )
          }
          placeholder="No mínimo 6 caracteres"
          setValue={setPassword}
          type={passwordVisible ? "text" : "password"}
          value={password}
        />
        <Input
          label="Confirme a senha"
          layout={
            passwordConfirmation.length >= 6 || passwordConfirmation === ""
              ? "default"
              : "error"
          }
          secondIcon={
            passwordVisible ? (
              <FiRrEye
                className="eyeicon"
                data-testid="eye-visible-icon2"
                onClick={handleShowPassword}
              />
            ) : (
              <FiRrEyeCrossed
                className="eyeicon"
                data-testid="eye-invisible-icon2"
                onClick={handleShowPassword}
              />
            )
          }
          placeholder="Repita a senha anterior"
          setValue={setPasswordConfirmation}
          type={passwordVisible ? "text" : "password"}
          value={passwordConfirmation}
        />
        <div className="term">
          <input
            type="checkbox"
            name="term"
            id="term"
            checked={termCheck}
            onChange={() => setTermCheck(!termCheck)}
          />
          <label htmlFor="term">
            Concordo que li e concordo com os{" "}
            <span onClick={() => history.push("/terms")} target="_blank">
              termos de uso
            </span>
            .
          </label>
        </div>
      </div>
      <div className="footer">
        {termCheck ? (
          <Button
            type="default"
            size="large"
            variant="primary"
            onClick={handleSignUp}
          >
            Criar conta grátis
          </Button>
        ) : (
          <Button type="default" size="large" variant="disable">
            Criar conta grátis
          </Button>
        )}

        <Button
          type="auxiliary"
          size="large"
          variant="primary"
          onClick={() => history.push("/signin")}
        >
          Acessar o Hazo
        </Button>
      </div>
    </div>
  );
};

export default SignUp;
