import React, { useCallback, useState } from "react";
import "./style.scss";
import Button from "../../Button";
import Cropper from "react-easy-crop";

const ModalCropImage = (props) => {
  const { image, modal, setImage, setInputAvatarController, setModal } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setImage(croppedImage);
      setInputAvatarController(null);
      toggle();
    } catch (e) {}
    // eslint-disable-next-line
  }, [croppedAreaPixels]);

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    let image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(data, 0, 0);

    let blobToCreateImage = await new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/png");
    });
    image = await createImage(blobToCreateImage);

    canvas.width = image.width * (150 / image.width);
    canvas.height = image.height * (150 / image.height);
    ctx.drawImage(image, 0, 0, 150, 150);

    return await new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/png");
    });
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const toggle = () => {
    setModal(!modal);
  };

  if (!modal) {
    return null;
  } else {
    return (
      <div className="compModalCropImage">
        <div className="crop-container">
          <Cropper
            aspect={1}
            crop={crop}
            image={image}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            zoom={zoom}
          />
        </div>
        <div className="controls">
          <div className="buttonArea">
            <Button
              type="auxiliary"
              size="medium"
              variant={"primary"}
              onClick={() => toggle()}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              size="medium"
              variant={"primary"}
              onClick={() => showCroppedImage()}
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    );
  }
};

export default ModalCropImage;
