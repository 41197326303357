import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import "./style.scss";
import { Context } from "../../Context/Context";
import { ReactComponent as FiRrEye } from "../../Assets/img/regular/fi-rr-eye.svg";
import { ReactComponent as FiRrEyeCrossed } from "../../Assets/img/regular/fi-rr-eye-crossed.svg";
import UserService from "../../Services/UserService";
import Alert from "../../Components/Alerts";
import Button from "../../Components/Button";
import Center2Icon from "../../Components/TopBar/Center2Icon";
import Loading from "../../Components/Loading";
import ModalDefaultToSetPassword from "../../Components/Modals/ModalDefault";
import Input from "../../Components/Input";

const Profile = () => {
  // const history = useHistory();
  const { updateUserInformation, userInformation } = useContext(Context);
  const [password, setPassword] = useState("");
  const [isOpenModalToSetPassword, setIsOpenModalToSetPassword] =
    useState(false);
  const [toggleAlert, setToggleAlert] = useState({
    type: "",
    message: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  useState(false);
  const [phone, setPhone] = useState("");
  const itemsInput = [
    {
      firstIcon: <></>,
      label: "Nome",
      layout: "after",
      placeholder: "",
      secondIcon: <></>,
      setValue: (value) => {
        setName(value);
      },
      type: "text",
      value: name,
    },
    {
      firstIcon: <></>,
      label: "E-mail",
      layout: "after",
      placeholder: "",
      secondIcon: <></>,
      setValue: (value) => {
        setEmail(value);
      },
      type: "text",
      value: email,
    },
    {
      firstIcon: <></>,
      label: "Celular",
      layout: "after",
      placeholder: "11 999999999",
      secondIcon: <></>,
      setValue: (value) => {
        setPhone(value);
      },
      type: "number",
      value: phone,
    },
  ];

  const handleChangeProfile = async () => {
    if (validationInputs() === false) {
      return false;
    }

    // Update fields
    if (name !== "") {
      if (loading === false) setLoading(true);
      let responseUpdateUserNameOnFirebaseStore =
        await UserService.updateUserNameOnFirebaseStore(name);
      if (responseUpdateUserNameOnFirebaseStore.code === "success") {
        setLoading(false);
        setToggleAlert({
          active: true,
          type: "Success",
          message: "Campo(s) atualizado com sucesso!",
        });
      }
    }

    if (phone !== "") {
      if (loading === false) setLoading(true);
      let responseUpdateUserPhoneOnFirebaseStore =
        await UserService.updateUserPhoneOnFirebaseStore(phone);
      if (responseUpdateUserPhoneOnFirebaseStore.code === "success") {
        setLoading(false);
        setToggleAlert({
          active: true,
          type: "Success",
          message: "Campo(s) atualizado com sucesso!",
        });
      }
    }

    if (email !== "") {
      setIsOpenModalToSetPassword(true);
    }
  };

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const updateEmailOnAllServices = async (password) => {
    try {
      setLoading(true);
      let responseUpdateUserEmail = await UserService.updateUserEmail(
        userInformation.email, // current email
        email, // Email to update
        password // Password of user for update email
      );
      if (responseUpdateUserEmail.code === "success") {
        setIsOpenModalToSetPassword(false);
        await updateUserInformation();
        setLoading(false);
        setToggleAlert({
          active: true,
          type: "Success",
          message: "Campo(s) atualizado com sucesso!",
        });
        // history.push("/account");
      } else if (responseUpdateUserEmail.code === "auth/wrong-password") {
        throw new Error("Não foi possível alterar seu email. Senha incorreta!");
      } else if (responseUpdateUserEmail.code === "auth/too-many-requests") {
        throw new Error(
          "Voce fez várias tentativas. Tente novamente mais tarde!"
        );
      } else if (responseUpdateUserEmail.code === "auth/email-already-in-use") {
        throw new Error("Este email já está sendo usado por outra conta.");
      }
    } catch (error) {
      setLoading(false);
      setToggleAlert({
        active: true,
        type: "Error",
        message: error.message,
      });
    }
  };

  const validationInputs = () => {
    if (name === "" && phone === "" && email === "") {
      return false;
    }

    if (name !== "" && name.length < 3) {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "O campo Nome necessita mais de 3 letras",
      });
      return false;
    }

    if (email !== "" && !email.match(/.+@.+\..+/g)) {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "Digite o email no formato correto por favor",
      });
      return false;
    }

    if (phone !== "" && !phone.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g)) {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "Digite um telefone celular no formato correto por favor",
      });
      return false;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setToggleAlert({
        ...toggleAlert,
        active: false,
      });
    }, 4000);

    return () => clearTimeout(timer);
  }, [toggleAlert]);

  return (
    (loading && <Loading />) || (
      <>
        <header className="headerProfile">
          <Center2Icon titlePage="Dados Pessoais" />
        </header>
        <main className="containerMainProfile">
          <div className="formMainProfile">
            {toggleAlert.active && (
              <div className="alertProfile">
                <Alert
                  type={toggleAlert.type}
                  message={toggleAlert.message}
                  open={toggleAlert.active}
                />
              </div>
            )}
            <div className="inputsFormProfile">
              {itemsInput.map((itemInput) => (
                <div key={itemInput.label}>
                  <Input
                    firstIcon={itemInput.firstIcon}
                    label={itemInput.label}
                    layout={itemInput.layout}
                    placeholder={itemInput.placeholder}
                    secondIcon={itemInput.secondIcon}
                    setValue={itemInput.setValue}
                    type={itemInput.type}
                    value={itemInput.value}
                  />
                </div>
              ))}
            </div>
            <Button
              dataTestid="dataTestId-button-save"
              type="default"
              size="large"
              variant="primary"
              children="Salvar Alterações"
              textStyle={{
                fontFamily: "Montserrat-Regular",
                fontSize: "14px",
                fontStyle: "normal",
              }}
              buttonStyle={{ width: "100%" }}
              onClick={handleChangeProfile}
            />
          </div>
        </main>
        <ModalDefaultToSetPassword
          title="Alterar email"
          description="Para seguir com a mudança de email, informe sua senha"
          isOpen={isOpenModalToSetPassword}
          modalColor="primary"
          buttons={[
            {
              name: "Alterar email",
              color: "primary",
              type: "default",
              onClick: () => updateEmailOnAllServices(password),
            },
            {
              name: "Voltar",
              color: "primary",
              type: "auxiliary",
              onClick: () => setIsOpenModalToSetPassword(false),
            },
          ]}
        >
          <Input
            label="Senha"
            layout="default"
            secondIcon={
              passwordVisible ? (
                <FiRrEye
                  className="eyeicon"
                  data-testid="eye-visible-icon"
                  onClick={handleShowPassword}
                />
              ) : (
                <FiRrEyeCrossed
                  className="eyeicon"
                  data-testid="eye-invisible-icon"
                  onClick={handleShowPassword}
                />
              )
            }
            setValue={setPassword}
            type={passwordVisible ? "text" : "password"}
            value={password}
          />
        </ModalDefaultToSetPassword>
      </>
    )
  );
};

export default Profile;
