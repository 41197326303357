import React, { useEffect } from "react";
import "./style.scss";
import { ReactComponent as FiRrCrossSmall } from "../../../Assets/img/regular/fi-rr-cross-small.svg";
import { ReactSVG } from "react-svg";

const ModalQuestions = ({ isOpen, setIsOpen, title, options }) => {
  const toogle = () => setIsOpen(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("wrapper")) {
        toogle();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="wrapper">
      <div className="compModalQuestions">
        <header>
          <span className="title">{title}</span>
          <FiRrCrossSmall className="icon" onClick={() => setIsOpen(false)} />
        </header>
        <div className="options">
          {options.map((option, index) => (
            <button key={index} className="option" onClick={option.onClick}>
              <ReactSVG className="optionIcon" src={option.icon} />
              <span>{option.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalQuestions;
