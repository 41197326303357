import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as FiSrMenuBurger } from "../../Assets/img/solid/fi-sr-menu-burger.svg";
import ChartsToReport from "../ChartsToReport";
import ButtonOnOff from "../ButtonOnOff";
import ChartComment from "../ChartComment";
import ChartTable from "../ChartTable";

function ChartDefault(props) {
  const {
    dataQuestions,
    question,
    reportData,
    reportChartsRef,
    typesOfCharts,
  } = props;

  const [showObservations, setShowObservations] = useState(false);
  const qNumberString =
    question.number < 10 ? `q_0${question.number}` : `q_${question.number}`;

  if (
    ["insertImage", "insertVideo", "insertParagraph"].includes(question.type)
  ) {
    return (
      <div className="compChartDefault">
        <div className="toggleArea">
          <div className="questionType">
            <span>Pergunta {question.number}</span>
          </div>
          <div className="menuArea">
            <FiSrMenuBurger className="menuIcon" />
          </div>
        </div>
        <div className="overviewArea">
          <p>
            Respondentes: <span>100</span>
          </p>
          <p>
            Pulos por lógica: <span>20</span>
          </p>
        </div>
        <div className="titleArea">{question.message}</div>
        <div className="tableArea">
          <div className="buttonArea">
            <ButtonOnOff
              handleValue={() => setShowObservations(!showObservations)}
              on={showObservations}
            />{" "}
            <span>Ocultar observações</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="compChartDefault">
        <div className="toggleArea">
          <div className="questionType">
            <span>Pergunta {question.number}</span>
          </div>
          <div className="menuArea">
            <FiSrMenuBurger className="menuIcon" />
          </div>
        </div>
        <div className="overviewArea">
          <p>
            Respondentes: <span>100</span>
          </p>
          <p>
            Pulos por lógica: <span>20</span>
          </p>
        </div>
        <div className="titleArea">{question.message}</div>
        <div className="chartArea">
          {reportData[qNumberString] !== undefined && (
            <ChartsToReport
              data={reportData[qNumberString].map((element) => {
                return {
                  option: element[qNumberString],
                  qtd: element.qtd,
                  value: element.percent.toFixed(1),
                };
              })}
              chartType={typesOfCharts[question.number]}
              question={question}
            />
          )}
        </div>
        <ChartTable
          qNumberString={qNumberString}
          question={question}
          reportData={reportData}
        />
        <ChartComment
          dataQuestions={dataQuestions}
          chartNumber={question.number}
          reportChartsRef={reportChartsRef}
        />
      </div>
    );
  }
}

export default ChartDefault;
