import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../Button";
import { ReactComponent as Building } from "../../Assets/img/regular/fi-rr-building.svg";
import { ReactComponent as CommentCheck } from "../../Assets/img/solid/fi-sr-comment-check.svg";
import "./style.scss";
import { PayPalButtons } from "@paypal/react-paypal-js";
import PaypalService from "../../Services/PaypalService";
import UserService from "../../Services/UserService";
import { Context } from "../../Context/Context";

/**
 * @typedef {'Free' | 'Basic' | 'Premium' | 'Ultra' | 'Enterprise'} CardPlanTypePlan
 * @typedef {'month' | 'year' } CardPlanTypePrice
 */

/**
 * @typedef {Object} CardPlanProps
 * @property {CardPlanTypePlan} typePlan
 * @property {CardPlanTypePrice} typePrice
 */

/**
 * @param {CardPlanProps}
 * @returns {JSX.Element}
 */

const CardPlan = ({ typePlan, typePrice, handleUserHavePlan, onError }) => {
  const { products, updateUserInformation, userInformation } =
    useContext(Context);
  const history = useHistory();
  const [contentOthersCards, setContentOthersCards] = useState([]);
  const [contentSpecialCards, setContentSpecialCards] = useState([]);
  const filterPlan = products.filter((plan) => plan.account === typePlan);
  const plan = filterPlan[0];

  const handleApprove = async (orderId, plan) => {
    if (
      userInformation.idSubscriptionPaypal !== undefined &&
      userInformation.idSubscriptionPaypal !== null
    ) {
      await PaypalService.cancelSubscription(
        userInformation.idSubscriptionPaypal,
        "Upgrade"
      );
    }

    await UserService.setAccountPlan(plan.account, orderId);
    await updateUserInformation();
    history.push("/projects");
  };

  const sendEmailEnterprise = () => (window.location = `mailto:hazo@hazo.app`);

  const handleFreePlan = () => {};

  const handleSpecialCards = () => {
    const data = [];
    const logic = typePlan === "Enterprise";

    data.push(
      <>
        {logic ? (
          <Building className="icon" />
        ) : (
          <div className="wrapperCardPlan">
            <div className="price">
              <span className="symbol" style={{ color: plan.color }}>
                R$
              </span>
              <span className="value" style={{ color: plan.color }}>
                {typePrice === "month"
                  ? `${plan.price.month},00`
                  : `${plan.price.year},00`}
              </span>
            </div>
          </div>
        )}
        <Button
          className="button"
          type="default"
          size="large"
          buttonStyle={{
            backgroundColor: plan.color,
            boxShadow: `0px 0px 16px 0px ${plan.colorShadow}`,
          }}
          onClick={() => {
            logic ? sendEmailEnterprise() : handleFreePlan();
          }}
        >
          {plan.button}
        </Button>
        <span className="text">
          {logic ? "Faltou alguma coisa?" : "O plano inclui:"}
        </span>
        <div className="contentBenefits">
          {plan.texts.map((benefit, index) => (
            <div key={index} className="benefit">
              <CommentCheck className="iconCheck" />
              <span className="benefitText">{benefit}</span>
            </div>
          ))}
          {logic === false && (
            <div className="benefit">
              <CommentCheck className="iconCheck disabled" />
              <span className="benefitText disabled">{plan.textDisable}</span>
            </div>
          )}
        </div>
      </>
    );

    return data;
  };

  const handleOthersCards = () => {
    const data = [];

    data.push(
      <>
        <div className="wrapperCardPlan">
          <div className="price">
            <span className="symbol" style={{ color: plan.color }}>
              R$
            </span>
            {plan.price !== undefined && (
              <span className="value" style={{ color: plan.color }}>
                {typePrice === "year" ? plan.price.year : plan.price.month}
              </span>
            )}
          </div>
          <span>por mês</span>
        </div>
        <div className="areaPaypal">
          <PayPalButtons
            style={{
              color: "silver",
              layout: "horizontal",
              height: 48,
              tagline: false,
              shape: "pill",
            }}
            createSubscription={(data, actions) => {
              const planId =
                typePrice === "year" ? plan.plans.year : plan.plans.month;

              return actions.subscription.create({
                plan_id: planId,
              });
            }}
            onApprove={async (data) => {
              await handleApprove(data.subscriptionID, plan);
            }}
            onError={(err) => {
              onError();
            }}
            onClick={(data, actions) => {
              if (userInformation.subscription === plan.account) {
                handleUserHavePlan();
                return actions.reject();
              } else {
                return actions.resolve();
              }
            }}
          />
          <p>
            *Você será direcionado para a página do PayPal e terá a opção de
            pagar com cartão de crédito
          </p>
        </div>
        <span className="text">O plano inclui:</span>
        <div className="contentBenefits">
          {plan.texts.map((benefit, index) => (
            <div key={index} className="benefit">
              <CommentCheck className="iconCheck" />
              <span className="benefitText">{benefit}</span>
            </div>
          ))}
        </div>
      </>
    );

    return data;
  };

  useEffect(() => {
    setContentOthersCards(handleOthersCards());
    setContentSpecialCards(handleSpecialCards());
    // eslint-disable-next-line
  }, [typePrice]);

  return (
    <div className="compCardPlan">
      {typePlan === "Premium" && (
        <span className="tagPremium">Mais popular</span>
      )}
      <div className="header">
        <span className="title" style={{ color: plan.color }}>
          {plan.title}
        </span>
        <span className="description">{plan.description}</span>
      </div>
      {typePlan === "Free" || typePlan === "Enterprise" ? (
        <>{contentSpecialCards}</>
      ) : (
        <>{contentOthersCards}</>
      )}
    </div>
  );
};

CardPlan.propTypes = {
  typePlan: PropTypes.oneOf(["Free", "Basic", "Premium", "Ultra", "Enterprise"])
    .isRequired,
  typePrice: PropTypes.oneOf(["month", "year"]),
  handleUserHavePlan: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

CardPlan.defaultProps = {
  typePlan: "Free",
  typePrice: "",
  handleUserHavePlan: () => {},
  onError: () => {},
};

export default CardPlan;
