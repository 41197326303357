import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as ProjectNone } from "../../Assets/img/project-none.svg";
import { Context } from "../../Context/Context";
import IconAdd from "../../Assets/img/regular/fi-rr-add.svg";
import Actions from "../../Components/TopBar/Actions";
import Alerts from "../../Components/Alerts";
import Bottoms from "../../Components/TopBar/Bottoms";
import BottomTabHome from "../../Components/BottomTabHome";
import Box from "../../Components/Box";
import Button from "../../Components/Button";
import CardProjects from "../../Components/CardProjects";
import LoadingProjects from "../../Components/LoadingProjects";
import ModalDefault from "../../Components/Modals/ModalDefault";
import ModalDeleteProjects from "../../Components/Modals/ModalDeleteProjects";
import ModalProjectDetails from "../../Components/Modals/ModalProjectDetails";
import ModalDuplicateProject from "../../Components/Modals/ModalDuplicateProject";
import ModalToMoveProjects from "../../Components/Modals/ModalToMoveProjects";
import ProjectsService from "../../Services/ProjectsService";
import Search from "../../Components/TopBar/Search";
import moment from "moment";
import "moment/locale/pt-br";
import "./style.scss";
import ModalNewInteraction from "../../Components/Modals/ModalNewInteraction";
import ModalMessageCenter from "../../Components/Modals/ModalMessageCenter";
import ModalUpgrade from "../../Components/Modals/ModalUpgrade";
moment.locale("pt-br");

const Projects = () => {
  const {
    getProjectsInFirebase,
    handleTotalInteractions,
    userInformation,
    userProjectsPage,
    setUserProjectsPage,
    favoriteProjects,
    setFavoriteProjects,
    userOthersProjects,
    setUserOthersProjects,
    favoriteOtherProjects,
    setFavoriteOtherProjects,
    allUserProjects,
    setAllUserProjects,
    alert,
    setAlert,
  } = useContext(Context);
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [projectsStatus, setProjectsStatus] = useState([]);
  const [typeProject, setTypeProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Mais recentes");
  const [selectedInteractions, setSelectedInteractions] =
    useState("Interações");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [showBoxFilters, setShowBoxFilters] = useState(false);
  const [showBoxInteractions, setShowBoxInteractions] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);
  const [showModalToMove, setShowModalToMove] = useState(false);
  const [showModalFavorite, setShowModalFavorite] = useState(false);
  const [showModalProject, setShowModalProject] = useState(false);
  const [showModalUpgrade, setShowModalUpgrade] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [totalAnswers, setTotalAnswers] = useState([]);
  const [userProjectsQtdAnswers, setUserProjectsQtdAnswers] = useState([]);
  const [modalNewInteraction, setModalNewInteraction] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [clickProject, setClickProject] = useState({});
  const [contentCardProjects, setContentCardProjects] = useState([]);
  const filterOptions = [
    "Mais recentes",
    "Data (Antigos)",
    "Data (Recentes)",
    "Nome (A-Z)",
    "Nome (Z-A)",
    "Qtd. Resposta (Cresc.)",
    "Qtd. Resposta (Decresc.)",
  ];
  const orderByFilter = (a, b) => {
    let projectNameA = a.nameProject.toLowerCase();
    let projectNameB = b.nameProject.toLowerCase();

    switch (selectedFilter) {
      case "Mais recentes":
        return new Date(b.data.toDate()) - new Date(a.data.toDate());
      case "Data (Antigos)":
        return new Date(a.data.toDate()) - new Date(b.data.toDate());
      case "Data (Recentes)":
        return new Date(b.data.toDate()) - new Date(a.data.toDate());
      case "Nome (A-Z)":
        if (projectNameA < projectNameB) {
          return -1;
        }
        if (projectNameA > projectNameB) {
          return 1;
        }
        return null;
      case "Nome (Z-A)":
        if (projectNameA > projectNameB) {
          return -1;
        }
        if (projectNameA < projectNameB) {
          return 1;
        }
        return null;
      case "Qtd. Resposta (Cresc.)":
        if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
          return 1;
        }
        if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
          return -1;
        }
        return null;
      case "Qtd. Resposta (Decresc.)":
        if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
          return -1;
        }
        if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
          return 1;
        }
        return null;
      default:
        return new Date(b.data.toDate()) - new Date(a.data.toDate());
    }
  };

  useEffect(() => {
    const getStatusProjects = async () => {
      const arrayOfidProjects = allUserProjects.map((project) => {
        return project.idProject;
      });

      const arrayStatusOfIdProjects =
        await ProjectsService.getStatusActiveFromProjects(
          arrayOfidProjects,
          userInformation.email,
          userInformation.subscription
        );
      setProjectsStatus(arrayStatusOfIdProjects.data);
    };

    if (allUserProjects.length > 0 && userInformation?.email) {
      getStatusProjects();
    }
    // eslint-disable-next-line
  }, [allUserProjects, userInformation]);

  const renderCard = (project, index, type, projects) => {
    const jsonProject = JSON.parse(project.jsonProject);

    const projectColor = jsonProject.chatbot_header_color;
    const projectDate = moment(project.data.toDate()).format("L");
    const selectedProject = selectedProjects.find(
      (item) => item.idProject === project.idProject
    );
    const qtdQuestions = jsonProject.questions.length;
    const qtdAnswers = userProjectsQtdAnswers[project.idProject]
      ? parseInt(userProjectsQtdAnswers[project.idProject])
      : 0;
    const statusActiveProject =
      projectsStatus &&
      projectsStatus.filter(
        (elementProject) => elementProject.project === project.idProject
      )[0].status_closed;

    return (
      <CardProjects
        key={project.idProject + index}
        active={!statusActiveProject} // Necessary invert logic because API send status active of projects of invert way
        selected={Boolean(selectedProject)}
        projectName={project.nameProject}
        projectDate={projectDate && projectDate}
        projectColor={projectColor ? `${projectColor}` : "#609ef1"}
        favorited={project.favorited}
        detailing={{
          questions: qtdQuestions,
          answers: qtdAnswers,
          completation: 50,
          updated_at: "10/10/2023",
        }}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        project={project}
        onClickCard={() => {
          setShowModalProject(true);
          setClickProject(project);
          setProjects(projects);
          setTypeProject(type);
        }}
        onClickSelected={() =>
          handleSelectedProjects(project.idProject, project)
        }
        onClickFavorite={(e) => handleFavoriteProject(e, project)}
      />
    );
  };

  const handleCardProjects = () => {
    const dataToReturn = [];
    const conditional = selectedInteractions === "Interações";
    const projects = conditional ? userProjectsPage : userOthersProjects;
    const favProjects = conditional ? favoriteProjects : favoriteOtherProjects;
    const typeProject = conditional ? "projects" : "others";
    const typeFavProject = conditional ? "favProjects" : "favOthers";

    const filteredFavProjects = favProjects.filter((favProject) =>
      favProject.nameProject.toLowerCase().includes(textSearch.toLowerCase())
    );
    const filteredProjects = projects.filter((project) =>
      project.nameProject.toLowerCase().includes(textSearch.toLowerCase())
    );

    const noResults =
      filteredFavProjects.length === 0 &&
      filteredProjects.length === 0 &&
      textSearch;

    dataToReturn.push(
      noResults ? (
        <div className="content">
          <ProjectNone className="iconProject" />
          <p className="text">
            Sem resultados encontrados.
            {"\n"} Tente pesquisar por outro nome.
          </p>
        </div>
      ) : (
        <div className="projects">
          {filteredFavProjects.map((favProject, index) =>
            renderCard(favProject, index, typeFavProject, favProjects)
          )}
          {filteredProjects
            .sort(orderByFilter)
            .map((project, index) =>
              renderCard(project, index, typeProject, projects)
            )}
        </div>
      )
    );

    return dataToReturn;
  };

  const filterOtherProjects = (projects) => {
    const projectsToSet = projects.filter((project) => {
      return project.folder === "others" ? project : null;
    });

    const favoriteProjects = projectsToSet.filter((project) => {
      return project.favorited ? project : null;
    });

    const updatedNonFavorites = projectsToSet.filter(
      (nonFavProject) => !nonFavProject.favorited
    );

    setUserOthersProjects(updatedNonFavorites);
    setFavoriteOtherProjects(favoriteProjects);
  };

  const filterDefaultProjects = (projects) => {
    const projectsToSet = projects.filter((project) => {
      return project.folder !== "others" ? project : null;
    });

    return projectsToSet;
  };

  const filterFavoriteProjects = (projects) => {
    const favorites = projects.filter((project) => {
      return project.favorited && project.folder !== "others" ? project : null;
    });

    setFavoriteProjects(favorites);
  };

  const filterUserProjects = (projects) => {
    const updatedNonFavorites = projects.filter(
      (nonFavProject) =>
        !nonFavProject.favorited && nonFavProject.folder !== "others"
    );

    setUserProjectsPage(updatedNonFavorites);
  };

  const handleSelectInteractions = (option) => {
    if (option === "Outros") {
      handleTotalOthersProjects();
    }
    setSelectedInteractions(option);
    setShowBoxInteractions(!showBoxInteractions);
  };

  const handleTotalOthersProjects = async () => {
    let arrayIdsProjects = userOthersProjects.map((element) => {
      return element.idProject;
    });
    let projectsQtdAnswers = await ProjectsService.getProjectsTotalAnswers(
      userInformation.email,
      arrayIdsProjects
    );
    setUserProjectsQtdAnswers({
      ...userProjectsQtdAnswers,
      ...projectsQtdAnswers,
    });
  };

  const handleSelectFilters = (option) => {
    setSelectedFilter(option);
    setShowBoxFilters(!showBoxFilters);
  };

  const handleSelectedProjects = (id, project) => {
    const idExists = selectedProjects.find(
      (project) => project.idProject === id
    );

    if (idExists) {
      const updateSelectedProjects = selectedProjects.filter(
        (project) => project.idProject !== id
      );
      setSelectedProjects(updateSelectedProjects);
    } else {
      setSelectedProjects([...selectedProjects, project]);
    }
  };

  const handleFavoriteProject = async (e, project) => {
    e.stopPropagation();
    setShowModalProject(false);

    const favoritedProjects =
      selectedInteractions === "Interações"
        ? favoriteProjects
        : favoriteOtherProjects;

    const isFavorite = favoritedProjects.some(
      (favProject) => favProject.idProject === project.idProject
    );

    if (!isFavorite && favoritedProjects.length >= 3) {
      setShowModalFavorite(true);
      return;
    }

    try {
      project.favorited = !project.favorited;

      await ProjectsService.setFavoriteProject(
        project.idProject,
        project.favorited
      );

      if (isFavorite) {
        if (selectedInteractions === "Interações") {
          const updatedFavorites = favoriteProjects.filter(
            (favProject) => favProject.idProject !== project.idProject
          );

          setUserProjectsPage([...userProjectsPage, project]);
          setFavoriteProjects(updatedFavorites);
        } else {
          const updatedFavorites = favoriteOtherProjects.filter(
            (favProject) => favProject.idProject !== project.idProject
          );

          setUserOthersProjects([...userOthersProjects, project]);
          setFavoriteOtherProjects(updatedFavorites);
        }
      } else {
        if (selectedInteractions === "Interações") {
          const updatedNonFavorites = userProjectsPage.filter(
            (nonFavProject) => nonFavProject.idProject !== project.idProject
          );

          setUserProjectsPage(updatedNonFavorites);
          setFavoriteProjects([...favoriteProjects, project]);
        } else {
          const updatedNonFavorites = userOthersProjects.filter(
            (nonFavProject) => nonFavProject.idProject !== project.idProject
          );
          setUserOthersProjects(updatedNonFavorites);
          setFavoriteOtherProjects([...favoriteOtherProjects, project]);
        }
      }
    } catch {
      setAlert({
        active: true,
        type: "Error",
        message: "Erro ao favoritar o projeto! Tente novamente mais tarde...",
      });
    }
  };

  const resetModalInformations = () => {
    setSelectedProjects([]);
    setShowActions(false);
  };

  useEffect(() => {
    setLoading(true);
    const getProjectsToList = async () => {
      let projectsInFirebase = await getProjectsInFirebase(
        userInformation.projects
      );

      filterOtherProjects(projectsInFirebase);
      setAllUserProjects(projectsInFirebase);
      filterUserProjects(projectsInFirebase);
      filterFavoriteProjects(projectsInFirebase);

      projectsInFirebase = filterDefaultProjects(projectsInFirebase);
      let arrayIdsProjects = projectsInFirebase.map((element) => {
        return element.idProject;
      });
      const firstPromise = ProjectsService.getProjectsTotalAnswers(
        userInformation.email,
        arrayIdsProjects
      );
      const secondPromise = ProjectsService.getProjectsTotalAnswers(
        userInformation.email,
        arrayIdsProjects,
        true
      );
      Promise.all([firstPromise, secondPromise]).then(
        ([firstResponse, secondResponse]) => {
          // first promise
          setUserProjectsQtdAnswers(firstResponse);
          projectsInFirebase.map((element, index) => {
            return (projectsInFirebase[index].qtdAnswer = firstResponse[
              element.idProject
            ]
              ? firstResponse[element.idProject]
              : "0");
          });
          // second promise
          let answers =
            Object.keys(secondResponse).length > 0
              ? Object.keys(secondResponse).reduce(
                  (sum, key) => sum + parseFloat(secondResponse[key] || 0),
                  0
                )
              : 0;
          setTotalAnswers(answers);
          setLoading(false);
        }
      );
    };

    if (userInformation?.email && userInformation) {
      getProjectsToList();
    }

    // eslint-disable-next-line
  }, [userInformation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        ...alert,
        active: false,
      });
    }, 4000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [alert]);

  useEffect(() => {
    setShowActions(selectedProjects.length > 0);
  }, [selectedProjects]);

  useEffect(() => {
    if (!loading && projectsStatus.length > 0) {
      setContentCardProjects(handleCardProjects());
    }
    // eslint-disable-next-line
  }, [
    userProjectsPage,
    favoriteProjects,
    userOthersProjects,
    favoriteOtherProjects,
    selectedInteractions,
    selectedProjects,
    textSearch,
    selectedFilter,
    loading,
    projectsStatus,
  ]);

  return (
    <div className="projectsComp">
      <ModalUpgrade isOpen={showModalUpgrade} setIsOpen={setShowModalUpgrade} />
      <ModalMessageCenter
        isOpen={showModalMessage}
        setIsOpen={setShowModalMessage}
      />
      <ModalNewInteraction
        isOpen={modalNewInteraction}
        setIsOpen={setModalNewInteraction}
        userProjects={allUserProjects}
      />
      <ModalProjectDetails
        isOpen={showModalProject}
        setIsOpen={setShowModalProject}
        project={clickProject}
        userProjects={projects}
        typeProject={typeProject}
      />
      <ModalDefault
        isOpen={showModalFavorite}
        setIsOpen={setShowModalFavorite}
        modalColor="primary"
        title="Limite excedido"
        description="Você excedeu o limite de projetos favoritos. Só pode escolher no máximo 3 projetos."
        buttons={[
          {
            name: "Entendi",
            color: "primary",
            type: "default",
            onClick: () => setShowModalFavorite(false),
          },
        ]}
      />
      <ModalDeleteProjects
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        selectedProjects={selectedProjects}
        typeFolder={
          selectedInteractions === "Interações" ? "projects" : "others"
        }
        onSuccessDelete={() => resetModalInformations()}
      />
      <ModalDuplicateProject
        isOpen={showModalDuplicate}
        setIsOpen={setShowModalDuplicate}
        project={selectedProjects[0]}
        onSuccessDuplicate={() => resetModalInformations()}
      />
      <ModalToMoveProjects
        isOpen={showModalToMove}
        setIsOpen={setShowModalToMove}
        typeFolder={
          selectedInteractions === "Interações" ? "others" : "projects"
        }
        selectedProjects={selectedProjects}
        onSuccessMove={() => resetModalInformations()}
      />
      {showSearchBar ? (
        <Search
          value={textSearch}
          setValue={setTextSearch}
          onClickClose={() => {
            setShowSearchBar(!showSearchBar);
            setTextSearch("");
          }}
        />
      ) : showActions ? (
        <Actions
          qtdProject={selectedProjects.length}
          onClickTrash={() => setShowModalDelete(true)}
          onClickCopy={() => setShowModalDuplicate(true)}
          onClickFolder={() => setShowModalToMove(true)}
          onClickMenuBurger={() => history.push("/account")}
          onClickNotifications={() => setShowModalMessage(true)}
          onClickUpgrade={() => setShowModalUpgrade(true)}
        />
      ) : (
        <Bottoms
          selectedOptionInteractions={selectedInteractions}
          selectedOptionMostRecent={selectedFilter}
          onClickInteractions={() => {
            setShowBoxInteractions(!showBoxInteractions);
            setShowBoxFilters(false);
          }}
          onClickMostRecent={() => {
            setShowBoxFilters(!showBoxFilters);
            setShowBoxInteractions(false);
          }}
          onClickMenuBurger={() => history.push("/account")}
          onClickNotifications={() => setShowModalMessage(true)}
          onClickSearch={() => setShowSearchBar(!showSearchBar)}
          onClickUpgrade={() => setShowModalUpgrade(true)}
        />
      )}
      <Box
        handleSelect={handleSelectInteractions}
        open={showBoxInteractions}
        options={["Interações", "Outros"]}
        selected={selectedInteractions}
        style={{ left: 20 }}
      />
      <Box
        handleSelect={handleSelectFilters}
        open={showBoxFilters}
        options={filterOptions}
        selected={selectedFilter}
        style={{ left: 100 }}
      />
      {alert.active && (
        <div className="alert">
          <Alerts
            open={alert.active}
            type={alert.type}
            message={alert.message}
          />
        </div>
      )}
      {userProjectsPage.length > 0 ||
      favoriteProjects.length > 0 ||
      userOthersProjects.length > 0 ||
      favoriteOtherProjects.length > 0 ||
      loading ? (
        <>
          {loading && <LoadingProjects />}
          {contentCardProjects}
          <div className="footer">
            <BottomTabHome
              max={handleTotalInteractions()}
              onClickNewInteraction={() => setModalNewInteraction(true)}
              value={totalAnswers}
            />
          </div>
        </>
      ) : (
        <div className="content">
          <ProjectNone className="iconProject" />
          <p className="text">
            Não há interações ou projetos criados
            {"\n"} até o momento.
          </p>
          <Button
            type="default"
            variant="primary"
            size="medium"
            icon={IconAdd}
            iconPosition="left"
            onClick={() => setModalNewInteraction(true)}
          >
            Criar nova interação
          </Button>
        </div>
      )}
    </div>
  );
};

export default Projects;
