import React from "react";
import { ReactComponent as ArrowRight } from "../../Assets/img/solid/fi-sr-arrow-small-right.svg";
import "./style.scss";

const CardSigning = ({ typePlan, value, renovationDate, onClick }) => {
  const planDesc = `Seu plano será renovado automaticamente no dia ${renovationDate} e será cobrado o valor de R$${value}`;
  const planDescEnterprise = `Seu plano será renovado automaticamente no dia ${renovationDate}`;
  const freeDesc = `Você não possui assinatura com os nossos serviços até o momento. Aproveite para fazer um upgrade e ter maior alcance.`;

  return (
    <div className="compCardSigning">
      {typePlan === "Free" ? (
        <>
          <div className="header">
            <span className="title">Plano Gratuito</span>
          </div>
          <div className="content">
            <span className="description">{freeDesc}</span>
          </div>
          <div className="footer free" onClick={onClick}>
            <div>
              <span className="text">Escolher assinatura</span>
            </div>
            <div>
              <ArrowRight className="icon" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="header">
            <span className="title">Plano {typePlan}</span>
          </div>
          <div className="content">
            <span className="description">
              {typePlan === "Enterprise" ? planDescEnterprise : planDesc}
            </span>
          </div>
          <div className="footer default" onClick={onClick}>
            <div>
              <span className="text">Cancelar assinatura</span>
            </div>
            <div>
              <ArrowRight className="icon" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CardSigning;
