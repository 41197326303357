import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/Context";
import Button from "../../Button";
import CardNewProject from "../../CardNewProject";
import CardTemplate from "../../CardTemplate";
import Center2Icon from "../../TopBar/Center2Icon";
import Draft from "../../Draft";
import TemplateService from "../../../Services/TemplateService";
import UserService from "../../../Services/UserService";
import "./style.scss";

const ModalNewInteraction = ({ isOpen, setIsOpen, userProjects }) => {
  const { createNewProject, duplicateTemplate, setUserInformation } =
    useContext(Context);
  const history = useHistory();
  const listRef = useRef(null);

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [nameProject, setNameProject] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleStart = (e) => {
    setDragging(true);
    setStartX(getX(e));
    setScrollLeft(listRef.current.scrollLeft);
  };

  const handleMove = (e) => {
    if (!dragging) return;

    const x = getX(e);
    const distance = startX - x;
    listRef.current.scrollLeft = scrollLeft + distance;
  };

  const handleEnd = () => {
    setDragging(false);
    setStartX(null);
  };

  const handleLeave = () => {
    if (dragging) {
      setDragging(false);
      setStartX(null);
    }
  };

  const getX = (event) => {
    return event.type.startsWith("touch")
      ? event.touches[0].clientX
      : event.clientX;
  };

  const handleSaveProject = async () => {
    let nameOfProject = nameProject;
    let counter = 1;

    // while (
    //   userProjects.some((project) => project.nameProject === nameOfProject)
    // ) {
    //   nameOfProject = `${nameProject} ${counter}`;
    //   counter++;
    // }

    for (let i = 0; i < userProjects.length; i++) {
      if (userProjects[i].nameProject === nameOfProject) {
        nameOfProject = `${nameProject} ${counter}`;
        counter++;
      }
    }

    if (selectedTemplate) {
      let response = await duplicateTemplate(selectedTemplate, nameOfProject);
      history.push(`/customization/${response}`);
    } else {
      let idNewProject = await createNewProject(nameOfProject);
      let userInformation = await UserService.getUserInformation();
      setUserInformation(userInformation);
      history.push(`/customization/${idNewProject}`);
    }
  };

  const handleInputChange = (text) => {
    setNameProject(text);
    if (text.length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSelectedTemplate = (template) => {
    setSelectedTemplate(template);
    setNameProject(`Copia ${template.name}`);
    setEdit(true);
    setButtonDisabled(false);
  };

  const handleEnter = (e) => {
    if (e === "Enter") return handleSaveProject();
  };

  const handleBackPage = () => {
    if (edit) {
      setEdit(false);
      setNameProject("");
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      let response = await TemplateService.getTemplates();
      setTemplates(response);
    };

    if (templates.length === 0) {
      getTemplates();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (edit && nameProject === "") {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line
  }, [edit]);

  if (!isOpen) return null;

  return (
    <div className="compModalNewInteraction">
      <Center2Icon
        titlePage="Nova interação"
        onClickBack={() => handleBackPage()}
      />
      {edit ? (
        <div className="divEdit">
          <div className="wrapperDraft">
            <Draft
              textBox="Antes de continuar, digite o nome do seu projeto"
              style={{ left: 50 }}
            />
          </div>
          <textarea
            cols="50"
            rows="10"
            value={nameProject}
            placeholder="Ex: Net Promote Score (NPS)"
            onChange={(e) => handleInputChange(e.target.value)}
            onKeyDown={(e) => handleEnter(e.key)}
          ></textarea>
          <div className="buttonFooter">
            <Button
              type="default"
              variant={buttonDisabled ? "disable" : "primary"}
              buttonStyle={{ width: 358 }}
              onClick={() => handleSaveProject()}
            >
              Finalizar
            </Button>
          </div>
        </div>
      ) : (
        <div className="modalContent">
          <div className="wrapperDraft">
            <Draft
              textBox="Escolha um modelo de projeto que deseja criar"
              style={{ left: 50 }}
            />
          </div>
          <div className="templates">
            <span className="title">Templates</span>
            <div
              ref={listRef}
              className="list"
              onMouseDown={handleStart}
              onMouseMove={handleMove}
              onMouseUp={handleEnd}
              onMouseLeave={handleLeave}
              onTouchStart={handleStart}
              onTouchMove={handleMove}
              onTouchEnd={handleEnd}
            >
              {templates.length > 0 &&
                templates.map((template, index) => (
                  <CardTemplate
                    key={index}
                    projectName={template.name}
                    imageUrl={template.imageURL && template.imageURL}
                    onClick={() => handleSelectedTemplate(template)}
                  />
                ))}
            </div>
          </div>
          <CardNewProject onClick={() => setEdit(true)} />
        </div>
      )}
    </div>
  );
};

export default ModalNewInteraction;
