import React, { useContext } from "react";
import { Context } from "../../../Context/Context";
import ModalDefault from "../ModalDefault";
import ProjectsService from "../../../Services/ProjectsService";

const ModalToMoveProjects = ({
  isOpen,
  setIsOpen,
  typeFolder,
  selectedProjects,
  onSuccessMove,
}) => {
  const {
    setAlert,
    userProjectsPage,
    userOthersProjects,
    setUserProjectsPage,
    setUserOthersProjects,
    favoriteProjects,
    setFavoriteProjects,
    favoriteOtherProjects,
    setFavoriteOtherProjects,
  } = useContext(Context);
  const buttons = [
    {
      name: "Cancelar",
      color: "primary",
      type: "auxiliary",
      onClick: () => setIsOpen(false),
    },
    {
      name: "Mover",
      color: "primary",
      type: "default",
      onClick: () => onClickConfirm(),
    },
  ];

  const handleMoveToProjects = () => {
    const updatedOtherProjects = userOthersProjects.filter(
      (project) => !selectedProjects.includes(project)
    );
    const updatedProjects = userOthersProjects.filter((project) =>
      selectedProjects.includes(project)
    );

    const favorites = selectedProjects.filter((project) => project.favorited);

    if (favorites.length) {
      const updatedFavorites = favoriteOtherProjects.filter(
        (project) => !favorites.includes(project)
      );

      setFavoriteOtherProjects(updatedFavorites);
      setFavoriteProjects([...favoriteProjects, ...favorites]);
    }

    setUserOthersProjects(updatedOtherProjects);
    setUserProjectsPage([...userProjectsPage, ...updatedProjects]);
  };

  const handleMoveToOthers = () => {
    const updatedProjects = userProjectsPage.filter(
      (project) => !selectedProjects.includes(project)
    );

    const updatedOtherProjects = userProjectsPage.filter((project) =>
      selectedProjects.includes(project)
    );

    const favorites = selectedProjects.filter((project) => project.favorited);

    if (favorites.length) {
      const updatedFavorites = favoriteProjects.filter(
        (project) => !favorites.includes(project)
      );

      setFavoriteProjects(updatedFavorites);
      setFavoriteOtherProjects([...favoriteOtherProjects, ...favorites]);
    }

    setUserProjectsPage(updatedProjects);
    setUserOthersProjects([...userOthersProjects, ...updatedOtherProjects]);
  };

  const handleMoveProjects = async () => {
    try {
      if (typeFolder === "projects") {
        handleMoveToProjects();
      } else {
        handleMoveToOthers();
      }

      const movePromises = selectedProjects.map((project) => {
        ProjectsService.setUpdateFolder(project.idProject, typeFolder);
        return null;
      });

      await Promise.all(movePromises);

      onSuccessMove();
      setAlert({
        active: true,
        type: "Success",
        message: "Projeto(s) movido(s) com sucesso!",
      });
    } catch {
      setAlert({
        active: true,
        type: "Error",
        message: "Erro ao mover projeto(s)! Tente novamente mais tarde...",
      });
    }
  };

  const onClickConfirm = async () => {
    await handleMoveProjects();
    setIsOpen(false);
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalColor="primary"
      title="Mover projeto(s)?"
      description={`Você tem certeza que deseja mover o(s) projeto(s) para a pasta <b>${
        typeFolder === "projects" ? "projetos" : "outros"
      }</b>?`}
      buttons={buttons}
    ></ModalDefault>
  );
};

export default ModalToMoveProjects;
