import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";

import HeaderProjectsPage from "../../Components/HeaderProjectsPage";

import Hazo from "../../Assets/img/hazo_messages.png";
import Close from "../../Assets/img/carbon_close.png";
import SaveOn from "../../Assets/img/saveOn.png";
import SaveOff from "../../Assets/img/saveOff.png";

import "./style.scss";
import MessagesService from "../../Services/MessagesService";

const Messages = () => {
  const history = useHistory();
  const { userMessages, qtdNewMessages, getQtdNewMessages, userInformation } = useContext(Context);
  const [ activeTab, setActiveTab ] = useState("todas");
  const [ savedMessage, setSavedMessage ] = useState(true);

  const byDate = (a, b) => { return new Date(a.date) - new Date(b.date) };

  const saveMessage = async(idMessage) => {
    setSavedMessage(!savedMessage);
    let updatedMessages = userMessages;
    updatedMessages.forEach(project => { if(project.id === idMessage) { project.saved = !project.saved }});
    await MessagesService.saveMessage(updatedMessages);
  };

  const readAllMessages = async() => {
    await MessagesService.readAllMessages(userMessages);
  }

  const handleLink = (url) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  }

  const handleAllMessages = () => {
    const dataToReturn = [];
    const messages = activeTab === 'todas' ? userMessages : userMessages.filter(message => message.saved);

    if(!messages.length) {
      dataToReturn.push(<p key={0} className="warning">Ops! Você ainda não tem mensagens salvas</p>)
    } else {
      messages.sort(byDate).reverse().map(message => (
        dataToReturn.push(
          <div
            className={
              message.isNewMessage === true
              ? "messages messagesOn"
              : "messages messagesOff" }
            key={message.id}
          >
            <button className="buttonSave" onClick={() => saveMessage(message.id)}>
              <img src={ message.saved ? SaveOn : SaveOff } alt="" />
            </button>
            <p className="title">{ message.title }</p>
            <p className="description">{ message.description }</p>
            { message.link && <button className="link" onClick={() => handleLink(message.link)}>{ message.linkName }</button> }
            <span className="dateInfo">{ message.dateTimeFormated }</span>
            <div className="line"></div>
          </div>
        )
      ))
    }
    return dataToReturn;
  }

  const handleClosePage = () => {
    readAllMessages().then(() => getQtdNewMessages(userMessages));
    history.push("/projects");
  }

  return (
    <div className="pageMessages">
      <HeaderProjectsPage name={userInformation ? userInformation.name : ''} />
      <div className="contentArea">
        <div className="headerMessages">
          <button
            className="close"
            onClick={() => handleClosePage()}
          >
            <img src={Close} alt="" />
          </button>
          <div className="contentTitle">
            <img src={Hazo} alt="Hazo" />
            <span className="title">Mensagens</span>
          </div>

          <div className="menu">
            <div className={ activeTab === 'todas' ? "containerOn" : "containerOff" }>
              <button className={ activeTab === 'todas' ? "" : "buttonOff" } onClick={() => setActiveTab("todas")}>Todas</button>
              { qtdNewMessages > 0 && <div id="badge">{ qtdNewMessages }</div> }
            </div>
            <div className={ activeTab === 'salvas' ? "containerOn" : "containerOff" }>
              <button className={ activeTab === 'salvas' ? "" : "buttonOff" } onClick={() => setActiveTab("salvas")}>Salvas</button>
            </div>
          </div>
          <div className="line"></div>
        </div>
          {
            userMessages && userMessages.length > 0 ?
            <div className="containerMessages">
              { handleAllMessages() }
            </div>
            : <div className="containerMessages"><p className="warning">Ops! Você não tem mensagens no momento</p></div>
          }
      </div>
    </div>
  )
}

export default Messages;
