import React, { useEffect, useState } from "react";
import Alerts from "../../Alerts";
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import Input from "../../Input";

import "./style.scss";

const ModalEditAdvancedLink = ({ isOpen, setIsOpen, advancedLink }) => {
  const [selected, setSelected] = useState([]);
  const [contactName, setContactName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [alert, setAlert] = useState({
    active: false,
    type: "Success",
    message: "",
  });
  const options = [
    "Enviar por e-mail",
    "Enviar pelo Whatsapp",
    "Enviar pelo Linkedin",
    "Enviar pelo Facebook",
    "Enviar pelo Twitter",
    "Enviar QR Code",
    "Código Embed",
  ];
  const toggle = () => {
    setSelected([]);
    setIsOpen(false);
  };

  const handleSaved = () => {
    setAlert({
      active: true,
      message: "Alterações salvas com sucesso!",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        ...alert,
        active: false,
      });
      toggle();
    }, 3000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [alert.active]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("wrapper")) {
        toggle();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    if (advancedLink) {
      setContactName(advancedLink.contactPoint);
      setDescription(advancedLink.description);
      setLink(advancedLink.link);
      setSelected([advancedLink.buttonName]);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {alert.active && (
        <div className="alert">
          <Alerts open={alert.active} type="Success" message={alert.message} />
        </div>
      )}
      <div className="wrapper">
        <div className="compModalEditAdvancedLink">
          <h2>Editar link avançado</h2>
          <Input
            label="Nome do ponto de contato"
            layout="default"
            placeholder="Ex: Landing page"
            setValue={setContactName}
            type="text"
            value={contactName}
          />
          <Input
            label="Descrição (Opcional)"
            layout="default"
            placeholder="Ex: Essa pesquisa estará na LP da..."
            setValue={setDescription}
            type="text"
            value={description}
          />
          <div className="urlArea">
            <p>https://hazo.me/</p>
            <Input
              label="URL"
              layout="default"
              placeholder="Ex: projeto"
              setValue={setLink}
              type="text"
              value={link}
            />
          </div>
          <Dropdown
            options={options}
            selected={selected}
            setSelected={setSelected}
          />
          <div className="buttonArea">
            <Button
              type="auxiliary"
              size="medium"
              variant="primary"
              onClick={toggle}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              size="medium"
              variant="primary"
              onClick={handleSaved}
            >
              Salvar alterações
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditAdvancedLink;
