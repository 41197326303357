import React, {
  useEffect,
  useState,
  useContext,
} from "react";
import { Context } from "../../../Context/Context";
import Input from "../../Input";
import ModalDefault from "../ModalDefault";
import { ReactComponent as Check } from "../../../Assets/img/inputcheck.svg";
import ProjectsService from "../../../Services/ProjectsService";

const ModalDeleteProjects = ({
  isOpen,
  setIsOpen,
  selectedProjects,
  typeFolder,
  onSuccessDelete,
}) => {
  const {
    setAlert,
    userProjectsPage,
    setUserProjectsPage,
    userOthersProjects,
    setUserOthersProjects,
    favoriteProjects,
    setFavoriteProjects,
    favoriteOtherProjects,
    setFavoriteOtherProjects,
    userInformation,
  } = useContext(Context);
  const [inputValue, setInputValue] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [statusInput, setStatusInput] = useState("default");
  const buttons = [
    {
      name: "Cancelar",
      color: "primary",
      type: "auxiliary",
      onClick: () => onClickCancel(),
    },
    {
      name: "Excluir",
      color: buttonDisabled ? "disable" : "cancel",
      type: "default",
      onClick: () => onClickConfirm(),
    }
  ];

  const clearElements = () => {
    setStatusInput("default");
    setButtonDisabled(true);
    setInputValue("");
  }

  const onClickCancel = () => {
    setIsOpen(false);
    clearElements();
  }

  const onClickConfirm = async() => {
    await handleDeleteProjects();
    setIsOpen(false);
  }

  const handleTextInput = (text) => {
    setInputValue(text);
    if(text === "hazo") {
      setButtonDisabled(false);
      setStatusInput("success");
    } else {
      setStatusInput("default");
      setButtonDisabled(true);
    }
  }

  const handleDeleteProjects = async() => {
    const projects = typeFolder === "projects"
      ? userProjectsPage
      : userOthersProjects;

    const favoritedProjects = typeFolder === "projects"
      ? favoriteProjects
      : favoriteOtherProjects;

    try {
      if(selectedProjects.length === 1) {
        await ProjectsService.deleteProject(userInformation, selectedProjects[0].idProject);
      } else {
        await ProjectsService.deleteMultiplesProjects(userInformation, selectedProjects);
      }

      const updatedProjects = projects.filter(project =>
        !selectedProjects.some(item => item.idProject === project.idProject)
      );
      const listFavProjects = selectedProjects.filter(project => {
        return project.favorited === true
      });
      const updateFavProjects = favoritedProjects.filter(project =>
        !listFavProjects.some(item => item.idProject === project.idProject)
      );

      if(typeFolder === "projects") {
        setUserProjectsPage(updatedProjects);
        setFavoriteProjects(updateFavProjects);
      } else {
        setUserOthersProjects(updatedProjects);
        setFavoriteOtherProjects(updateFavProjects);
      }
      onSuccessDelete();
      setAlert({
        active: true,
        type: "Success",
        message: "Projeto(s) excluído(s) com sucesso!"
      });
    } catch {
      setAlert({
        active: true,
        type: "Error",
        message: "Erro ao excluir projeto(s)! Tente novamente mais tarde..."
      });
    }
  };

  useEffect(() => {
    if(!isOpen) {
      clearElements();
    }
  },[isOpen])

  return (
    <ModalDefault
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttons={buttons}
      title="Excluir projeto(s)?"
      description="Você perderá todos os dados da pesquisa. Para prosseguir com a ação, é necessário digitar a palavra <b>hazo</b> no campo abaixo."
      modalColor="cancel"
    >
      <Input
        type="text"
        layout={statusInput}
        value={inputValue}
        setValue={handleTextInput}
        placeholder="ex: hazo"
        secondIcon={
          statusInput === "success"
          ? <Check className="icon" />
          : null
        }
      />
    </ModalDefault>
  )
}

export default ModalDeleteProjects;
