import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as MenuDots } from "../../Assets/img/solid/fi-sr-menu-dots-vertical.svg";
import Button from "../Button";
import "./style.scss";
import Box from "../Box";

const CardOtherLink = ({
  title,
  description,
  linkChatbot,
  qtdAnswers,
  textButton,
  onClick,
  onClickOption,
}) => {
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showBox, setShowBox] = useState(false);

const handleResize = () => {
  setWindowWidth(window.innerWidth);
};

const handleSelectedOption = (option) => {
  onClickOption(option);
  setShowBox(false);
}

useEffect(() => {
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div className="compCardOtherLink">
      <div className="header">
        <span className="title">{title}</span>
        <MenuDots
          className="icon"
          onClick={() => setShowBox(!showBox)}
        />
        <Box
          open={showBox}
          options={[
            "Editar",
            "Excluir"
          ]}
          style={{ left: 210 }}
          handleSelect={handleSelectedOption}
        />
      </div>
      <div>
        <span className="description">{description}</span>
      </div>
      <div>
        <span className="link">{linkChatbot}</span>
      </div>
      <div className="footer">
        <span>Respostas coletadas: {qtdAnswers}</span>
        <Button
          type="auxiliary"
          size={
            windowWidth <= 768
            ? "small"
            : "medium"
          }
          onClick={onClick}
        >
          {textButton}
        </Button>
      </div>
    </div>
  )
}

CardOtherLink.propsTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkChatbot: PropTypes.string.isRequired,
  qtdAnswers: PropTypes.number.isRequired,
  textButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickMore: PropTypes.func.isRequired,
}

CardOtherLink.defaultProps = {
  title: '',
  description: '',
  linkChatbot: '',
  qtdAnswers: 0,
  textButton: '',
  onClick: () => {},
  onClickMore: () => {},
}

export default CardOtherLink;
