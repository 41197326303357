import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowRight } from "../../Assets/img/solid/fi-sr-arrow-small-right.svg";
import { ReactComponent as CommentCheck } from "../../Assets/img/solid/fi-sr-comment-check.svg";
import { Context } from "../../Context/Context";
import "./style.scss";

/**
 * @typedef {'Free' | 'Basic' | 'Premium' | 'Ultra' | 'Enterprise'} CardBenefitsType
 */

/**
 * @typedef {Object} CardBenefitsProps
 * @property {CardBenefitsType} typePlan
 * @property {function} setIsOpenModalUpgrade - Func to define the state of modalUpgrade
 */

/**
 * @param {CardBenefitsProps} props
 * @returns {JSX.Element}
 */

const validPlans = ["Free", "Basic", "Premium", "Ultra", "Enterprise"];

const CardBenefits = ({ typePlan, setIsOpenModalUpgrade }) => {
  const { products } = useContext(Context);

  const handleBenefits = () => {
    const dataToReturn = [];

    if (validPlans.includes(typePlan)) {
      const product = products.filter(
        (product) => product.account === typePlan
      );
      const benefits = product[0].texts;

      benefits.forEach((benefit, index) => {
        dataToReturn.push(
          <div key={index} className="benefit">
            <CommentCheck className="iconCheck" />
            <span className="text">{benefit}</span>
          </div>
        );
      });
    } else {
      return null;
    }

    return dataToReturn;
  };

  return (
    <>
      <div className="compCardBenefits">
        <div className="header">
          <span className="title">Benefícios</span>
        </div>
        <div className="content">{handleBenefits()}</div>
        <div className="footer" onClick={() => setIsOpenModalUpgrade(true)}>
          <span className="text">Ver outros planos</span>
          <ArrowRight className="iconArrow" />
        </div>
      </div>
    </>
  );
};

CardBenefits.propTypes = {
  typePlan: PropTypes.oneOf(validPlans).isRequired,
  setIsOpenModalUpgrade: PropTypes.func.isRequired,
};

export default CardBenefits;
