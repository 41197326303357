import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CommentInfo } from "../../Assets/img/regular/fi-rr-comment-info.svg";
import { ReactComponent as ArrowRight } from "../../Assets/img/solid/fi-sr-arrow-small-right.svg";
import "./style.scss";

const CardSession = ({ title, description, icon, onClick }) => {
  return (
    <div className="compCardSession" onClick={onClick}>
      <div className="wrapperCard">
        <div className="header">
          {icon ? icon : <CommentInfo className="icon" />}
          <span className="title">{title}</span>
        </div>
        <div>
          <span className="description">{description}</span>
        </div>
      </div>
      <div className="line"></div>
      <div className="footerCard">
        <span>Acessar</span>
        <ArrowRight className="icon" />
      </div>
    </div>
  );
};

CardSession.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

CardSession.defaultProps = {
  title: "",
  description: "",
  onClick: () => {},
};

export default CardSession;
