import "./style.scss";
import PropTypes from "prop-types";
import { ReactComponent as FiRrChartTree } from "../../../Assets/img/regular/fi-rr-chart-tree.svg";
import { ReactComponent as FiSrPlay } from "../../../Assets/img/solid/fi-sr-play.svg";
import { ReactComponent as FiRrCrossCircle } from "../../../Assets/img/regular/fi-rr-cross-circle.svg";
import { ReactComponent as Hazo } from "../../../Assets/img/256PX-01-whiout-padding.svg";

/*******************************************************************************
 * How to use:
 * <Chat
    avatarName="avatar"
    onClickChartTree={() => {
      return null;
    }}
    onClickPlay={() => {
      return null;
    }}
  />
 ******************************************************************************/

function Chat(props) {
  const { avatarName, logic, onClickChartTree, onClickPlay } = props;
  return (
    <div className="compChat">
      <div className="left">
        <Hazo className="icon" data-testid="hazo-icon" />
      </div>
      <div className="middle">
        <span className="title">{avatarName}</span>
        <span className="description">Status</span>
      </div>
      <div className="right">
        { logic ?
          <FiRrCrossCircle
            className="icon"
            onClick={onClickChartTree}
          />
          :
          <FiRrChartTree
            className="icon"
            data-testid="chart-tree-icon"
            onClick={onClickChartTree}
          />
        }
        <FiSrPlay
          className="icon"
          data-testid="play-icon"
          onClick={onClickPlay}
        />
      </div>
    </div>
  );
}

Chat.propTypes = {
  avatarName: PropTypes.string.isRequired,
  onClickChartTree: PropTypes.func.isRequired,
  onClickPlay: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  avatarName: "Hazo, o Camaleão",
  onClickChartTree: () => {},
  onClickPlay: () => {},
};

export default Chat;
