import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import AnswerType from "../../Components/Answer/AnswerType";
import Chat from "../../Components/TopBar/Chat";
import DefaultQuestion from "../../Components/Answer/DefaultQuestion";
import DisclaimerInitial from "../../Components/Answer/DisclaimerInitial/";
import JsonDictionaryService from "../../Services/JsonDictionaryService";
import Loading from "../../Components/Loading";
import AnswersLogic from "../../Components/Logic/AnswersLogic";
import NavigationMenu from "../../Components/NavigationMenu";
import ProjectsService from "../../Services/ProjectsService";

const BotMaker = () => {
  let { projectId } = useParams();
  const { chatbot, createDictionary, setChatbot, userInformation } =
    useContext(Context);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatbotTemp, setChatbotTemp] = useState(null);
  const [showLogic, setShowLogic] = useState(false);
  const [usedNumbers, setUsedNumbers] = useState([]);
  const [lastQuestion, setLastQuestion] = useState(0);
  const pdfRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => pdfRef.current,
  // });

  const addQuestion = async (questionNumber = null) => {
    let existOpenQuestions = chatbotTemp.questions.some(
      (question) => question.edit
    );

    if (existOpenQuestions) {
      validQuestions();
    } else {
      let newQuestionNumber = 0;
      for (let i = 0; i < chatbotTemp.questions.length; i++) {
        if (chatbotTemp.questions[i].number > newQuestionNumber) {
          newQuestionNumber = chatbotTemp.questions[i].number;
        }
      }
      newQuestionNumber++;

      if (usedNumbers.indexOf(newQuestionNumber) > -1) {
        newQuestionNumber = Math.max(...usedNumbers) + 1;
        setUsedNumbers([...usedNumbers, newQuestionNumber]);
      } else {
        setUsedNumbers([...usedNumbers, newQuestionNumber]);
      }

      if (questionNumber === "welcome") {
        questionNumber = -1;
      }

      if (questionNumber === null) {
        setChatbotTemp({
          ...chatbotTemp,
          questions: [
            ...chatbotTemp.questions,
            {
              message: "",
              number: newQuestionNumber,
              options: [],
              type: "",
              edit: 1,
              error: false,
            },
          ],
        });
      } else {
        let arrayTemp = [...chatbotTemp.questions];
        let newArrayOptions = [
          ...arrayTemp.slice(0, questionNumber + 1),
          {
            message: "",
            number: newQuestionNumber,
            options: [],
            type: "",
            edit: 1,
            error: false,
          },
          ...arrayTemp.slice(questionNumber + 1, arrayTemp.length),
        ];
        setChatbotTemp({
          ...chatbotTemp,
          questions: [...newArrayOptions],
        });
      }
    }
  };

  const handleAddOption = (questionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: [
        ...arrayTemp[questionNumber].options,
        { color: "#606c88", next: 0, textColor: "#ffffff", value: "" },
      ],
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleCancel = (questionNumber) => {
    handleScroll(questionNumber);
    let questionNumberWillCancel = chatbotTemp.questions[questionNumber].number;
    let originalQuestion = null;

    for (let i = 0; i < chatbot.questions.length; i++) {
      if (chatbot.questions[i].number === questionNumberWillCancel) {
        originalQuestion = chatbot.questions[i];
      }
    }

    let arrayTemp = chatbotTemp.questions;
    if (originalQuestion !== null) {
      arrayTemp[questionNumber] = {
        ...originalQuestion,
        edit: 0,
        error: false,
      };
      setChatbotTemp({
        ...chatbot,
        questions: arrayTemp,
      });
    } else {
      arrayTemp.splice(questionNumber, 1);
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
    }
  };

  const handleColor = (questionNumber, optionNumber, backColor, textColor) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayWithNewColor = [...arrayTemp[questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      color: backColor,
      textColor: textColor,
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewColor,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleDuplicateQuestion = async (questionNumber) => {
    let newQuestionNumber = 0;
    for (let i = 0; i < chatbotTemp.questions.length; i++) {
      if (chatbotTemp.questions[i].number > newQuestionNumber) {
        newQuestionNumber = chatbotTemp.questions[i].number;
      }
    }

    let newQuestion = [
      Object.assign({}, chatbotTemp.questions[questionNumber]),
    ];

    let firstSlice = Array.from(chatbotTemp.questions.slice(0, questionNumber));
    let secondSlice = Array.from(
      chatbotTemp.questions.slice(
        questionNumber,
        chatbotTemp.questions[questionNumber].length
      )
    );

    let newArrayOptions = [].concat(firstSlice, newQuestion, secondSlice);
    newArrayOptions[questionNumber + 1].number = newQuestionNumber + 1;
    setChatbotTemp({
      ...chatbotTemp,
      questions: newArrayOptions,
    });

    let projectJson = JSON.stringify({
      ...chatbotTemp,
      questions: [...newArrayOptions],
    });
    await createDictionary(
      {
        ...chatbotTemp,
        questions: [...newArrayOptions],
      },
      projectId
    );
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      projectId,
      projectJson
    );
  };

  const handleEdit = (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      let arrayTemp = [...chatbotTemp.questions];
      for (let i = 0; i < arrayTemp.length; i++) {
        let valueToEdit = 0;
        if (i === questionNumber) {
          valueToEdit = 1;
        }
        arrayTemp[i] = {
          ...arrayTemp[i],
          edit: valueToEdit,
        };
      }
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e !== null) {
      if (e.target !== null) {
        e.target.style.height = `${e.target.scrollHeight}px`;
      }
    }
  };

  const handleMessage = (questionNumber, message) => {
    let arrayTemp = [...chatbotTemp.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      message: message,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleNextOptions = (questionNumber, optionNumber, value) => {
    let arrayTemp = JSON.parse(JSON.stringify(chatbotTemp));

    arrayTemp.questions[questionNumber].options[optionNumber].next =
      Number(value);

    setChatbotTemp(arrayTemp);
  };

  const handleNextMultipleOptions = (questionNumber, value) => {
    let arrayTemp = JSON.parse(JSON.stringify(chatbotTemp));

    if (value === "Próxima Pergunta") {
      arrayTemp.questions[questionNumber].options.map((option, index) => {
        return (option.next = 0);
      });
    } else {
      arrayTemp.questions[questionNumber].options.map((option, index) => {
        return (option.next = Number(value));
      });
    }

    setChatbotTemp(arrayTemp);
  };

  const handleOptionValue = (questionNumber, optionNumber, value) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayWithNewColor = [...arrayTemp[questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      value: value.replace(/\n/g, " "),
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewColor,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleQuestion = (question, position) => {
    switch (question.type) {
      case "closeAnswer":
      case "emojiScale":
      case "insertImage":
      case "insertParagraph":
      case "insertVideo":
      case "likeDislike":
      case "menuInteractive":
      case "multipleChoice":
      case "numericalScale":
      case "openAnswerCellPhone":
      case "openAnswerCPF":
      case "openAnswerDate":
      case "openAnswerHour":
      case "openAnswerEmail":
      case "openAnswerFreeText":
      case "openAnswerOnlyNumbers":
      case "openAnswerPhone":
      case "starThreeScale":
      case "starFiveScale":
        return (
          <AnswerType
            addQuestion={addQuestion}
            currentLastQuestion={chatbotTemp.questions.length}
            handleAddOption={handleAddOption}
            handleCancel={handleCancel}
            handleColor={handleColor}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleEdit={handleEdit}
            handleKeyDown={handleKeyDown}
            handleMessage={handleMessage}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleSave={handleSave}
            handleSaveAndNew={handleSaveAndNew}
            key={position}
            moveDownQuestion={moveDownQuestion}
            moveUpQuestion={moveUpQuestion}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={position}
            questionValues={chatbotTemp}
            removeQuestion={removeQuestion}
            setQuestionValues={setChatbotTemp}
            lastQuestion={lastQuestion}
            type={question.type}
          />
        );
      default:
        return (
          <DefaultQuestion
            addQuestion={addQuestion}
            currentLastQuestion={chatbotTemp.questions.length}
            handleCancel={handleCancel}
            handleDuplicateQuestion={handleDuplicateQuestion}
            handleEdit={handleEdit}
            handleKeyDown={handleKeyDown}
            handleMessage={handleMessage}
            handleNextOptions={handleNextOptions}
            handleSave={handleSave}
            handleSaveAndNew={handleSaveAndNew}
            key={position}
            moveDownQuestion={moveDownQuestion}
            moveUpQuestion={moveUpQuestion}
            questionNumber={position}
            questionValues={chatbotTemp}
            removeQuestion={removeQuestion}
            setQuestionValues={setChatbotTemp}
            lastQuestion={lastQuestion}
          />
        );
    }
  };

  const handleRemoveOption = (questionNumber, optionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let arrayRemoved = [...arrayTemp[questionNumber].options];
    arrayRemoved.splice(optionNumber, 1);
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayRemoved,
      error: false,
    };
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
  };

  const handleSave = async (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      handleScroll(questionNumber);
      let arrayTemp = [...chatbotTemp.questions];
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        edit: 0,
      };
      setChatbot({
        ...chatbot,
        questions: arrayTemp,
      });
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });

      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson,
        usedNumbers
      );
    }
  };

  const handleSaveAndNew = async (questionNumber) => {
    let errorExists = validQuestions();
    if (!errorExists) {
      handleScroll(questionNumber);
      let arrayTemp = [...chatbotTemp.questions];
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        edit: 0,
      };

      let newQuestionNumber = 0;
      for (let i = 0; i < chatbotTemp.questions.length; i++) {
        if (chatbotTemp.questions[i].number > newQuestionNumber) {
          newQuestionNumber = chatbotTemp.questions[i].number;
        }
      }
      newQuestionNumber++;

      if (usedNumbers.indexOf(newQuestionNumber) > -1) {
        newQuestionNumber = Math.max(...usedNumbers) + 1;
        setUsedNumbers([...usedNumbers, newQuestionNumber]);
      } else {
        setUsedNumbers([...usedNumbers, newQuestionNumber]);
      }

      setChatbot({
        ...chatbot,
        questions: arrayTemp,
      });
      setChatbotTemp({
        ...chatbotTemp,
        questions: [
          ...arrayTemp.slice(0, questionNumber + 1),
          {
            message: "",
            number: newQuestionNumber,
            options: [],
            type: "",
            edit: 1,
            error: false,
          },
          ...arrayTemp.slice(questionNumber + 1, arrayTemp.length),
        ],
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson,
        usedNumbers
      );
    }
  };

  const handleScroll = (questionNumber) => {
    if (questionNumber) {
      return window.scrollTo({
        top:
          document.getElementById(`Pergunta${questionNumber + 1}`).offsetTop -
          65 -
          65 -
          63,
        behavior: "smooth",
      });
    } else {
      return null;
    }
  };

  const handleTestChatbot = async () => {
    setChatbot(chatbotTemp);
    await createDictionary(chatbotTemp, projectId);
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      projectId,
      JSON.stringify(chatbotTemp)
    );
    window.open(`${process.env.REACT_APP_BASE_URL}testing/${link}`);
  };

  const moveDownQuestion = async (questionNumber) => {
    if (questionNumber < chatbot.questions.length - 1) {
      let arrayTemp = [...chatbotTemp.questions];
      let positionTemp = arrayTemp[questionNumber];
      arrayTemp[questionNumber] = arrayTemp[questionNumber + 1];
      arrayTemp[questionNumber + 1] = positionTemp;
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const moveDownOption = async (questionNumber, questionOptionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let positionTemp = arrayTemp[questionNumber];
    if (questionOptionNumber < positionTemp.options.length - 1) {
      let firstValue = positionTemp.options[questionOptionNumber];
      let secondValue = positionTemp.options[questionOptionNumber + 1];
      let arrayOptionsTemp = positionTemp.options;
      arrayOptionsTemp[questionOptionNumber] = secondValue;
      arrayOptionsTemp[questionOptionNumber + 1] = firstValue;
      positionTemp.options = arrayOptionsTemp;

      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const moveUpQuestion = async (questionNumber) => {
    if (questionNumber > 0) {
      let arrayTemp = [...chatbotTemp.questions];
      let positionTemp = arrayTemp[questionNumber - 1];
      arrayTemp[questionNumber - 1] = arrayTemp[questionNumber];
      arrayTemp[questionNumber] = positionTemp;
      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const moveUpOption = async (questionNumber, questionOptionNumber) => {
    let arrayTemp = [...chatbotTemp.questions];
    let positionTemp = arrayTemp[questionNumber];
    let max = 0;

    switch (arrayTemp[questionNumber].type) {
      case "likeDislike":
        max = 2;
        break;
      case "emojiScale":
        max = 5;
        break;
      case "starThreeScale":
        max = 3;
        break;
      case "starFiveScale":
        max = 5;
        break;
      default:
        break;
    }

    if (questionOptionNumber > max) {
      let firstValue = positionTemp.options[questionOptionNumber];
      let secondValue = positionTemp.options[questionOptionNumber - 1];
      let arrayOptionsTemp = positionTemp.options;
      arrayOptionsTemp[questionOptionNumber] = secondValue;
      arrayOptionsTemp[questionOptionNumber - 1] = firstValue;
      positionTemp.options = arrayOptionsTemp;

      setChatbotTemp({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      let projectJson = JSON.stringify({
        ...chatbotTemp,
        questions: arrayTemp,
      });
      await createDictionary(
        {
          ...chatbotTemp,
          questions: arrayTemp,
        },
        projectId
      );
      await JsonDictionaryService.jsonDictionarySaveFirebase(
        projectId,
        projectJson
      );
    }
  };

  const removeQuestion = async (questionNumber) => {
    let arrayTemp = [];
    for (let i = 0; i < chatbotTemp.questions.length; i++) {
      if (i !== questionNumber) {
        arrayTemp.push(chatbotTemp.questions[i]);
      }
    }
    setChatbotTemp({
      ...chatbotTemp,
      questions: arrayTemp,
    });
    let projectJson = JSON.stringify({
      ...chatbotTemp,
      questions: arrayTemp,
    });
    await createDictionary(
      {
        ...chatbotTemp,
        questions: arrayTemp,
      },
      projectId
    );
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      projectId,
      projectJson
    );
  };

  const validQuestions = () => {
    let arrayTemp = [...chatbotTemp.questions];
    let disclaimer_initialTemp = { ...chatbotTemp.disclaimer_initial };
    let errorExists = false;
    let whereIsError = "";

    if (disclaimer_initialTemp.value === "") {
      disclaimer_initialTemp = {
        ...disclaimer_initialTemp,
        error: true,
      };
      whereIsError = "DisclaimerInicial";
      errorExists = true;
    }

    if (arrayTemp.length > 0) {
      for (let i = 0; i < arrayTemp.length; i++) {
        if (arrayTemp[i].edit === 1) {
          if (arrayTemp[i].message === "") {
            arrayTemp[i] = {
              ...arrayTemp[i],
              error: true,
            };
            errorExists = true;
            whereIsError = `Pergunta${i + 1}`;
          } else if (arrayTemp[i].type === "") {
            arrayTemp[i] = {
              ...arrayTemp[i],
              error: true,
            };
            errorExists = true;
            whereIsError = `Pergunta${i + 1}`;
          } else {
            switch (arrayTemp[i].type) {
              case "closeAnswer":
              case "emojiScale":
              case "multipleChoice":
              case "starThreeScale":
              case "starFiveScale":
              case "menuInteractive":
              case "likeDislike":
              case "numericalScale":
                for (let j = 0; j < arrayTemp[i].options.length; j++) {
                  if (arrayTemp[i].options[j].value === "") {
                    arrayTemp[i] = {
                      ...arrayTemp[i],
                      error: true,
                    };
                    errorExists = true;
                    whereIsError = `Pergunta${i + 1}`;
                  }
                }
                break;

              case "insertImage":
              case "insertVideo":
                if (arrayTemp[i].options === "") {
                  arrayTemp[i] = {
                    ...arrayTemp[i],
                    error: true,
                  };
                  errorExists = true;
                  whereIsError = `Pergunta${i + 1}`;
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }

    if (errorExists) {
      window.scrollTo({
        top: document.getElementById(whereIsError)?.offsetTop - 65 - 65 - 63,
        behavior: "smooth",
      });
    }

    setChatbotTemp({
      ...chatbotTemp,
      disclaimer_initial: disclaimer_initialTemp,
      questions: arrayTemp,
    });
    return errorExists;
  };

  useEffect(() => {
    const getProjectInformation = async () => {
      let validationProjectIdExists = [];

      if (userInformation.subscription !== "IdssManager") {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element === projectId ? element : null;
          }
        );
      } else {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element.idProject === projectId ? element : null;
          }
        );
      }

      if (
        validationProjectIdExists.length > 0 ||
        userInformation.subscription === "IdssManager"
      ) {
        const userProject = await ProjectsService.getSpecificProject(projectId);

        let chatbotParsed = JSON.parse(userProject[0].json);
        setLastQuestion(chatbotParsed.questions.length);
        setChatbot(chatbotParsed);
        setChatbotTemp(chatbotParsed);
        setLink(
          userProject[0].link !== undefined ? userProject[0].link : projectId
        );

        if (userProject[0].usedNumbers === undefined) {
          let arrayNumbers = [];
          for (let i = 0; i < chatbotParsed.questions.length; i++) {
            arrayNumbers.push(chatbotParsed.questions[i].number);
          }
          setUsedNumbers(arrayNumbers);
        } else {
          setUsedNumbers(userProject[0].usedNumbers);
        }
        setLoading(false);
      }
    };

    if (userInformation) {
      getProjectInformation();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="compBotmaker" id="compBotmaker" ref={pdfRef}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Helmet>
        <Chat
          avatarName={chatbotTemp.robot_name}
          logic={showLogic}
          onClickChartTree={() => setShowLogic(!showLogic)}
          onClickPlay={handleTestChatbot}
        />
        <main className="messagesArea">
          {!showLogic ? (
            <>
              <NavigationMenu active="botmaker" projectId={projectId} />
              <DisclaimerInitial
                addQuestion={addQuestion}
                currentLastQuestion={chatbotTemp.questions.length}
                projectId={projectId}
                questionValues={chatbotTemp}
                setQuestionValues={setChatbotTemp}
                validQuestions={validQuestions}
              />
              {chatbotTemp.questions.map((question, index) => {
                return handleQuestion(question, index);
              })}
            </>
          ) : (
            <AnswersLogic questionValues={chatbotTemp} />
          )}
        </main>
      </div>
    );
  }
};

export default BotMaker;
