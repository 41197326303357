import React, { useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as Intro3 } from "../../../Assets/img/welcome/intro-3.svg";
import IconAdd from "../../../Assets/img/regular/fi-rr-add.svg";
import Button from "../../Button";
import Alerts from "../../Alerts";
import BottomTabNewLink from "../../BottomTabNewLink";
import CardOtherLink from "../../CardOtherLink";
import CenterNoIcon from "../../TopBar/CenterNoIcon";
import ModalAdvancedLinks from "../ModalAdvancedLinks";
import ModalDefault from "../ModalDefault";
import ModalEditAdvancedLink from "../ModalEditAdvancedLink";
import ModalEmbed from "../ModalEmbed";
import ModalQRCode from "../ModalQRCode";

const ModalConfigAdvancedLinks = ({ isOpen, setIsOpen }) => {
  const [showModalEmbed, setShowModalEmbed] = useState(false);
  const [showModalQRCode, setShowModalQRCode] = useState(false);
  const [urlQRCode, setUrlQRCode] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [alert, setAlert] = useState({
    active: false,
    type: "Success",
    message: "",
  });

  const advancedLinks = [
    {
      contactPoint: "E-mail",
      description:
        "Essa pesquisa será enviada somente por e-mail para os clientes do plano de saúde.",
      link: `projeto1`,
      colectedAnswers: 10,
      typeContact: "Email",
      buttonName: "Enviar por e-mail",
    },
    {
      contactPoint: "Whatsapp",
      description:
        "Essa pesquisa será enviada somente por whatsapp para os clientes do posto de saúde.",
      link: `projeto2`,
      colectedAnswers: 10,
      typeContact: "Whatsapp",
      buttonName: "Enviar pelo Whatsapp",
    },
    {
      contactPoint: "Landing Page",
      description: "Essa pesquisa será anexada no site principal.",
      link: `projeto3`,
      colectedAnswers: 0,
      typeContact: "Código embed",
      buttonName: "Ver código embed",
      onClick: () => {
        setShowModalEmbed(true);
      },
    },
    {
      contactPoint: "Propaganda TV",
      description:
        "Essa pesquisa será anexada na propaganda nos horários comerciais.",
      link: `projeto4`,
      colectedAnswers: 0,
      typeContact: "QR Code",
      buttonName: "Ver QR Code",
      onClick: () => {
        setShowModalQRCode(true);
        setUrlQRCode("projeto4");
      },
    },
  ];

  const [showModalAddLink, setShowModalAddLink] = useState(false);
  const [contentCards, setContentCards] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [linkName, setLinkName] = useState("");
  const [showModalEdit, setShowModalEdit] = useState(false);

  const toggle = () => {
    setIsOpen(false);
  };

  const handleBoxOption = (option, card) => {
    setLinkName(card.contactPoint);
    setSelectedCard(card);

    if (option === "Excluir") {
      setShowModalDelete(true);
    } else if (option === "Editar") {
      setShowModalEdit(true);
    }
  };

  const handleContentCards = () => {
    const data = [];

    data.push(
      !advancedLinks.length ? (
        <div className="noCards">
          <Intro3 className="img" />
          <p>
            Não há links avançados criados {"\n"}
            até o momento.
          </p>
          <Button
            size="medium"
            iconPosition="left"
            icon={IconAdd}
            onClick={() => {
              setShowModalAddLink(true);
            }}
          >
            Criar novo link
          </Button>
        </div>
      ) : (
        <>
          <div className="cards">
            {advancedLinks.map((card, index) => (
              <CardOtherLink
                key={index}
                title={card.contactPoint}
                description={card.description}
                qtdAnswers={card.colectedAnswers}
                linkChatbot={`${process.env.REACT_APP_BASE_URL}${card.link}`}
                textButton={card.buttonName}
                onClick={card.onClick}
                onClickOption={(option) => handleBoxOption(option, card)}
              />
            ))}
          </div>
          <div className="bottom">
            <BottomTabNewLink
              value={advancedLinks.length}
              onClickNewQuestion={() => setShowModalAddLink(true)}
            />
          </div>
        </>
      )
    );

    return data;
  };

  useEffect(() => {
    setContentCards(handleContentCards());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        ...alert,
        active: false,
      });
      setShowModalDelete(false);
    }, 3000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [alert.active]);

  if (!isOpen) return null;

  return (
    <>
      {alert.active && (
        <div className="alert">
          <Alerts open={alert.active} type="Success" message={alert.message} />
        </div>
      )}
      <ModalEditAdvancedLink
        isOpen={showModalEdit}
        setIsOpen={setShowModalEdit}
        advancedLink={selectedCard}
      />
      <ModalDefault
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        modalColor="cancel"
        title="Excluir link secundário"
        description={`Você tem certeza que deseja excluir esse link secundário <b>${linkName}</b>?`}
        buttons={[
          {
            name: "Cancelar",
            color: "primary",
            type: "auxiliary",
            onClick: () => {
              setShowModalDelete(false);
            },
          },
          {
            name: "Excluir link",
            color: "cancel",
            type: "default",
            onClick: () => {
              setAlert({
                ...alert,
                active: true,
                message: "Link excluído com sucesso!",
              });
            },
          },
        ]}
      />
      <ModalQRCode
        isOpen={showModalQRCode}
        setIsOpen={setShowModalQRCode}
        url={urlQRCode}
      />
      <ModalEmbed isOpen={showModalEmbed} setIsOpen={setShowModalEmbed} />
      <ModalAdvancedLinks
        isOpen={showModalAddLink}
        setIsOpen={setShowModalAddLink}
      />
      <div className="compModalConfigAdvancedLinks">
        <CenterNoIcon
          title="Links avançados"
          buttonName="Salvar"
          buttonNameStyle="default"
          onClickButton={() => console.log("Salvar links avançados")}
          onClickBack={toggle}
        />
        <main>{contentCards}</main>
      </div>
    </>
  );
};

export default ModalConfigAdvancedLinks;
