import React from "react";
import "./style.scss";
import "../style.scss";
import Button from "../../Button";
import Input from "../../Input";
import OptionConfiguration from "../OptionConfiguration";

const MenuInteractive = (props) => {
  const {
    questionNumber,
    questionValues,
    setQuestionValues,
    handleColor,
    handleOptionValue,
    handleRemoveOption,
    moveDownOption,
    moveUpOption,
  } = props;

  const handleUrlValue = (optionNumber, url) => {
    let arrayTemp = [...questionValues.questions];
    let arrayWithNewColor = [...arrayTemp[questionNumber].options];
    arrayWithNewColor[optionNumber] = {
      ...arrayWithNewColor[optionNumber],
      url: url,
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewColor,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const handleTypeOfOption = (optionNumber, type) => {
    let arrayTemp = [...questionValues.questions];
    let arrayWithNewType = [...arrayTemp[questionNumber].options];
    arrayWithNewType[optionNumber] = {
      ...arrayWithNewType[optionNumber],
      type: type,
    };
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: arrayWithNewType,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const handleAddOption = (questionNumber) => {
    let arrayTemp = [...questionValues.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: [
        ...arrayTemp[questionNumber].options,
        { color: "#606c88", next: 0, value: "", url: "", type: "link" },
      ],
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const handleClickInput = (element) => {
    document.getElementById(element).click();
  };

  return (
    <div className="compMenuInteractive">
      {questionValues.questions[questionNumber].options.map((option, index) => {
        return (
          <div key={index} className="option">
            <div className="optionsMenuInteractive">
              <div
                className="optionMenuInteractive"
                onClick={() => handleTypeOfOption(index, "link")}
              >
                <div className="boxCheck">
                  {questionValues.questions[questionNumber].options[index]
                    .type === "link" ? (
                    <div className="boxChecked"></div>
                  ) : null}
                </div>
                Link
              </div>
              <div
                className="optionMenuInteractive"
                onClick={() => handleTypeOfOption(index, "interactive")}
              >
                <div className="boxCheck">
                  {questionValues.questions[questionNumber].options[index]
                    .type === "interactive" ? (
                    <div className="boxChecked"></div>
                  ) : null}
                </div>
                Botão interativo
              </div>
            </div>
            <OptionConfiguration
              option={option}
              index={index}
              questionNumber={questionNumber}
              handleColor={handleColor}
              handleOptionValue={handleOptionValue}
              handleClickInput={handleClickInput}
              handleRemoveOption={handleRemoveOption}
              moveDownOption={moveDownOption}
              moveUpOption={moveUpOption}
            />
            <div className="inputMenuInteractive">
              {questionValues.questions[questionNumber].options[index].type ===
              "link" ? (
                <Input
                  layout="default"
                  placeholder="Inserir a URL"
                  setValue={(value) => handleUrlValue(index, value)}
                  type="text"
                  value={
                    questionValues.questions[questionNumber].options[index].url
                  }
                />
              ) : null}
            </div>
          </div>
        );
      })}
      <Button
        onClick={() => handleAddOption(questionNumber)}
        size="small"
        type="auxiliary"
        variant="primary"
      >
        Adicionar Opção
      </Button>
    </div>
  );
};

export default MenuInteractive;
