import React, { useState } from "react";
import { ReactComponent as FiRrAngleDown } from "../../../Assets/img/regular/fi-rr-angle-down.svg";

import Button from "../../Button";
import ClosedAnswer from "../CloseAnswer";
import QuestionConditions from "../QuestionConditions";
import EmojiScale from "../EmojiScale";
import InsertImage from "../InsertImage";
import InsertParagraph from "../InsertTextBox";
import InsertVideo from "../InsertVideo";
import LikeDislike from "../LikeDislike";
import MenuEditQuestionOff from "../../MenuEditQuestionOff";
import MenuEditQuestionOn from "../../MenuEditQuestionOn";
import MenuInteractive from "../MenuInteractive";
import ModalOptions from "../../Modals/ModalOptions";
import ModalPresets2 from "../../Modals/ModalPresets2";
import MultipleChoice from "../MultipleChoice";
import NumericalScale from "../NumericalScale";
import OpenAnswer from "../OpenAnswer";
import StarScale from "../StarScale";

import Emoji1 from "../../../Assets/img/emoji-1.svg";
import Emoji2 from "../../../Assets/img/emoji-2.svg";
import Emoji3 from "../../../Assets/img/emoji-3.svg";
import Emoji4 from "../../../Assets/img/emoji-4.svg";
import Emoji5 from "../../../Assets/img/emoji-5.svg";
import { ReactComponent as FiSrStar } from "../../../Assets/img/solid/fi-sr-star.svg";
import { ReactComponent as FiSrShumbsDown } from "../../../Assets/img/solid/fi-sr-thumbs-down.svg";
import { ReactComponent as FiSrShumbsUp } from "../../../Assets/img/solid/fi-sr-thumbs-up.svg";

import "../style.scss";

const AnswerType = (props) => {
  const {
    addQuestion,
    currentLastQuestion,
    handleAddOption,
    handleCancel,
    handleColor,
    handleDuplicateQuestion,
    handleEdit,
    handleKeyDown,
    handleMessage,
    handleNextMultipleOptions,
    handleNextOptions,
    handleOptionValue,
    handleRemoveOption,
    handleSave,
    handleSaveAndNew,
    lastQuestion,
    moveDownOption,
    moveDownQuestion,
    moveUpOption,
    moveUpQuestion,
    questionNumber,
    questionValues,
    removeQuestion,
    setQuestionValues,
    type,
  } = props;
  const [modalAnswersOpen, setModalAnswersOpen] = useState(false);
  const [modalPresetsOpen, setModalPresetsOpen] = useState(false);

  const handleClickInput = (element) => {
    document.getElementById(element).click();
  };

  const handleInputToSave = () => {
    handleSave(questionNumber);
  };

  const handleInputToSaveNew = () => {
    handleSaveAndNew(questionNumber);
  };

  const condicionalsArea = (type) => {
    const types = [
      "insertImage",
      "insertParagraph",
      "insertVideo",
      "multipleChoice",
      "openAnswerCellPhone",
      "openAnswerCPF",
      "openAnswerDate",
      "openAnswerEmail",
      "openAnswerFreeText",
      "openAnswerHour",
      "openAnswerOnlyNumbers",
      "openAnswerPhone",
    ];

    if (types.includes(type)) return;

    return (
      <div className="conditionsArea">
        <hr />
        <span>Lógica (Condicionais de resposta)</span>
        {questionValues.questions[questionNumber].options.map(
          (option, index) => {
            return (
              <QuestionConditions
                key={index}
                index={index}
                option={option}
                questionNumber={questionNumber}
                questionValues={questionValues}
                handleNextOptions={handleNextOptions}
              />
            );
          }
        )}
      </div>
    );
  };

  const handleQuestionType = (element) => {
    const typeMappings = {
      openAnswerCellPhone: "Pergunta Aberta",
      openAnswerCPF: "Pergunta Aberta",
      openAnswerDate: "Pergunta Aberta",
      openAnswerEmail: "Pergunta Aberta",
      openAnswerFreeText: "Pergunta Aberta",
      openAnswerHour: "Pergunta Aberta",
      openAnswerOnlyNumbers: "Pergunta Aberta",
      openAnswerPhone: "Pergunta Aberta",
      starThreeScale: "Escala Estrela",
      starFiveScale: "Escala Estrela",
      emojiScale: "Escala Emoji",
      closeAnswer: "Pergunta Fechada",
      insertImage: "Inserir Link Imagem",
      insertVideo: "Inserir Link Video",
      insertParagraph: "Inserir Parágrafo",
      likeDislike: "Like e Dislike",
      menuInteractive: "Menu Interativo com Links",
      multipleChoice: "Múltipla Escolha",
      numericalScale: "Escala Numérica",
    };

    return typeMappings[element.type] || "Pergunta Desconhecida";
  };

  const showOptionsEditOn = (type) => {
    switch (type) {
      case "emojiScale":
        return (
          <EmojiScale
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
          />
        );
      case "closeAnswer":
        return (
          <ClosedAnswer
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
          />
        );
      case "insertImage":
        return (
          <InsertImage
            questionValues={questionValues}
            questionNumber={questionNumber}
            setQuestionValues={setQuestionValues}
          />
        );
      case "insertVideo":
        return (
          <InsertVideo
            questionValues={questionValues}
            questionNumber={questionNumber}
            setQuestionValues={setQuestionValues}
          />
        );
      case "insertParagraph":
        return (
          <InsertParagraph
            questionValues={questionValues}
            questionNumber={questionNumber}
            setQuestionValues={setQuestionValues}
          />
        );
      case "likeDislike":
        return (
          <LikeDislike
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
          />
        );
      case "menuInteractive":
        return (
          <MenuInteractive
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            setQuestionValues={setQuestionValues}
          />
        );
      case "multipleChoice":
        return (
          <MultipleChoice
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            setQuestionValues={setQuestionValues}
          />
        );
      case "numericalScale":
        return (
          <NumericalScale
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            questionNumber={questionNumber}
            setQuestionValues={setQuestionValues}
          />
        );
      case "openAnswerCellPhone":
      case "openAnswerCPF":
      case "openAnswerDate":
      case "openAnswerEmail":
      case "openAnswerFreeText":
      case "openAnswerHour":
      case "openAnswerOnlyNumbers":
      case "openAnswerPhone":
        return (
          <OpenAnswer
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            setQuestionValues={setQuestionValues}
          />
        );
      case "starThreeScale":
      case "starFiveScale":
        return (
          <StarScale
            handleClickInput={handleClickInput}
            questionValues={questionValues}
            handleColor={handleColor}
            handleOptionValue={handleOptionValue}
            handleRemoveOption={handleRemoveOption}
            handleKeyDown={handleKeyDown}
            moveDownOption={moveDownOption}
            moveUpOption={moveUpOption}
            questionNumber={questionNumber}
            handleAddOption={handleAddOption}
            handleNextOptions={handleNextOptions}
            handleNextMultipleOptions={handleNextMultipleOptions}
            setQuestionValues={setQuestionValues}
          />
        );
      default:
        return;
    }
  };

  /*****************************************************************************
   * / Edit off functions
   ****************************************************************************/
  const showOptionsEditOffWithImage = (option, index) => {
    switch (type) {
      case "emojiScale":
        const emojiImages = [Emoji1, Emoji2, Emoji3, Emoji4, Emoji5];
        if (index >= 0 && index < emojiImages.length) {
          return <img alt={`Emoji ${index + 1}`} src={emojiImages[index]} />;
        }
        return option.value;
      case "likeDislike":
        return index === 0 ? (
          <FiSrShumbsUp className="iconDefault likeIcon" />
        ) : index === 1 ? (
          <FiSrShumbsDown className="iconDefault dislikeIcon" />
        ) : (
          <div style={{ backgroundColor: option.color }}>{option.value}</div>
        );
      case "starThreeScale":
      case "starFiveScale":
        const starQuantity = [];
        const starNumber = parseInt(option.value.split("-")[1]);
        for (let i = 0; i < starNumber; i++) {
          starQuantity.push(
            <FiSrStar className="iconDefault starIcon" key={i} />
          );
        }
        return (
          <div style={{ backgroundColor: option.color }}>{starQuantity}</div>
        );
      default:
        return option.value;
    }
  };

  const showOptionsEditOff = () => {
    if (type === "insertImage") {
      return (
        <div className="imageVideoContainer">
          <img
            alt="Uploaded by user"
            src={questionValues.questions[questionNumber].options}
          />
        </div>
      );
    } else if (type === "insertVideo") {
      return (
        <div className="imageVideoContainer">
          <iframe
            title="Mídia enviada pelo usuário"
            width="300"
            height="200"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            src={
              props.questionValues.questions[props.questionNumber].options
                .replace("watch?v=", "embed/")
                .split("&")[0]
            }
          />
        </div>
      );
    } else if (type === "multipleChoice") {
      const options = questionValues.questions[questionNumber].options;
      return (
        <>
          {options.map((option, index) => (
            <div
              key={index}
              className="showOptionsButton"
              style={{
                backgroundColor: option.color,
                color: option.textColor,
              }}
            >
              {option.value}
            </div>
          ))}
          {questionValues.questions[questionNumber].otherOption?.value && (
            <div className="showOptionsButton">Outra (escreva abaixo)</div>
          )}
          {questionValues.questions[questionNumber].noneQuestion?.value && (
            <div className="showOptionsButton">
              Nenhuma das opções anteriores
            </div>
          )}
        </>
      );
    } else {
      const options = questionValues.questions[questionNumber].options;
      return options.map((option, index) => (
        <div
          key={index}
          className="showOptionsButton"
          style={{
            backgroundColor: option.color,
          }}
        >
          {showOptionsEditOffWithImage(option, index)}
        </div>
      ));
    }
  };

  if (!questionValues.questions[questionNumber].edit) {
    return (
      <div className="defaultQuestionLayout">
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility:
              lastQuestion === questionNumber + 1 ? "visible" : "hidden",
          }}
        />
        <div className="mainAreaDefault">
          <div className="titleDefault">{`Pergunta ${
            questionNumber + 1
          } - ${handleQuestionType(
            questionValues.questions[questionNumber]
          )}`}</div>
          <div className="mainSpace">
            <p className="mainText">
              {questionValues.questions[questionNumber].message}
            </p>
            <div className="mainOptionsArea">
              {!["insertParagraph", "openAnswer"].includes(type) &&
                showOptionsEditOff()}
            </div>
          </div>
          <MenuEditQuestionOff
            onClickTrash={() => removeQuestion(questionNumber)}
            onClickCopy={() => handleDuplicateQuestion(questionNumber)}
            onClickArrowDown={() => moveDownQuestion(questionNumber)}
            onClickArrowUp={() => moveUpQuestion(questionNumber)}
            onClickEdit={() => handleEdit(questionNumber)}
            onClickAdd={() => addQuestion(questionNumber)}
            lastQuestion={currentLastQuestion}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="defaultQuestionLayout">
        <ModalOptions
          questionNumber={questionNumber}
          questionValues={questionValues}
          setQuestionValues={setQuestionValues}
          isOpen={modalAnswersOpen}
          toggle={() => setModalAnswersOpen(!modalAnswersOpen)}
        />
        <ModalPresets2
          questionNumber={questionNumber}
          questionValues={questionValues}
          setQuestionValues={setQuestionValues}
          isOpen={modalPresetsOpen}
          toggle={() => setModalPresetsOpen(!modalPresetsOpen)}
        />
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility:
              lastQuestion === questionNumber + 1 ? "visible" : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="mainAreaDefault">
            <div className="titleDefault">{`Pergunta ${
              questionNumber + 1
            } - ${handleQuestionType(
              questionValues.questions[questionNumber]
            )}`}</div>
            <div className="mainSpace" id={`Pergunta${questionNumber + 1}`}>
              <Button
                innerClassName="buttonPresetDefault"
                onClick={() => setModalPresetsOpen(true)}
                size="small"
                type="auxiliary"
                variant="primary"
              >
                Presets
              </Button>
              <textarea
                rows="3"
                className="textareaDefault"
                value={questionValues.questions[questionNumber].message}
                placeholder="Digite aqui a sua pergunta"
                onChange={(event) => {
                  handleMessage(questionNumber, event.target.value);
                  handleKeyDown(event);
                }}
              />
              <Button
                onClick={() => {
                  setModalAnswersOpen(true);
                }}
                size="small"
                type="default"
                variant="primary"
              >
                {handleQuestionType(questionValues.questions[questionNumber])}
                <FiRrAngleDown className="iconButtonSelectQuestionDefault" />
              </Button>
              {showOptionsEditOn(type)}
              {condicionalsArea(type)}
            </div>
            <MenuEditQuestionOn
              onClickCrossCircle={() => handleCancel(questionNumber)}
              onClickCheck={handleInputToSave}
              onClickAdd={handleInputToSaveNew}
              lastQuestion={currentLastQuestion}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default AnswerType;
