import React from "react";
import "./style.scss";
import OptionConfiguration from "../OptionConfiguration";
import Button from "../../Button";
import { ReactComponent as FiSrStar } from "../../../Assets/img/solid/fi-sr-star.svg";

const StarScale = (props) => {
  const {
    handleAddOption,
    handleClickInput,
    handleColor,
    handleKeyDown,
    handleOptionValue,
    handleRemoveOption,
    moveDownOption,
    moveUpOption,
    questionNumber,
    questionValues,
    setQuestionValues,
    setTooltipOpen,
  } = props;

  const handleTypeQuestion = (typeOfQuestion) => {
    let arrayTemp = [...questionValues.questions];
    if (typeOfQuestion === "starThreeScale") {
      let optionsOfQuestion = arrayTemp[questionNumber].options;
      let restArray = optionsOfQuestion.slice(5, optionsOfQuestion.length);
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        type: typeOfQuestion,
        options: [
          { color: "#606c88", next: 0, value: "star-1" },
          { color: "#606c88", next: 0, value: "star-2" },
          { color: "#606c88", next: 0, value: "star-3" },
          ...restArray,
        ],
      };
    } else {
      let optionsOfQuestion = arrayTemp[questionNumber].options;
      let restArray = optionsOfQuestion.slice(3, optionsOfQuestion.length);
      arrayTemp[questionNumber] = {
        ...arrayTemp[questionNumber],
        type: typeOfQuestion,
        options: [
          { color: "#606c88", next: 0, value: "star-1" },
          { color: "#606c88", next: 0, value: "star-2" },
          { color: "#606c88", next: 0, value: "star-3" },
          { color: "#606c88", next: 0, value: "star-4" },
          { color: "#606c88", next: 0, value: "star-5" },
          ...restArray,
        ],
      };
    }
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compStarsScale">
      <div className="choiseStarsArea">
        <div className="optionsStars">
          <div
            className="boxCheck"
            onClick={() => handleTypeQuestion("starThreeScale")}
          >
            {questionValues.questions[questionNumber].type ===
            "starThreeScale" ? (
              <div className="boxChecked"></div>
            ) : null}
          </div>
          <div>
            <div>
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
            </div>
            3-stars
          </div>
        </div>
        <div className="optionsStars">
          <div
            className="boxCheck"
            onClick={() => handleTypeQuestion("starFiveScale")}
          >
            {questionValues.questions[questionNumber].type ===
            "starFiveScale" ? (
              <div className="boxChecked"></div>
            ) : null}
          </div>
          <div className="starsArea">
            <div>
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
              <FiSrStar className="iconDefault starIcon" />
            </div>
            5-stars
          </div>
        </div>
      </div>
      <div className="optionsConfigurationArea">
        {questionValues.questions[questionNumber].options.map(
          (option, index) => {
            if (option.value.match(/^star-[1-5]$/)) {
              let stars = [];
              for (let i = 1; i <= index + 1; i++) {
                stars.push(
                  <FiSrStar className="iconDefault starIcon" key={i} />
                );
              }
              return (
                <div key={index} className="optionConfigurationArea">
                  <div className="elipse"></div>
                  <div>Opção {index + 1}:</div>
                  {stars}
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <OptionConfiguration
                    option={option}
                    index={index}
                    questionNumber={questionNumber}
                    handleColor={handleColor}
                    setTooltipOpen={setTooltipOpen}
                    handleOptionValue={handleOptionValue}
                    handleClickInput={handleClickInput}
                    handleKeyDown={handleKeyDown}
                    handleRemoveOption={handleRemoveOption}
                    moveDownOption={moveDownOption}
                    moveUpOption={moveUpOption}
                  />
                </div>
              );
            }
          }
        )}
        <Button
          onClick={() => handleAddOption(questionNumber)}
          size="small"
          type="auxiliary"
          variant="primary"
        >
          Adicionar Opção
        </Button>
      </div>
    </div>
  );
};

export default StarScale;
