import React, {
  useState,
  useRef,
  useContext,
} from "react";
import { Context } from "../../Context/Context";
import { useParams } from "react-router-dom";
import { Tooltip } from "reactstrap";
import PropTypes from "prop-types";
import { ReactComponent as FiRrCheck } from "../../Assets/img/regular/fi-rr-check.svg";
import { ReactComponent as FiRrRefresh } from "../../Assets/img/regular/fi-rr-refresh.svg";
import ProjectsService from "../../Services/ProjectsService";
import "./style.scss";

/**
 * @typedef {'small' | 'medium' } FieldCardSize
*/

/**
 * @typedef {Object} FieldCardProps
 * @property {FieldCardSize} size
 */

/**
 * @param {FieldCardProps}
 * @returns {JSX.Element}
*/

const FieldCard = ({
  link,
  setLink,
  linkStock,
  setLinkStock,
  size,
  disabled,
}) => {
  const { userInformation } = useContext(Context);
  const { projectId } = useParams();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const inputRef = useRef(null);
  const LINK_REGEX = /^([a-zA-Z0-9]|_){0,50}$/;

  const handleChange = (e) => {
    const { value } = e.target;

    if (value.match(LINK_REGEX)) {
      setLink(value);
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
      setTooltipMessage("Permitido somente letras e/ou números");
    }
  }

  const handleResetLinkOnDatabase = async() => {
    setLink(projectId);
    let response = await ProjectsService.setLinkOfProject(
      projectId,
      projectId,
      userInformation.email
    );
    if (response) {
      setEdit(false);
    }
    setLinkStock(link);
  };

  const handleSetLinkOnDatabase = async() => {
    if (link.length < 6) {
      setTooltipMessage("Coloque no mínimo 6 caracteres para o link");
      setShowTooltip(true);
      return;
    }

    if (linkStock !== link) {
      let response = await ProjectsService.setLinkOfProject(
        projectId,
        link,
        userInformation.email
      );

      if (response === "This url already exists") {
        setTooltipMessage("Este link já existe");
        setShowTooltip(true);
        return;
      } else if (response === "An error happened") {
        setTooltipMessage("Um erro aconteceu. Tente novamente mais tarde.");
        setShowTooltip(true);
        return;
      } else {
        setEdit(false);
        setLinkStock(link);
      }
    } else {
      setEdit(false);
    }
    setShowTooltip(false);
  };

  const inputStyle = {
    fontSize: size === 'small' ? 12 : 14,
  };

  return (
    <div className="compFieldCard">
      <div className="divEdit">
        <Tooltip
          placement="top"
          isOpen={showTooltip}
          target={"tooltipInput"}
        >
          {tooltipMessage}
        </Tooltip>
        <input
          ref={inputRef}
          id="tooltipInput"
          className={
            edit
            ? "inputLink active"
            : "inputLink"
          }
          style={inputStyle}
          type="text"
          value={link}
          onChange={handleChange}
          onFocus={() => setEdit(true)}
          disabled={disabled}
      />
        { edit &&
          <div className="icons">
            <FiRrCheck
              className="icon"
              onClick={handleSetLinkOnDatabase}
            />
            <FiRrRefresh
              className="icon"
              onClick={handleResetLinkOnDatabase}
            />
          </div>
        }
      </div>
    </div>
  )
}

FieldCard.propTypes = {
  link: PropTypes.string,
  setLink: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClickConfirm: PropTypes.func,
  onClickRefresh: PropTypes.func,
}

FieldCard.defaultProps = {
  value: '',
  size: '',
  disabled: false,
}

export default FieldCard;
