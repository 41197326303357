import { firebaseFirestore } from "../Config/firebase.js";
import firebase from "firebase/app";
import Axios from "axios";
require("dotenv").config();

export default class ProjectsService {
  static getProjects = async (userProjects) => {
    let dataToReturn = [];
    for (let i = 0; i < userProjects.length; i = i + 10) {
      let querySnapshot = await firebaseFirestore
        .collection("projects")
        .where("id", "in", userProjects.slice(i, i + 10))
        .get();

      querySnapshot.forEach((document) => {
        dataToReturn.push(document.data());
      });
    }

    return dataToReturn;
  };

  static getProjectsIdss = async () => {
    let idssProjects = [];

    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("subscription", "in", ["Idss", "IdssUltra"])
      .get();

    querySnapshot.forEach((document) => {
      idssProjects.push({
        user: document.data().email,
        projects: document.data().projects,
      });
    });
    return idssProjects;
  };

  static newProjectInFirebase = async (userInformation, projectInfomations) => {
    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userInformation.email)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    await firebaseFirestore
      .collection("users")
      .doc(docRef)
      .update({
        projects: firebase.firestore.FieldValue.arrayUnion(
          projectInfomations.id
        ),
      });

    await firebaseFirestore
      .collection("projects")
      .doc()
      .set({
        id: projectInfomations.id,
        json: JSON.stringify(projectInfomations.structure),
        name: projectInfomations.name,
        created_at: new Date(),
      });
  };

  static getReportData = async (project) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/report_data/`,
      data: project,
    }).then((res) => {
      response = res.data.data;
    });
    return response;
  };

  static getSpecificProject = async (project) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", project)
      .get();
    let dataToReturn = [];

    querySnapshot.forEach((document) => {
      dataToReturn.push(document.data());
    });

    return dataToReturn;
  };

  static setMultipleAnswer = async (idProject, value, email) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", idProject)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    await firebaseFirestore
      .collection("projects")
      .doc(docRef)
      .update({ multiple_answer: value });

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}update_cookie_time/`,
      data: JSON.stringify({
        userEmail: email,
        campaign: idProject,
        cookie: value,
      }),
    }).then((res) => {
      return res.data;
    });
  };

  static setLinkOfProject = async (idProject, value, email) => {
    let response = await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}update_link/`,
      data: JSON.stringify({
        userEmail: email,
        campaign: idProject,
        url: value,
      }),
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.data;
      });

    if (response.message === "This url already exists") {
      return "This url already exists";
    } else if (response.message === "Static link was updated") {
      let querySnapshot = await firebaseFirestore
        .collection("projects")
        .where("id", "==", idProject)
        .get();

      let docRef = "";
      querySnapshot.forEach((document) => {
        docRef = document.id;
      });

      await firebaseFirestore
        .collection("projects")
        .doc(docRef)
        .update({ link: value });

      return true;
    }
  };

  static getAvgTimeAnswer = async (idProject, email, lastQuestion) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/avg_answer/`,
      data: JSON.stringify({
        userEmail: email,
        campaign: idProject,
        lastQuestion,
      }),
    }).then((res) => {
      response = res.data.data;
    });
    return response;
  };

  static saveProject = async (projectId, jsonProject, nameProject) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", projectId)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    await firebaseFirestore.collection("projects").doc(docRef).update({
      json: jsonProject,
      name: nameProject,
    });
  };

  static deleteProject = async (userInformation, projectId) => {
    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userInformation.email)
      .get();

    let docData = "";
    let docRef = "";
    querySnapshot.forEach((document) => {
      docData = document.data();
      docRef = document.id;
    });

    let arrayProjects = [];
    for (let i = 0; i < docData.projects.length; i++) {
      if (docData.projects[i] !== projectId) {
        arrayProjects.push(docData.projects[i]);
      }
    }

    await firebaseFirestore
      .collection("users")
      .doc(docRef)
      .update({ projects: arrayProjects });

    let response = "";
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}delete_campaign/`,
      data: JSON.stringify({
        user: userInformation.email,
        campaign: projectId,
      }),
    }).then((res) => {
      response = res.data;
    });

    return response;
  };

  static deleteMultiplesProjects = async (userInformation, projects) => {
    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userInformation.email)
      .get();

    let docData = "";
    let docRef = "";
    querySnapshot.forEach((document) => {
      docData = document.data();
      docRef = document.id;
    });

    const updatedProjects = docData.projects.filter(
      (projectId) =>
        !projects.some((project) => project.idProject === projectId)
    );

    await firebaseFirestore
      .collection("users")
      .doc(docRef)
      .update({ projects: updatedProjects });

    let response = "";
    projects.forEach(async (project) => {
      await Axios({
        crossDomain: true,
        dataType: "json",
        method: "post",
        contentType: "application/json",
        url: `${process.env.REACT_APP_BASE_URL_API}delete_campaign/`,
        data: JSON.stringify({
          user: userInformation.email,
          campaign: project.idProject,
        }),
      }).then((res) => {
        response = res.data;
      });
    });

    return response;
  };

  static getProjectsTotalAnswers = async (
    userEmail,
    userProjects,
    month = false
  ) => {
    let data =
      month === true
        ? {
            userEmail: userEmail,
            campaigns: userProjects,
            month: month,
          }
        : {
            userEmail: userEmail,
            campaigns: userProjects,
          };
    let response = "";
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/total_answers/`,
      data: JSON.stringify(data),
    }).then((res) => {
      response = res.data.data;
    });
    return response;
  };

  static getDatabaseToXls = async (data) => {
    let response = "";
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/excel_data/`,
      data: data,
    }).then((res) => {
      response = res.data.data;
    });
    return response;
  };

  static setClosingProject = async (
    idProject,
    email,
    closing,
    subscription
  ) => {
    let response = "";

    await Axios.put(
      `${process.env.REACT_APP_BASE_URL_API}v2/closed_projects/`,
      JSON.stringify({
        projectId: idProject,
        userEmail: email,
        closing: closing,
        subscription: subscription,
      })
    )
      .then((res) => {
        response = res.data;
      })
      .catch(() => {
        response = false;
      });
    return response;
  };

  static getStatusActiveFromProjects = async (
    arrayIdProjects,
    email,
    subscription
  ) => {
    let response = "";
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/closed_projects/`,
      data: JSON.stringify({
        projectIds: arrayIdProjects,
        userEmail: email,
        subscription: subscription,
      }),
    }).then((res) => {
      response = res.data;
    });
    return response;
  };

  static setShareProjectInFinal = async (idProject, value, email) => {
    await firebaseFirestore
      .collection("projects")
      .where("id", "==", idProject)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          await firebaseFirestore
            .collection("projects")
            .doc(document.id)
            .update({ share_final: value });
        });
      });

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}update_share/`,
      data: JSON.stringify({
        userEmail: email,
        campaign: idProject,
        share: value,
      }),
    }).then((res) => {
      return res.data;
    });
  };

  static setUpdateFolder = async (idProject, folder) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", idProject)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    await firebaseFirestore
      .collection("projects")
      .doc(docRef)
      .update({ folder: folder });
  };

  static getOwnerProject = async (project) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/owner_project/`,
      data: project,
    }).then((res) => {
      response = res.data.data;
    });
    return response;
  };

  static setFavoriteProject = async (idProject, favorited) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", idProject)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    await firebaseFirestore
      .collection("projects")
      .doc(docRef)
      .update({ favorited: favorited });
  };
}
