import React, { useEffect, useState } from "react";
import "./style.scss";
import Dropdown from "../../Dropdown";
import { ReactComponent as FiSrStar } from "../../../Assets/img/solid/fi-sr-star.svg";
import { ReactComponent as FiSrShumbsDown } from "../../../Assets/img/solid/fi-sr-thumbs-down.svg";
import { ReactComponent as FiSrShumbsUp } from "../../../Assets/img/solid/fi-sr-thumbs-up.svg";
import { ReactComponent as FiSrFrown } from "../../../Assets/img/solid/fi-sr-frown.svg";
import { ReactComponent as FiSrSad } from "../../../Assets/img/solid/fi-sr-sad.svg";
import { ReactComponent as FiSrMeh } from "../../../Assets/img/solid/fi-sr-meh.svg";
import { ReactComponent as FiSrSmile } from "../../../Assets/img/solid/fi-sr-smile.svg";
import { ReactComponent as FiSrLaugh } from "../../../Assets/img/solid/fi-sr-laugh.svg";

const QuestionConditions = (props) => {
  const { handleNextOptions, index, option, questionNumber, questionValues } =
    props;
  const [selected, setSelected] = useState([]);

  const returnImage = (value) => {
    if (value.match(/^emoji-[0-5]{1}$/)) {
      switch (value) {
        case "emoji-1":
          return <FiSrFrown className="iconDefault emojiFrownIcon" />;
        case "emoji-2":
          return <FiSrSad className="iconDefault emojiSadIcon" />;
        case "emoji-3":
          return <FiSrMeh className="iconDefault emojiMehIcon" />;
        case "emoji-4":
          return <FiSrSmile className="iconDefault emojiSmileIcon" />;
        case "emoji-5":
          return <FiSrLaugh className="iconDefault emojiLaughIcon" />;
        default:
          return value;
      }
    } else if (value === "Like") {
      return <FiSrShumbsUp className="iconDefault likeIcon" />;
    } else if (value === "Dislike") {
      return <FiSrShumbsDown className="iconDefault dislikeIcon" />;
    } else if (value.match(/^star-[0-5]{1}$/)) {
      const qtdStars = value.split("-")[1] * 1;
      let stars = [];
      for (let i = 1; i < qtdStars + 1; i++) {
        stars.push(<FiSrStar className="iconDefault starIcon" key={i} />);
      }

      return stars;
    } else {
      return value;
    }
  };

  useEffect(() => {
    const handleConditionalLogic = (value) => {
      if (value === "Despedida") {
        handleNextOptions(questionNumber, index, 99999);
      } else if (value === "Próxima Pergunta") {
        handleNextOptions(questionNumber, index, 0);
      } else {
        let valueToFind = value.replace("Pergunta ", "");
        handleNextOptions(
          questionNumber,
          index,
          questionValues.questions[valueToFind - 1].number
        );
      }
    };

    if (selected.length > 0) {
      handleConditionalLogic(selected[0]);
    }
    // eslint-disable-next-line
  }, [selected]);

  return (
    <div key={index} className="compQuestionConditions">
      <div className="firstArea">
        <div className="optionCondition">{returnImage(option.value)}</div>
        <div
          className="optionColor"
          style={{
            backgroundColor: option.color,
            color: option.textColor !== undefined ? option.textColor : "white",
          }}
        ></div>
      </div>
      <div className="secondArea">
        ir para
        <Dropdown
          onlyOne={true}
          label=""
          options={[
            "Próxima Pergunta",
            ...questionValues.questions.map((_, index) => {
              return `Pergunta ${index + 1}`;
            }),
            "Despedida",
          ]}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
};

export default QuestionConditions;
