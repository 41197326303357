import "./style.scss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ReactComponent as FiSrArrowSmallLeft } from "../../../Assets/img/solid/fi-sr-arrow-small-left.svg";

/*******************************************************************************
 * How to use:
 * <CenterNoIcon
    buttonName="button"
    buttonNameStyle="red"
    onClickButton={() => {
      return null;
    }}
    title="title"
  />
 ******************************************************************************/

function CenterNoIcon(props) {
  const { buttonName, buttonNameStyle, onClickBack, onClickButton, title } = props;
  const history = useHistory();

  return (
    <div className="compCenterNoIcon">
      <div
        className="left"
        onClick={() => {
          onClickBack
          ? onClickBack()
          : history.goBack();
        }}
      >
        <FiSrArrowSmallLeft
          className="icon"
          data-testid="arrow-small-left-icon"
        />
        <span>Voltar</span>
      </div>
      <div className="middle">{title}</div>
      <div className="right">
        <span className={buttonNameStyle} onClick={onClickButton}>
          {buttonName}
        </span>
      </div>
    </div>
  );
}

CenterNoIcon.propTypes = {
  buttonName: PropTypes.string.isRequired,
  buttonNameStyle: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

CenterNoIcon.defaultProps = {
  buttonName: "",
  buttonNameStyle: "default",
  onClickButton: () => {},
  title: "",
};

export default CenterNoIcon;
