import React from "react";
import Input from "../../Input";
import "./style.scss";

const InsertVideo = (props) => {
  const { questionNumber, questionValues, setQuestionValues } = props;

  const handleVideoUrl = (url) => {
    let arrayTemp = [...questionValues.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: url,
      error: false,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compInsertVideo">
      <Input
        layout="default"
        placeholder="Cole aqui a URL do vídeo do YouTube"
        setValue={handleVideoUrl}
        type="text"
        value={questionValues.questions[questionNumber].options}
      />
    </div>
  );
};

export default InsertVideo;
