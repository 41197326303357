import React, { useState, useEffect } from "react";
import "./style.scss";

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  useEffect(() => {
    function handleCurrentSlide() {
      slides.forEach((slide, index) => {
        if (index === currentIndex) {
          setCurrentSlide(
            <div className="slide active" key={index}>
              {slide}
            </div>
          );
        }
      });
    }

    if (slides !== null) {
      return handleCurrentSlide();
    } else {
      return null;
    }
  }, [currentIndex, slides]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-container">
      <div className="carousel" data-testid="carousel">
        {currentSlide}
      </div>
      <div className="dots">
        {slides.map((_, index) => (
          <div
            className={`dot ${index === currentIndex ? "active" : ""}`}
            data-testid="dot"
            key={index}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
