import React from "react";
import "./style.scss";
import ButtonOnOff from "../../ButtonOnOff";
import Button from "../../Button";
import CondicionalsMultipleChoice from "../CondicionalsMultipleChoice";
import OptionConfiguration from "../OptionConfiguration";

const MultipleChoice = (props) => {
  const {
    questionValues,
    questionNumber,
    setQuestionValues,
    handleColor,
    setTooltipOpen,
    handleOptionValue,
    handleClickInput,
    handleRemoveOption,
    handleKeyDown,
    moveDownOption,
    moveUpOption,
    handleAddOption,
    handleNextOptions,
    handleNextMultipleOptions,
  } = props;
  const handleChangeOtherOption = (value) => {
    let arrayTemp = [...questionValues.questions];
    if (arrayTemp[questionNumber].otherOption === undefined) {
      arrayTemp[questionNumber].otherOption = {
        value: value,
        next: 0,
      };
    } else {
      arrayTemp[questionNumber].otherOption = {
        ...arrayTemp[questionNumber].otherOption,
        value: value,
      };
    }
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const handleChangeNoneOption = (value) => {
    let arrayTemp = [...questionValues.questions];
    if (arrayTemp[questionNumber].noneQuestion === undefined) {
      arrayTemp[questionNumber].noneQuestion = {
        value: value,
        next: 0,
      };
    } else {
      arrayTemp[questionNumber].noneQuestion = {
        ...arrayTemp[questionNumber].noneQuestion,
        value: value,
      };
    }
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const showConditionOtherOption = () => {
    if (questionValues.questions[questionNumber].otherOption !== undefined) {
      if (questionValues.questions[questionNumber].otherOption.value === true) {
        return (
          <div className="optionOtherOption">
            <div
              className="option"
              style={{ backgroundColor: "#606c88", color: "white" }}
            >
              Outra (escreva abaixo)
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const showConditionNoneQuestion = () => {
    if (questionValues.questions[questionNumber].noneQuestion !== undefined) {
      if (
        questionValues.questions[questionNumber].noneQuestion.value === true
      ) {
        return (
          <div className="optionNoneQuestion goToCompNoneQuestion">
            <div
              className="option"
              style={{ backgroundColor: "#606c88", color: "white" }}
            >
              Nenhuma das opções anteriores
            </div>
            <div className="gap goToComp">
              ir para
              <select
                className="dropdownSelectQuestion"
                value={
                  questionValues.questions[questionNumber].noneQuestion
                    .value === 0
                    ? "Próxima Pergunta"
                    : questionValues.questions[questionNumber].noneQuestion.next
                }
                onChange={(event) => handleNoneQuestion(event.target.value)}
              >
                <option>Próxima Pergunta</option>
                {questionValues.questions.map((question, index) => {
                  return (
                    <option key={question.number} value={question.number}>
                      Pergunta {index + 1}
                    </option>
                  );
                })}
                <option value="99999">Despedida</option>
              </select>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const handleNoneQuestion = (value) => {
    let arrayTemp = [...questionValues.questions];
    let next = value;
    switch (value) {
      case "Próxima Pergunta":
        next = 0;
        break;
      case "Despedida":
        next = 99999;
        break;
      default:
        next = parseInt(value);
        break;
    }
    arrayTemp[questionNumber].noneQuestion = {
      ...arrayTemp[questionNumber].noneQuestion,
      next: next,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compMultipleChoice">
      {questionValues.questions[questionNumber].options.map((option, index) => {
        return (
          <div key={index} className="options">
            <OptionConfiguration
              option={option}
              index={index}
              questionNumber={questionNumber}
              handleColor={handleColor}
              setTooltipOpen={setTooltipOpen}
              handleOptionValue={handleOptionValue}
              handleClickInput={handleClickInput}
              handleRemoveOption={handleRemoveOption}
              handleKeyDown={handleKeyDown}
              moveDownOption={moveDownOption}
              moveUpOption={moveUpOption}
            />
          </div>
        );
      })}
      <Button
        onClick={() => handleAddOption(questionNumber)}
        size="small"
        type="auxiliary"
        variant="primary"
      >
        Adicionar Opção
      </Button>
      <hr />
      <div className="configurationOption">
        Outra (escreva abaixo)
        <ButtonOnOff
          on={
            questionValues.questions[questionNumber].otherOption !== undefined
              ? questionValues.questions[questionNumber].otherOption.value
              : false
          }
          handleValue={() =>
            handleChangeOtherOption(
              questionValues.questions[questionNumber].otherOption !== undefined
                ? !questionValues.questions[questionNumber].otherOption.value
                : true
            )
          }
        />
      </div>
      <div className="configurationOption">
        Nenhuma das opções anteriores
        <ButtonOnOff
          on={
            questionValues.questions[questionNumber].noneQuestion !== undefined
              ? questionValues.questions[questionNumber].noneQuestion.value
              : false
          }
          handleValue={() =>
            handleChangeNoneOption(
              questionValues.questions[questionNumber].noneQuestion !==
                undefined
                ? !questionValues.questions[questionNumber].noneQuestion.value
                : true
            )
          }
        />
      </div>
      <div className="conditionsArea">
        <hr />
        <span>Lógica (Condicionais de resposta)</span>
        <div className="optionsConditions">
          <div className="multipleOptionsBox">
            {questionValues.questions[questionNumber].options.map(
              (option, index) => {
                return (
                  <CondicionalsMultipleChoice
                    key={index}
                    index={index}
                    option={option}
                    questionNumber={questionNumber}
                    questionValues={questionValues}
                    handleNextOptions={handleNextOptions}
                  />
                );
              }
            )}
            {showConditionOtherOption()}
          </div>
          <div className="gap goToComp">
            ir para
            <select
              className="dropdownSelectQuestion"
              value={
                questionValues.questions[questionNumber].options[0].next === 0
                  ? "Próxima Pergunta"
                  : questionValues.questions[questionNumber].options[0].next
              }
              onChange={(event) =>
                handleNextMultipleOptions(questionNumber, event.target.value)
              }
            >
              <option>Próxima Pergunta</option>
              {questionValues.questions.map((question, index) => {
                return (
                  <option key={question.number} value={question.number}>
                    Pergunta {index + 1}
                  </option>
                );
              })}
              <option value="99999">Despedida</option>
            </select>
          </div>
        </div>
        <hr />
        {showConditionNoneQuestion()}
      </div>
    </div>
  );
};

export default MultipleChoice;
