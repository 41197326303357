import React from "react";
import "./style.scss";
import Input from "../../Input";

const NumericalScale = (props) => {
  const { questionNumber, questionValues, setQuestionValues } = props;

  const handleMin = (value) => {
    let arrayTemp = [...questionValues.questions];
    let arrayWithOptions = [];

    if (value < 0) {
      value = 0;
    } else if (value > 10) {
      value = 10;
    } else if (!value) {
      value = 0;
    }

    let difference = arrayTemp[questionNumber].max - value;
    for (let i = 0; i <= difference; i++) {
      arrayWithOptions.push({
        color: generateColor(i, difference),
        next: 0,
        value: String(i + value),
      });
    }
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      min: value,
      options: arrayWithOptions,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const handleMax = (value) => {
    let arrayTemp = [...questionValues.questions];
    let arrayWithOptions = [];

    if (value < 0) {
      value = 0;
    } else if (value > 10) {
      value = 10;
    } else if (!value) {
      value = 0;
    }

    for (let i = arrayTemp[questionNumber].min; i <= value; i++) {
      arrayWithOptions.push({
        color: generateColor(i, value - arrayTemp[questionNumber].min),
        next: 0,
        value: String(i),
      });
    }
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      max: value,
      options: arrayWithOptions,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  const generateColor = (position, size) => {
    let jump = 11 / size;
    if (jump === 11) {
      jump = 10;
    } else if (jump === Infinity) {
      jump = 0;
    }

    let arrayColors = [
      "#B72025",
      "#D82028",
      "#EE5223",
      "#F36E23",
      "#FAA723",
      "#FFC828",
      "#ECDC0D",
      "#E8E73D",
      "#C6D92F",
      "#AFCF3D",
      "#64B64F",
    ];
    return arrayColors[
      Math.floor(jump * position) > 10 ? 10 : Math.floor(jump * position)
    ];
  };

  return (
    <div className="scaleArea">
      <div className="optionScale">
        Escala de
        <Input
          layout="default"
          placeholder="dd/mm/aaaa"
          setValue={handleMin}
          type="number"
          value={questionValues.questions[questionNumber].min}
        />
        (min)
      </div>
      <div className="optionScale">
        Até
        <Input
          layout="default"
          placeholder="dd/mm/aaaa"
          setValue={handleMax}
          type="number"
          value={questionValues.questions[questionNumber].max}
        />
        (max)
      </div>
    </div>
  );
};

export default NumericalScale;
