import { firebaseAuth, firebaseFirestore } from "../Config/firebase.js";
import Axios from "axios";
import firebaseApp from "firebase/app";

export default class UserService {
  static getCurrentUserEmail = () => {
    return firebaseAuth.currentUser.email;
  };

  static getUserInformation = async () => {
    let userEmail = await this.getCurrentUserEmail();

    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get();
    let dataToReturn = null;

    querySnapshot.forEach((document) => {
      dataToReturn = document.data();
    });

    return dataToReturn;
  };

  static setAccountPlan = async (typePlan, idSubscriptionPaypal) => {
    let userEmail = await this.getCurrentUserEmail();

    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            subscription: typePlan,
            subscriptionAt: new Date(),
            idSubscriptionPaypal: idSubscriptionPaypal,
          });
        });
      });
  };

  // Set false for new users
  static updateNewUser = async () => {
    let userEmail = await this.getCurrentUserEmail();
    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            isNewUser: false,
          });
        });
      });
  };

  static updateUserNameOnFirebaseStore = async (nameToUpdate) => {
    let status = {};
    let userEmail = this.getCurrentUserEmail();
    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then(async (snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            name: nameToUpdate,
          });
        });
        status = {
          message: "Field name updated",
          code: "success",
        };
      })
      .catch((error) => {
        status = {
          message: error.message,
          code: error.code,
        };
      });
    return status;
  };

  static updateUserPhoneOnFirebaseStore = async (phoneToUpdate) => {
    let status = {};
    let userEmail = this.getCurrentUserEmail();
    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then(async (snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            phone: phoneToUpdate,
          });
        });
        status = {
          message: "Field phone updated",
          code: "success",
        };
      })
      .catch((error) => {
        status = {
          message: error.message,
          code: error.code,
        };
      });
    return status;
  };

  static updateUserEmail = async (currentEmail, emailToUpdate, password) => {
    let status = {};

    // Update email user on auth firebase
    const credential = firebaseApp.auth.EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      password
    );
    await firebaseAuth.currentUser
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await firebaseAuth.currentUser
          .updateEmail(emailToUpdate)
          .then(async () => {
            // Update email user on firebaseStore
            await firebaseFirestore
              .collection("users")
              .where("email", "==", currentEmail)
              .get()
              .then(async (snap) => {
                snap.forEach(async (document) => {
                  let docRef = document.id;
                  await firebaseFirestore
                    .collection("users")
                    .doc(docRef)
                    .update({
                      email: emailToUpdate,
                    });
                });
                // Update email user on MySql
                await Axios.put(
                  `${process.env.REACT_APP_BASE_URL_API}update_user_email/`,
                  JSON.stringify({
                    email_user: currentEmail,
                    email_to_update: emailToUpdate,
                  })
                )
                  .then((response) => {
                    status = {
                      message: response,
                      code: "success",
                    };
                  })
                  .catch((error) => {
                    status = {
                      message: error,
                      code: error.code,
                    };
                  });
              })
              .catch((error) => {
                status = {
                  message: error.message,
                  code: error.code,
                };
              });
          })
          .catch((error) => {
            status = {
              message: error.message,
              code: error.code,
            };
          });
      })
      .catch((error) => {
        status = {
          message: error.message,
          code: error.code,
        };
      });
    return status;
  };
}
