import "./style.scss";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ReactComponent as FiSrArrowSmallLeft } from "../../../Assets/img/solid/fi-sr-arrow-small-left.svg";
import { ReactComponent as FiRrFilter } from "../../../Assets/img/regular/fi-rr-filter.svg";
import { ReactComponent as FiRrBookmark } from "../../../Assets/img/regular/fi-rr-bookmark.svg";
import { ReactComponent as FiSrBookmark } from "../../../Assets/img/solid/fi-sr-bookmark.svg";

/*******************************************************************************
 * How to use:
 * <Center2Icon
    onClickFilter={() => {
      return null;
    }}
    onClickBookMark={() => {
      return null;
    }}
    onClickBack={() => {
      return null;
    }}
    titlePage="Página"
    selectedBookmark={false}
  />
 ******************************************************************************/

function Center2Icon(props) {
  const { onClickFilter, onClickBookMark, onClickBack, titlePage, selectedBookmark } = props;
  const history = useHistory();

  return (
    <div className="compCenter2Icon">
      <div className="top">
        <div
          className="left"
          onClick={() => {
            onClickBack ? onClickBack() : history.goBack();
          }}
        >
          <FiSrArrowSmallLeft
            className="icon"
            data-testid="arrow-small-left-icon"
          />
          <span>Voltar</span>
        </div>
        <div className="middle">{titlePage}</div>
        <div className="right">
          {onClickFilter && (
            <FiRrFilter
              className="icon"
              data-testid="filter-icon"
              onClick={onClickFilter}
            />
          )}
          {onClickBookMark && (
            selectedBookmark ?
              <FiSrBookmark
                className="icon"
                data-testid="bookmark-icon"
                onClick={onClickBookMark}
              />
            :
              <FiRrBookmark
                className="icon"
                data-testid="bookmark-icon"
                onClick={onClickBookMark}
              />
          )}
        </div>
      </div>
    </div>
  );
}

Center2Icon.propTypes = {
  titlePage: PropTypes.string.isRequired,
};

export default Center2Icon;
