import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import ProjectsService from "../../Services/ProjectsService";
import Database from "../../Components/Database";
import NavigationMenu from "../../Components/NavigationMenu";

import Center2Icon from "../../Components/TopBar/Center2Icon";
import Report from "../../Components/Report";
import Button from "../../Components/Button";

const Reports = () => {
  let { projectId } = useParams();
  const history = useHistory();
  const { handleTotalInteractions, userInformation, dataTableXlsx } =
    useContext(Context);
  const [dataFirebase, setDataFirebase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [showTables, setShowTables] = useState({});
  const [tabNavigation, setTabNavigation] = useState("report");

  const pdfRef = useRef();

  useEffect(() => {
    const mountShowTables = (dataFirebaseTmp) => {
      let objectShowTable = showTables;
      for (let i = 0; i < dataFirebaseTmp.questions.length; i++) {
        objectShowTable = {
          ...objectShowTable,
          [dataFirebaseTmp.questions[i].number]: false,
        };
      }
      setShowTables(objectShowTable);
    };

    const getFirebaseData = async () => {
      let dataFirebaseTmp = await ProjectsService.getSpecificProject(projectId);
      let dataFirebaseTmpParsed = JSON.parse(dataFirebaseTmp[0].json);
      dataFirebaseTmpParsed.project_name = dataFirebaseTmp[0].name;
      setDataFirebase(dataFirebaseTmpParsed);
      return dataFirebaseTmpParsed;
    };

    const getReportDatas = async (dataFirebaseTmp) => {
      let qtdAnswers = handleTotalInteractions();
      let arrayMultiple = dataFirebaseTmp.questions
        .filter((element) => {
          if (element.type === "multipleChoice") {
            return element;
          } else {
            return null;
          }
        })
        .map((element) => {
          return element.number < 10
            ? `q_0${element.number}`
            : `q_${element.number}`;
        });

      let data = await ProjectsService.getReportData(
        JSON.stringify({
          project: projectId,
          numberQuestions: dataFirebaseTmp.questions.length,
          multiple: arrayMultiple,
          qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
          user: userInformation.email,
        })
      );
      setReportData({ intro: dataFirebaseTmp.disclaimer_initial, ...data });
    };

    const main = async () => {
      if (
        userInformation.projects.indexOf(projectId) > -1 ||
        userInformation.subscription === "IdssManager"
      ) {
        let dataFirebaseTmp = await getFirebaseData();
        mountShowTables(dataFirebaseTmp);
        await getReportDatas(dataFirebaseTmp);
      }
      setLoading(false);
    };

    if (userInformation) {
      main();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  const showData = () => {
    switch (tabNavigation) {
      case "report":
        if (!reportData || !dataFirebase) {
          return <Loading />;
        } else {
          return (
            <Report
              reportData={reportData}
              dataFirebase={dataFirebase}
              projectId={projectId}
            />
          );
        }
      case "database":
        return <Database dataFirebase={dataFirebase} />;
      default:
        break;
    }
  };

  console.log();

  if (dataFirebase) {
    return (
      <div className="compReports" id="compReports" ref={pdfRef}>
        <div style={{ display: "none" }}>
          <table id="tableConsolidated">
            <tbody id="tableConsolidatedbody">{dataTableXlsx}</tbody>
          </table>
        </div>
        <Center2Icon
          onClickFilter={() => {
            return null;
          }}
          onClickBookMark={() => {
            return null;
          }}
          titlePage={dataFirebase.project_name}
          selectedBookmark={false}
        />
        <NavigationMenu active="report" projectId={projectId} />
        <nav className="reportNavigation">
          <Button
            onClick={() => setTabNavigation("report")}
            size="small"
            type={tabNavigation === "report" ? "default" : "auxiliary"}
            variant="primary"
          >
            Resultados
          </Button>
          <Button
            onClick={() => {
              if (userInformation.subscription !== "Idss") {
                setTabNavigation("database");
              }
            }}
            size="small"
            type={tabNavigation === "database" ? "default" : "auxiliary"}
            variant="primary"
          >
            Base de dados
          </Button>
        </nav>
        {loading ? <Loading /> : showData()}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Reports;
