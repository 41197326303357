import React from "react";
import ButtonOnOff from "../../ButtonOnOff";
import "./style.scss";

const OpenAnswer = (props) => {
  const handleOptionChecked = (optionSelected) => {
    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      type: optionSelected,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleSizeOfOpenText = (sizeValue) => {
    if (sizeValue > 600) {
      sizeValue = 600;
    } else if (sizeValue < 0) {
      sizeValue = 1;
    }

    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      size: sizeValue,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handlePreferNotAnswer = () => {
    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      preferNotAnswer: !arrayTemp[props.questionNumber].preferNotAnswer,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compOpenAnswer">
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerFreeText")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerFreeText" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Texto Livre
        <input
          className="inputMaxCaracteres"
          value={props.questionValues.questions[props.questionNumber].size}
          key={1}
          max="600"
          min="1"
          onChange={(event) => handleSizeOfOpenText(Number(event.target.value))}
          type="number"
        />
        caracteres <span className="exampleText">(max: 600)</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerOnlyNumbers")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerOnlyNumbers" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Somente Números
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerEmail")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerEmail" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Email <span className="exampleText">(exemplo@exemplo.com.br)</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerPhone")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerPhone" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Telefone Fixo <span className="exampleText">(012) 3456-7890</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerCellPhone")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerCellPhone" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Telefone Celular <span className="exampleText">(012) 93456-7890</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerCPF")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerCPF" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        CPF <span className="exampleText">(000.111.222-33)</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerDate")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerDate" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Data <span className="exampleText">(01/01/2021)</span>
      </div>
      <div
        className="optionLineOpenAnswer"
        onClick={() => handleOptionChecked("openAnswerHour")}
      >
        <div className="boxCheck">
          {props.questionValues.questions[props.questionNumber].type ===
          "openAnswerHour" ? (
            <div className="boxChecked"></div>
          ) : null}
        </div>
        Hora <span className="exampleText">(00:00)</span>
      </div>
      <hr />
      <div className="PreferNotAnwser">
        <div>Opção Prefiro Não Informar</div>
        <ButtonOnOff
          on={
            props.questionValues.questions[props.questionNumber].preferNotAnswer
              ? true
              : false
          }
          handleValue={handlePreferNotAnswer}
        />
      </div>
    </div>
  );
};

export default OpenAnswer;
