import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

/**
 * @typedef {'default' | 'focus' | 'after' | 'error' | 'success'} layout
 * @typedef {'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week'} type
 */

/**
 * @typedef {Object} ButtonProps
 * @property {InputLayout} layout
 * @property {InputType} type
 */

/**
 * @param {ButtonProps}
 * @returns {JSX.Element}
 */

/*******************************************************************************
 * How to use:
 * <Input
    firstIcon={<FfRiCrossCircle />}
    label="Fim"
    layout="default"
    placeholder="dd/mm/aaaa"
    secondIcon={<FfRiCrossCircle />}
    setValue={setEndDate}
    type="date"
    value={endDate}
  />
 ******************************************************************************/

const Input = (props) => {
  const {
    firstIcon,
    secondIcon,
    label,
    layout,
    placeholder,
    type,
    value,
    setValue,
  } = props;
  const inputId = `input-${Math.random().toString(36).substring(2, 15)}`;

  return (
    <div className="compInput">
      <label htmlFor={inputId}>{label}</label>
      <div className={`inputArea ${layout}Input`}>
        {firstIcon && firstIcon}
        <input
          id={inputId}
          onChange={(event) => setValue(event.target.value)}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {secondIcon && secondIcon}
      </div>
    </div>
  );
};

Input.propTypes = {
  firstIcon: PropTypes.element,
  secondIcon: PropTypes.element,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  layout: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func.isRequired,
};

Input.defaultProps = {
  firstIcon: <></>,
  secondIcon: <></>,
  label: "",
  placeholder: "",
  layout: "default",
  type: "text",
  value: "",
  setValue: () => {},
};

export default Input;
