import React, { useEffect, useState } from "react";
import "./style.scss";
import "../style.scss";
import Hazo from "../../../Assets/img/256PX-01-whiout-padding.svg";
import copy from "copy-to-clipboard";
import Alerts from "../../Alerts";
import Button from "../../Button";
import PropTypes from "prop-types";

import { ReactComponent as FiRrInfo } from "../../../Assets/img/regular/fi-rr-info.svg";

/**
 * @typedef { true | false } isOpen
 */

/**
 * @typedef {Object} ModalEmbedProps
 * @property {isOpen} isOpen
 */

/**
 * @param {ModalEmbedProps}
 * @returns {JSX.Element}
 */

/*******************************************************************************
 * How to use:
 * <ModalEmbed isOpen={true} />
 ******************************************************************************/

const ModalEmbed = (props) => {
  const { isOpen, setIsOpen } = props;
  const [tab, setTab] = useState("code");
  const [side, setSide] = useState("left");
  const [alert, setAlert] = useState({
    active: false,
    type: "Success",
    message: "",
  });

  const toggle = () => {
    setIsOpen(false);
  };

  const copyToClipboard = () => {
    copy(`
      <script>
        window.oneToOneWidgetProject = 'nWHCto';
        window.oneToOneWidgetSide = ${side};
        var s = document.createElement('script');
        s.src = 'https://widget.hazo.app/js/widget-loader.js';
        s.async = true; document.body.appendChild(s);
      </script>
    `);

    setAlert({
      active: true,
      message: "Código copiado com sucesso!",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        ...alert,
        active: false,
      });
      toggle();
    }, 3000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [alert.active]);

  if (isOpen) {
    return (
      <>
        {alert.active && (
          <div className="alert">
            <Alerts
              open={alert.active}
              type="Success"
              message={alert.message}
            />
          </div>
        )}
        <div className="wrapper">
          <div className="compModalEmbed">
            <h2>Ver código embed</h2>
            <nav>
              <ul>
                <li
                  className={tab === "code" ? "active" : ""}
                  onClick={() => setTab("code")}
                >
                  Código
                </li>
                <li
                  className={tab === "preview" ? "active" : ""}
                  onClick={() => setTab("preview")}
                >
                  Preview
                </li>
              </ul>
            </nav>
            <section>
              <p className="description">
                Para implementar o embed, copie o código abaixo e cole no seu
                HTML.
              </p>
              {tab === "code" && (
                <div className="codeArea">
                  <div className="code">
                    {`<script>
                      window.oneToOneWidgetProject = 'nWHCto';
                      window.oneToOneWidgetSide = ${side};
                      var s = document.createElement('script');
                      s.src = 'https://widget.hazo.app/js/widget-loader.js';
                      s.async = true; document.body.appendChild(s);
                    </script>`}
                  </div>
                  <div className="information">
                    <FiRrInfo className="icon" data-testid="info-icon" />
                    <p>
                      A cor do botão e o avatar acompanham as customizações
                      efetuadas no chatbot.
                    </p>
                  </div>
                  <div className="buttonsArea">
                    <Button
                      type="auxiliary"
                      size="medium"
                      variant="primary"
                      onClick={toggle}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="default"
                      size="medium"
                      variant="primary"
                      onClick={copyToClipboard}
                    >
                      Copiar código
                    </Button>
                  </div>
                </div>
              )}
              {tab === "preview" && (
                <div className="previewArea">
                  <div
                    className={
                      side === "left"
                        ? "messagePreview messagePreviewLeft"
                        : "messagePreview messagePreviewRight"
                    }
                  >
                    <div className="image">
                      <img src={Hazo} alt="hazo" />
                    </div>
                    <div className="messageArea">
                      <p>
                        Olá, eu sou o Hazo! Conte-me, como está a sua
                        experiência?
                      </p>
                      <Button
                        type="default"
                        size="medium"
                        variant="primary"
                        onClick={copyToClipboard}
                      >
                        Vamos ter um bate-papo
                      </Button>
                    </div>
                  </div>
                  <p>Escolha como deseja apresentar o chat do hazo no site:</p>
                  <ul>
                    <li onClick={() => setSide("left")} className="left">
                      Esquerda
                    </li>
                    <li onClick={() => setSide("right")} className="right">
                      Direita
                    </li>
                  </ul>
                </div>
              )}
            </section>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

ModalEmbed.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

ModalEmbed.defaultProps = {
  isOpen: false,
};

export default ModalEmbed;
