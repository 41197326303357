import React, { useRef, useState } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { ReactComponent as FiSrStar } from "../../Assets/img/solid/fi-sr-star.svg";
import { ReactComponent as FiRrStar } from "../../Assets/img/regular/fi-rr-star.svg";

/*******************************************************************************
 * How to use:
 * <CardProjects
    active={true}
    detailing={{
      questions: 10,
      answers: 5,
      completation: 50,
      updated_at: "10/10/2023",
    }}
    favorited={true}
    newQuestion={true}
    projectColor="green"
    projectDate="31/08/2023"
    projectName="teste"
    selected={false}
  />
 ******************************************************************************/

const CardProjects = (props) => {
  const {
    active,
    detailing,
    favorited,
    newQuestion,
    onClickFavorite,
    onClickCard,
    onClickSelected,
    projectColor,
    projectDate,
    projectName,
    selected,
    selectedProjects,
  } = props;

  const cardRef = useRef(null);
  const [isLongClick, setIsLongClick] = useState(false);
  const [isLongPress, setIsLongPress] = useState(false);
  const [isLongPressTimer, setIsLongPressTimer] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const mainStyle = selected
    ? {
        borderTopColor: projectColor,
        borderTopStyle: "solid",
        borderTopWidth: "6px",
        borderLeftColor: projectColor,
        borderLeftStyle: "solid",
        borderLeftWidth: "2px",
        borderRightColor: projectColor,
        borderRightStyle: "solid",
        borderRightWidth: "2px",
        borderBottomColor: projectColor,
        borderBottomStyle: "solid",
        borderBottomWidth: "2px",
      }
    : {
        borderTopColor: projectColor,
        borderTopStyle: "solid",
        borderTopWidth: "6px",
      };
  const touchStartRef = useRef(0);
  let touchStartY = 0;
  let touchStartTime = 0;

  const handleMouseDown = () => {
    cardRef.current = setTimeout(() => {
      setIsLongClick(true);
      onClickSelected();
    }, 500);
  };

  const handleMouseUp = () => {
    clearTimeout(cardRef.current);

    if (!isLongClick) {
      selectedProjects.length >= 1 ? onClickSelected() : onClickCard();
    }

    setIsLongClick(false);
  };

  const handleTouchMove = (e) => {
    const deltaY = Math.abs(e.touches[0].clientY - touchStartY);

    if (deltaY > 10) {
      setIsScrolling(true);
      clearTimeout(isLongPressTimer);
    }
  };

  const handleTouchStart = (e) => {
    touchStartY = e.touches[0].clientY;
    touchStartTime = new Date().getTime();

    const longPressTimer = setTimeout(() => {
      const currentTime = new Date().getTime();
      if (currentTime - touchStartTime >= 500) {
        setIsLongPress(true);
        onClickSelected();
      }
    }, 500);

    setIsLongPressTimer(longPressTimer);
  };

  const handleTouchEnd = () => {
    clearTimeout(isLongPressTimer);
    const touchEnd = Date.now();

    if (
      !isLongPress &&
      !isScrolling &&
      !selectedProjects.length &&
      touchEnd - touchStartRef.current < 500
    ) {
      selectedProjects.length >= 1 ? onClickSelected() : onClickCard();
    }

    setIsScrolling(false);
    setIsLongPress(false);
  };

  return (
    <div
      className="compCardProjects"
      data-testid="compCardProjects"
      style={mainStyle}
      ref={cardRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={handleTouchEnd}
    >
      <div className="titleArea">
        <div className="titleAlign">
          <h2>{projectName}</h2>
          <div className="buttonNewAnswerArea">
            {newQuestion && <div className="newAnswers">Novas Respostas</div>}
          </div>
          {favorited ? (
            <FiSrStar
              className="yellowStar"
              data-testid="sr-star-icon"
              onClick={onClickFavorite}
            />
          ) : (
            <FiRrStar
              className="star"
              data-testid="rr-star-icon"
              onClick={onClickFavorite}
            />
          )}
        </div>
        <span className="createdAt">
          {projectDate && `Criado em ${projectDate}`}
        </span>
        {active ? (
          <span className="spanStatus active">Ativo</span>
        ) : (
          <span className="spanStatus inactive">Inativo</span>
        )}
      </div>
      <div className="detailingArea">
        <div className="field">
          <span className="fieldName">Perguntas</span>
          <span className="fieldValue">{detailing?.questions}</span>
        </div>

        <div className="field">
          <span className="fieldName">Respostas</span>
          <span className="fieldValue">{detailing?.answers}</span>
        </div>

        {/* <div className="field">
          <span className="fieldName">Completo</span>
          <span className="fieldValue">{detailing?.completation}%</span>
        </div>

        <div className="field">
          <span className="fieldName">Atualizado em</span>
          <span className="fieldValue">{detailing?.updated_at}</span>
        </div> */}
      </div>
    </div>
  );
};

CardProjects.propTypes = {
  active: PropTypes.bool.isRequired,
  detailing: PropTypes.shape({
    questions: PropTypes.number,
    answers: PropTypes.number,
    completation: PropTypes.number,
    updated_at: PropTypes.string,
  }),
  favorited: PropTypes.bool.isRequired,
  newQuestion: PropTypes.bool.isRequired,
  onClickFavorite: PropTypes.func.isRequired,
  onClickCard: PropTypes.func.isRequired,
  projectColor: PropTypes.string.isRequired,
  projectDate: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default CardProjects;
