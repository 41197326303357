import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../Button";
import "./style.scss";

/**
 * @typedef {'primary' | 'cancel' | 'success'} ModalDefaultColor
 */

/**
 * @typedef {'default' | 'outline' | 'auxiliary' | 'text'} ModalDefaultButtonType
 */

/**
 * @typedef {Object} ModalDefaultProps
 * @property {ModalDefaultColor} modalColor
 * @property {ModalDefaultButtonType} type
 */

/**
 * @param {ModalDefaultProps}
 * @returns {JSX.Element}
 */

const ModalDefault = ({
  title,
  description,
  isOpen,
  setIsOpen,
  modalColor,
  buttons,
  children,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const toogle = () => setIsOpen(!isOpen);
  const handleResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("wrapper")) {
        toogle();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="wrapper">
      <div className={`compModalDefault ${modalColor}`}>
        <span className="title">{title}</span>
        {description && (
          <span
            className="description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {children}
        <div className="footer">
          {buttons.map((button, index) => (
            <Button
              key={index}
              variant={button.color}
              type={button.type}
              size={windowWidth <= 768 ? "medium" : "large"}
              onClick={button.onClick}
            >
              {button.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

ModalDefault.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
  modalColor: PropTypes.oneOf(["primary", "cancel", "success"]).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.oneOf(["primary", "cancel", "success", "disable"])
        .isRequired,
      type: PropTypes.oneOf(["default", "outline", "auxiliary", "text"])
        .isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

ModalDefault.defaultProps = {
  title: "",
  description: "",
  isOpen: false,
  setIsOpen: () => {},
  buttons: [{}],
};

export default ModalDefault;
