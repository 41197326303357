import "./style.scss";
import Input from "../../Input";
import PropTypes from "prop-types";
import { ReactComponent as FiRrSearch } from "../../../Assets/img/regular/fi-rr-search.svg";
import { ReactComponent as FiRrCrossCircle } from "../../../Assets/img/regular/fi-rr-cross-circle.svg";

/*******************************************************************************
 * How to use:
 * <Search
    value={test}
    setValue={setTest}
    onClickClose={() => {
      return null;
    }}
  />
 ******************************************************************************/

function Search(props) {
  const { value, setValue, onClickClose } = props;
  const handleClearInput = () => setValue("");

  return (
    <div className="compSearch">
      <div className="left">
        <Input
          firstIcon={<FiRrSearch className="icon" />}
          layout="default"
          placeholder="Pesquisar projeto..."
          setValue={setValue}
          type="text"
          value={value}
        />
      </div>
      { value !== "" &&
        <div
          className="secondIcon"
          onClick={handleClearInput}
        >
          <FiRrCrossCircle className="iconClose"/>
        </div>
      }
      <div className="right">
        <span onClick={onClickClose}>Fechar</span>
      </div>
    </div>
  );
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default Search;
