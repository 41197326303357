import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hazo from "../../Assets/img/hazo.svg";
import "./style.scss";

const Draft = ({ textBox, animated, style }) => {
  const [isVisible, setIsVisible] = useState(false);

  const styleDefault = style ? style : null;

  const styleInvisible = {
    display: isVisible === false && "none",
  };

  useEffect(() => {
    if (animated) {
      let timer = setTimeout(() => {
        setIsVisible(true);
      }, 200);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={animated ? "compDraft styleVisible" : "compDraft"}
      style={animated ? styleInvisible && styleDefault : styleDefault}
      data-testid="compDraft"
    >
      <div className="box">
        <span data-testid="text">{textBox}</span>
      </div>
      <div className="boxImg">
        <img className="hazo" src={Hazo} alt="Hazo" data-testid="hazo-avatar" />
      </div>
    </div>
  );
};

Draft.propTypes = {
  textBox: PropTypes.string.isRequired,
  animated: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

Draft.defaultProps = {
  textBox: "Faça seu login ou cadastre-se no Hazo.",
  animated: true,
  style: {},
};

export default Draft;
