import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../Context/Context";
import { ReactComponent as ArrowLeft } from "../../../Assets/img/solid/fi-sr-arrow-small-left.svg";
import { ReactComponent as Edit } from "../../../Assets/img/regular/fi-rr-edit.svg";
import { ReactComponent as Trash } from "../../../Assets/img/regular/fi-rr-trash.svg";
import { ReactComponent as Chatbot } from "../../../Assets/img/regular/fi-rr-comments.svg";
import { ReactComponent as Share } from "../../../Assets/img/regular/fi-rr-share.svg";
import { ReactComponent as Charts } from "../../../Assets/img/regular/fi-rr-signal-alt.svg";
import CardSession from "../../CardSession";
import ModalDefault from "../ModalDefault";
import Input from "../../Input";
import Alerts from "../../Alerts";
import ProjectsService from "../../../Services/ProjectsService";
import "./style.scss";
import { useHistory } from "react-router-dom";

const ModalProjectDetails = ({
  isOpen,
  setIsOpen,
  userProjects,
  project,
  typeProject,
}) => {
  const {
    saveProject,
    userInformation,
    setUserProjects,
    setUserOthersProjects,
    setFavoriteProjects,
    setFavoriteOtherProjects,
  } = useContext(Context);
  const history = useHistory();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [newProjectName, setNewProjectName] = useState(project.nameProject);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [alert, setAlert] = useState({
    active: false,
    type: "",
    message: "",
  });
  const cards = [
    {
      title: "Customização",
      description:
        "Configure e personalize o seu projeto de acordo com a identidade visual da sua marca.",
      onClick: () => history.push(`/customization/${project.idProject}`),
    },
    {
      title: "Chatbot",
      description:
        "Crie seu chatbot, edite as perguntas, revise os fluxos de interação e simule o resultado final.",
      icon: <Chatbot className="iconCard" />,
      onClick: () => history.push(`/botmaker/${project.idProject}`),
    },
    {
      title: "Compartilhamento",
      description:
        "Configure o seu link de compartilhamento e envie o projeto através do whatsapp e outros.",
      icon: <Share className="iconCard" />,
      onClick: () => history.push(`/share/${project.idProject}`),
    },
    {
      title: "Resultados",
      description:
        "Acompanhe os resultados e avalie as métricas para a melhor tomada de decisão",
      icon: <Charts className="iconCard" />,
      onClick: () => history.push(`/report/${project.idProject}`),
    },
  ];

  const handleText = (text) => {
    setNewProjectName(text);

    if (text === "") {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleEditProject = async () => {
    let projectTemp = project;
    projectTemp.nameProject = newProjectName;

    let jsonParsed = JSON.parse(projectTemp.jsonProject);
    jsonParsed.project_name = newProjectName;
    projectTemp.jsonProject = JSON.stringify(jsonParsed);

    let arrayToSet = userProjects.map((element) => {
      return element.idProject === projectTemp.idProject
        ? projectTemp
        : element;
    });

    setUserProjects(arrayToSet);
    await saveProject(
      projectTemp.idProject,
      projectTemp.jsonProject,
      projectTemp.nameProject
    )
      .then(() => {
        setAlert({
          active: true,
          type: "Success",
          message: "Alterações concluídas com sucesso!",
        });
      })
      .catch(() => {
        setAlert({
          active: true,
          type: "Error",
          message:
            "Erro ao alterar nome do projeto! Tente novamente mais tarde...",
        });
      });

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModalEdit(false);
  };

  const handleDeleteProject = () => {
    ProjectsService.deleteProject(userInformation, project.idProject)
      .then(() => {
        setAlert({
          active: true,
          type: "Success",
          message: "Projeto excluído com sucesso!",
        });

        let arrayTemp = [];
        for (let i = 0; i < userProjects.length; i++) {
          if (userProjects[i].idProject !== project.idProject) {
            arrayTemp.push(userProjects[i]);
          }
        }

        switch (typeProject) {
          case "favProjects":
            setFavoriteProjects(arrayTemp);
            break;
          case "projects":
            setUserProjects(arrayTemp);
            break;
          case "favOthers":
            setFavoriteOtherProjects(arrayTemp);
            break;
          case "others":
            setUserOthersProjects(arrayTemp);
            break;
          default:
            break;
        }

        setIsOpen(false);
      })
      .catch(() => {
        setAlert({
          active: true,
          type: "Error",
          message: "Erro ao excluir o projeto! Tente novamente mais tarde...",
        });
      });

    setShowModalDelete(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({
        ...alert,
        active: false,
      });
    }, 4000);

    return () => clearTimeout(timer);
  }, [alert]);

  useEffect(() => {
    setNewProjectName(project.nameProject);
  }, [project]);

  if (!isOpen) return null;

  return (
    <div className="compModalProjectDetails">
      <ModalDefault
        isOpen={showModalEdit}
        setIsOpen={setShowModalEdit}
        modalColor="primary"
        title="Editar nome do projeto"
        buttons={[
          {
            name: "Cancelar",
            color: "primary",
            type: "auxiliary",
            onClick: () => handleCloseModal(),
          },
          {
            name: "Salvar alterações",
            color: "primary",
            type: buttonDisabled ? "disable" : "default",
            onClick: () => handleEditProject(),
          },
        ]}
      >
        <Input
          label="Nome do projeto"
          placeholder="Ex. Nome do projeto"
          value={newProjectName}
          setValue={handleText}
        />
      </ModalDefault>
      <ModalDefault
        isOpen={showModalDelete}
        setIsOpen={setShowModalDelete}
        modalColor="cancel"
        title="Excluir projeto"
        description={`Você perderá todos os dados da pesquisa. Tem certeza que deseja excluir o projeto <b>${project.nameProject}</b>?`}
        buttons={[
          {
            name: "Cancelar",
            color: "primary",
            type: "auxiliary",
            onClick: () => setShowModalDelete(false),
          },
          {
            name: "Excluir",
            color: "cancel",
            type: "default",
            onClick: () => handleDeleteProject(),
          },
        ]}
      />
      <header>
        <ArrowLeft className="icon" onClick={() => setIsOpen(false)} />
        <span className="title">{project.nameProject}</span>
        <div className="icons">
          <Edit className="icon" onClick={() => setShowModalEdit(true)} />
          <Trash
            className="icon trash"
            onClick={() => setShowModalDelete(true)}
          />
        </div>
      </header>
      {alert.active && (
        <div className="alert">
          <Alerts
            open={alert.active}
            type={alert.type}
            message={alert.message}
          />
        </div>
      )}
      <div className="wrapperCards">
        <div className="cards">
          {cards.map((card, index) => (
            <CardSession
              key={index}
              title={card.title}
              description={card.description}
              icon={card.icon}
              onClick={card.onClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalProjectDetails;
