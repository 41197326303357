import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import AuthService from "../../Services/AuthService";
import Alerts from "../../Components/Alerts";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import { Context } from "../../Context/Context";

import { ReactComponent as FiRrCheck } from "../../Assets/img/regular/fi-rr-check.svg";
import { ReactComponent as FiRrEye } from "../../Assets/img/regular/fi-rr-eye.svg";
import { ReactComponent as FiRrEyeCrossed } from "../../Assets/img/regular/fi-rr-eye-crossed.svg";
import { ReactComponent as Hazo } from "../../Assets/img/signin/hazo-avatar.svg";

const SignIn = () => {
  const { alert, setAlert } = useContext(Context);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [stayConnected, setStayConnected] = useState(true);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleLogin();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  const handleLogin = async () => {
    if (email === "" || password === "") {
      setAlert({
        active: true,
        type: "Error",
        message: "Preencha todos os campos",
      });
    }

    let loginValues = {
      emailLogin: email,
      passwordLogin: password,
    };

    await AuthService.signIn(
      loginValues.emailLogin,
      loginValues.passwordLogin,
      stayConnected
    )
      .then((response) => {
        let errorMessage = verifyErroCode(response);
        if (errorMessage !== null) {
          setAlert({
            active: true,
            type: "Error",
            message: errorMessage,
          });
        } else {
          setAlert({
            active: false,
            type: "",
            message: "",
          });
          history.push("/projects");
        }
      })
      .catch((err) => {
        const errorCode = err.code;
        let errorMessage = verifyErroCode(errorCode);

        if (errorMessage !== null) {
          setAlert({
            active: true,
            type: "Error",
            message: errorMessage,
          });
        }
      });
  };

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const verifyErroCode = (error) => {
    switch (error) {
      case "auth/user-disabled":
        return "Essa conta foi removida.";
      case "auth/user-not-found":
        return "O usuário informado não possui cadastro.";
      case "auth/email-already-in-use":
        return "Já existe uma conta com o endereço de email fornecido.";
      case "auth/invalid-email":
        return "O endereço de e-mail não é válido.";
      case "auth/account-exists-with-different-credential":
        return "E-mail já esta cadastrado em outra conta.";
      case "auth/wrong-password":
        return "Senha incorreta.";
      case "auth/invalid-email-verified":
        return "O e-mail é inválido.";
      default:
        return null;
    }
  };

  return (
    <div className="CompSignIn">
      <div className="header">
        <div className="chatText">Faça seu login ou cadastre-se no Hazo.</div>
        <div className="hazoArea">
          <Hazo className="hazoImage" />
        </div>
      </div>
      {alert.active && (
        <div className="alert">
          <Alerts
            open={alert.active}
            type={alert.type}
            message={alert.message}
            toggle={() =>
              setAlert({
                active: false,
                type: "Error",
                message: "",
              })
            }
          />
        </div>
      )}
      <div className="content">
        <Input
          label="Nome"
          layout={
            email.match(/.+@.+\..+/g) || email === "" ? "default" : "error"
          }
          secondIcon={
            email.match(/.+@.+\..+/g) ? (
              <FiRrCheck className="icon" data-testid="email-check-icon" />
            ) : null
          }
          placeholder="Nome e sobrenome"
          setValue={setEmail}
          type="text"
          value={email}
        />
        <Input
          label="Senha"
          layout={password.length >= 6 || password === "" ? "default" : "error"}
          secondIcon={
            passwordVisible ? (
              <FiRrEye
                className="eyeicon"
                data-testid="eye-visible-icon"
                onClick={handleShowPassword}
              />
            ) : (
              <FiRrEyeCrossed
                className="eyeicon"
                data-testid="eye-invisible-icon"
                onClick={handleShowPassword}
              />
            )
          }
          placeholder="No mínimo 6 caracteres"
          setValue={setPassword}
          type={passwordVisible ? "text" : "password"}
          value={password}
        />
        <div className="connecteArea">
          <div className="inputArea">
            <input
              type="checkbox"
              name="stayConnected"
              id="stayConnected"
              checked={stayConnected}
              onChange={(event) => setStayConnected(event.target.checked)}
            />
            <label htmlFor="stayConnected">Manter conectado</label>
          </div>
          <span
            className="forgotPassword"
            onClick={() => history.push("/forgotpassword")}
          >
            Esqueci minha senha
          </span>
        </div>
        <Button
          dataTestid="submit-button"
          type="default"
          size="large"
          variant={
            email.match(/.+@.+\..+/g) && password.length >= 6
              ? "primary"
              : "disable"
          }
          onClick={
            email.match(/.+@.+\..+/g) && password.length >= 6
              ? handleLogin
              : null
          }
        >
          Entrar
        </Button>
      </div>
      <div className="footer">
        <p className="firstText">Ainda não tem uma conta?</p>
        <p className="secondText" onClick={() => history.push("/signup")}>
          Cadastre-se agora
        </p>
      </div>
    </div>
  );
};

export default SignIn;
