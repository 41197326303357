import React, { useContext, useState, useRef } from "react";
import "./style.scss";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "../../Context/Context";
import Button from "../../Components/Button";
import JsonDictionaryService from "../../Services/JsonDictionaryService";
import LogoService from "../../Services/LogoService";
import CenterNoIcon from "../../Components/TopBar/CenterNoIcon";
import HazoAvatar from "../../Assets/img/signin/hazo-avatar.svg";
import NavigationMenu from "../../Components/NavigationMenu";
import ModalCropImage from "../../Components/Modals/ModalCropImage";

const Customization = () => {
  const history = useHistory();
  const { projectId } = useParams();
  const { chatbot, createDictionary } = useContext(Context);

  const logoInputRef = useRef(null);
  const avatarInputRef = useRef(null);
  const colorInputRef = useRef(null);

  const [logoBlob, setLogoBlob] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [avatarImageName, setAvatarImageName] = useState("");
  const [messageLogo, setMessageLogo] = useState("");
  const [messageAvatar, setMessageAvatar] = useState("");
  const [modalCropImage, setModalCropImage] = useState(false);
  const [configTemp, setConfigTemp] = useState({
    robot_name: chatbot.robot_name,
    chatbot_header_color: chatbot.chatbot_header_color,
    chatbot_header_textColor: chatbot.chatbot_header_textColor,
    robot_image_name: chatbot.robot_image_name,
    business_image_name: chatbot.business_image_name,
    disclaimer_final: chatbot.disclaimer_final
      ? chatbot.disclaimer_final
      : "Obrigado! Crie também o seu hazo.app!",
  });
  const [fileToUpload, setFileToUpload] = useState({
    logo: null,
    avatar: null,
  });

  const handleClickLogoRef = () => {
    logoInputRef.current.click();
  };

  const handleClickAvatarRef = () => {
    avatarInputRef.current.click();
  };

  const handleClickColorRef = () => {
    colorInputRef.current.click();
  };

  const handleLogo = (e) => {
    if (e.target.files[0].size > 3000000) {
      setMessageLogo("O arquivo é muito grande");
      return;
    } else {
      setMessageLogo("");
    }

    let typeOfImage = e.target.files[0].name.split(".").pop();
    let base = "";
    switch (typeOfImage) {
      case "svg":
        base = "image/svg+xml";
        break;
      case "png":
        base = "image/png";
        break;
      case "jpg":
      case "jpeg":
        base = "image/jpg";
        break;
      default:
        return setMessageLogo("Formato do arquivo inválido");
    }
    setMessageLogo("");

    let fileName = "logo";
    let blob = e.target.files[0].slice(0, e.target.files[0].size, base);
    let newFile = new File([blob], `${projectId}-${fileName}.${typeOfImage}`, {
      type: base,
    });

    setConfigTemp({
      ...configTemp,
      business_image_name: `${newFile.name}?v=${Math.round(
        Math.random() * (100000 - 0) + 0
      )}`,
    });

    setFileToUpload({
      ...fileToUpload,
      logo: newFile,
    });

    setLogoBlob(URL.createObjectURL(newFile));
    e.target.value = null;
  };

  const handleAvatar = (e) => {
    if (e.target.files[0].size > 3000000) {
      setMessageAvatar("O arquivo é muito grande");
      return;
    } else {
      setMessageAvatar("");
    }

    let typeOfImage = e.target.files[0].name.split(".").pop();
    let base = "";
    switch (typeOfImage) {
      case "svg":
        base = "image/svg+xml";
        break;
      case "png":
        base = "image/png";
        break;
      case "jpg":
      case "jpeg":
        base = "image/jpg";
        break;
      default:
        return setMessageAvatar("Formato do arquivo inválido");
    }
    setMessageAvatar("");

    let fileName = "avatar";
    let blob = e.target.files[0].slice(0, e.target.files[0].size, base);
    let newFile = new File([blob], `${projectId}-${fileName}.${typeOfImage}`, {
      type: base,
    });

    setConfigTemp({
      ...configTemp,
      robot_image_name: `${newFile.name}?v=${Math.round(
        Math.random() * (100000 - 0) + 0
      )}`,
    });

    setFileToUpload({
      ...fileToUpload,
      avatar: newFile,
    });

    setAvatarBlob(URL.createObjectURL(newFile));
    setAvatarImageName(newFile.name);
    e.target.value = null;
    setModalCropImage(true);
  };

  const handleAvatarName = (event) => {
    setConfigTemp({
      ...configTemp,
      robot_name: event.target.value,
    });
  };

  const handleHeaderColor = (event) => {
    let color = event.target.value;

    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    setConfigTemp({
      ...configTemp,
      chatbot_header_color: color,
      chatbot_header_textColor: textColor,
    });
  };

  const handleSave = async () => {
    if (fileToUpload.logo !== null) {
      await LogoService.logoSave(fileToUpload.logo);
    }

    if (fileToUpload.avatar !== null) {
      await LogoService.logoSave(fileToUpload.avatar);
    }

    let chatbotObject = {
      ...chatbot,
      robot_name: configTemp.robot_name,
      chatbot_header_color: configTemp.chatbot_header_color,
      chatbot_header_textColor: configTemp.chatbot_header_textColor,
      robot_image_name: configTemp.robot_image_name,
      business_image_name: configTemp.business_image_name,
      disclaimer_final: configTemp.disclaimer_final,
    };

    await createDictionary(chatbotObject, projectId);
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      projectId,
      JSON.stringify(chatbotObject)
    );

    history.push(`/botmaker/${projectId}`);
  };

  const handleDiclaimerFinal = (event) => {
    setConfigTemp({
      ...configTemp,
      disclaimer_final: event.target.value,
    });
  };

  const handleSaveAvatarCroped = (blob) => {
    let newFile = new File([blob], `${projectId}-avatar.png`, {
      type: "image/png",
    });
    setConfigTemp({
      ...configTemp,
      robot_image_name: `${newFile.name}?v=${Math.round(
        Math.random() * (100000 - 0) + 0
      )}`,
    });
    setAvatarBlob(URL.createObjectURL(blob));
    setFileToUpload({
      ...fileToUpload,
      avatar: newFile,
    });
  };

  return (
    <div className="CompCustomization">
      <ModalCropImage
        modal={modalCropImage}
        setModal={setModalCropImage}
        image={avatarBlob}
        setImage={handleSaveAvatarCroped}
        fileName={avatarImageName}
      />
      <CenterNoIcon
        buttonName="Salvar"
        buttonNameStyle="default"
        onClickButton={handleSave}
        title="Customização"
      />
      <NavigationMenu active="customization" projectId={projectId} />
      <main>
        <div className="imageArea">
          <div className="textBox">
            Aqui você customiza o seu chatbot e deixa ele a sua cara. Você pode
            voltar aqui quando quiser.
          </div>
          <img src={HazoAvatar} alt="hazo" />
        </div>

        <div className="colorArea">
          <div className="textArea">
            <p>Personalize a cor do cabecalho de acordo com a sua marca</p>
          </div>
          <div
            className="showColor"
            style={{ backgroundColor: configTemp.chatbot_header_color }}
          >
            <input
              type="color"
              ref={colorInputRef}
              onChange={handleHeaderColor}
            />
          </div>
          <Button
            onClick={handleClickColorRef}
            size="large"
            type="auxiliary"
            variant="primary"
          >
            Escolher cor
          </Button>
        </div>
        <div className="logoAvatarArea">
          <div className="textArea">
            <p>Envie a sua logo da sua marca</p>
          </div>
          <input
            accept=".jpg, .jpeg, .png, .svg"
            className="inputHide"
            htmlFor="Altere a logo da empresa"
            onChange={handleLogo}
            ref={logoInputRef}
            type="file"
          />
          <div
            className="imgArea"
            style={{
              backgroundColor: configTemp.chatbot_header_color,
              borderColor: configTemp.chatbot_header_color,
            }}
          >
            <img
              alt="logo da empresa"
              id="tooltipLogo"
              src={
                logoBlob !== null
                  ? logoBlob
                  : `${process.env.REACT_APP_BASE_URL}dist/img/${configTemp.business_image_name}`
              }
            />
          </div>
          <Button
            onClick={handleClickLogoRef}
            size="large"
            type="auxiliary"
            variant="primary"
          >
            Enviar Logo
          </Button>
          {messageLogo !== "" && messageLogo}
          <div className="textArea">
            <p className="description">Formatos aceitos: PNG, SVG, JPEG, JPG</p>
            <p className="description">Tamanho máximo: 3MB</p>
          </div>
        </div>
        <hr></hr>
        <div className="inputsTextArea">
          <div className="textArea">
            <p>Escolha o nome do avatar</p>
          </div>
          <input
            type="text"
            value={configTemp.robot_name}
            onChange={handleAvatarName}
          />
        </div>
        <div className="logoAvatarArea">
          <input
            accept=".jpg, .jpeg, .png, .svg"
            className="inputHide"
            htmlFor="Altere a logo da empresa"
            onChange={(e) => handleAvatar(e, "avatar")}
            ref={avatarInputRef}
            type="file"
          />
          <div className="imgArea">
            <img
              alt="logo da empresa"
              className="imgLogo"
              id="tooltipLogo"
              src={
                avatarBlob !== null
                  ? avatarBlob
                  : `${process.env.REACT_APP_BASE_URL}dist/img/${configTemp.robot_image_name}`
              }
            />
          </div>
          <Button
            onClick={handleClickAvatarRef}
            size="large"
            type="auxiliary"
            variant="primary"
          >
            Enviar avatar
          </Button>
          {messageAvatar !== "" && messageAvatar}
          <div className="textArea">
            <p className="description">Formatos aceitos: PNG, SVG, JPEG, JPG</p>
            <p className="description">Tamanho máximo: 3MB</p>
          </div>
        </div>
        <hr></hr>

        <div className="inputsTextArea">
          <div className="textArea">
            <p>Escolha a mensagem de despedida do seu chatbot</p>
          </div>
          <textarea
            cols="30"
            onChange={handleDiclaimerFinal}
            rows="2"
            value={configTemp.disclaimer_final}
          ></textarea>
        </div>
      </main>
    </div>
  );
};

export default Customization;
