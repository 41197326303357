import { firebaseAuth } from "../Config/firebase.js";
import firebase from "firebase/app";
import Axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");
export const Auth = firebaseAuth;
require("dotenv").config();
export default class AuthService {
  //Serviço de cadastro
  static signUp = async (dataSignUp) => {
    const db = firebase.firestore();
    let response = null;
    moment.locale("pt-br");

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API}new_user/`,
      async: true,
      data: JSON.stringify({
        email: dataSignUp.email,
      }),
    })
      .then(async () => {
        await Axios({
          crossDomain: true,
          dataType: "json",
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL_API}v2/user/`,
          async: true,
          data: JSON.stringify({
            email: dataSignUp.email,
            name: dataSignUp.name,
            phone: dataSignUp.phone,
            subscription: "Free",
          }),
        })
          .then(async () => {
            response = "success";
          })
          .catch((error) => {
            response = error;
          });

        let day = moment().format("DD");
        let month = moment().format("MMM");
        let hour = moment().format("HH:mm");
        let monthFormated = month.charAt(0).toUpperCase() + month.slice(1);

        await firebaseAuth
          .createUserWithEmailAndPassword(dataSignUp.email, dataSignUp.password)
          .then(async (resp) => {
            let user_uid = resp.user.uid;
            db.collection("users")
              .doc(user_uid)
              .set({
                name: dataSignUp.name,
                email: dataSignUp.email.toLowerCase(),
                phone: dataSignUp.phone,
                projects: [],
                messages: [
                  {
                    id: "boasvindas",
                    title: "Seja bem-vindo(a) ao Hazo.app!",
                    description:
                      "Aqui no Hazo.app você pode criar interações por chatbot para fazer pesquisas de satisfação e de mercado, clima corporativo, link na bio, cadastro de leads e muito mais! Se você ainda não assistiu nosso tutorial, clique abaixo para assistir e conhecer mais!",
                    link: "https://www.youtube.com/watch?v=LEBnBBcMvGI",
                    linkName: "Clique aqui para assistir",
                    saved: false,
                    isNewMessage: true,
                    date: moment().format(),
                    dateTimeFormated: `${day}/${monthFormated} - ${hour}`,
                  },
                ],
                subscription: "Free",
              });
            await firebaseAuth.signInWithEmailAndPassword(
              dataSignUp.email,
              dataSignUp.password
            );
          })
          .catch((e) => {
            response = e.code;
          });

        response = "success";
      })
      .catch((error) => {
        response = error.response.data.message;
      });

    return response;
  };

  static signIn = async (email, password, stayConnected) => {
    if (stayConnected === true) {
      return await firebaseAuth.signInWithEmailAndPassword(email, password);
    } else if (stayConnected === false) {
      await firebaseAuth
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          return firebaseAuth.signInWithEmailAndPassword(email, password);
        })
        .catch((error) => {
          return error.code;
        });
    }
  };

  static signOut = async () => {
    await firebaseAuth.signOut().then(() => {
      localStorage.removeItem("@hazoapp");
    });
  };

  static reAuth = async (password, newPassword) => {
    const user = firebaseAuth.currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    let status = {};

    await user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await user
          .updatePassword(newPassword)
          .then(async () => {
            status = {
              message: "Senha alterada com sucesso!",
              type: "success",
            };
          })
          .catch(() => {
            status = {
              message: "Não foi possível alterar a senha!",
              type: "fail",
            };
          });
      })
      .catch(() => {
        status = {
          message: "Senha atual incorreta!",
          type: "fail",
        };
      });

    return status;
  };

  static resetPassword = async (email) => {
    let status = {};

    if (
      email.match(
        // eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
      )
    ) {
      await firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          status = {
            message: "Resent",
            code: "success",
          };
        })
        .catch((error) => {
          status = {
            message: error.message,
            code: error.code,
          };
        });
    }
    return status;
  };
}
