import React from "react";
import "./style.scss";
import Input from "../../Input";

const InsertImage = (props) => {
  const { questionNumber, questionValues, setQuestionValues } = props;

  const handleImageUrl = (url) => {
    let linkIsGoogleDrive = url.includes("drive.google.com");

    const imageURL = linkIsGoogleDrive
      ? `https://docs.google.com/uc?id=${url.split("/d/")[1].split("/")[0]}`
      : url;

    let arrayTemp = [...questionValues.questions];
    arrayTemp[questionNumber] = {
      ...arrayTemp[questionNumber],
      options: imageURL,
      error: false,
    };
    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <div className="compInsertImage">
      <Input
        layout="default"
        placeholder="Cole aqui a URL da imagem do Google Drive..."
        setValue={handleImageUrl}
        type="text"
        value={questionValues.questions[questionNumber].options}
      />
      <p className="warning">
        <span>Atenção:</span> Ao adicionar o link de imagem do Google Drive,
        lembre-se de configurar o compartilhamento dela como público
      </p>
    </div>
  );
};

export default InsertImage;
