import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Play } from "../../Assets/img/regular/fi-rr-play.svg";
import Button from "../Button";
import "./style.scss";

const CardConfigLink = ({ title, subtitle, description, text, textButton, onClick }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

  return(
    <div className="compCardConfigLink">
      <div>
        <span className="title">{title}</span>
      </div>
      <div className="content">
        <div>
          <Play
            className="icon"
            data-testid="play-icon"
          />
        </div>
        <div className="text">
          <span className="subtitle">
            {subtitle}
          </span>
          <span className="description">
            {description}
          </span>
        </div>
      </div>
      <div className="footer">
        <span
          className="footerText"
          dangerouslySetInnerHTML={{ __html: text}}
        />
        <Button
          type="auxiliary"
          size={
            windowWidth <= 768
            ? "medium"
            : "large"
          }
          onClick={onClick}
        >
          {textButton}
        </Button>
      </div>
    </div>
  )
}

CardConfigLink.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

CardConfigLink.defaultProps = {
  title: '',
  subtitle: '',
  description: '',
  text: '',
  textButton: '',
  onClick: () => {},
}

export default CardConfigLink;
