import React, { useContext, useState, useEffect } from "react";
import "./style.scss";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Context } from "../../Context/Context";
import { Tooltip } from "reactstrap";
import Messages from "../../Assets/img/messages.png";

import MessagesService from "../../Services/MessagesService";

const HeaderProjectsPage = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    userInformation,
    qtdNewMessages,
    setUserMessages,
    getQtdNewMessages,
  } = useContext(Context);
  const [tooltipDatabase, setTooltipOpen] = useState(false);

  useEffect(() => {
    const getUserMessages = async () => {
      await MessagesService.getMessagesFromUser().then((messages) => {
        setUserMessages(messages);
        getQtdNewMessages(messages);
      });
    };

    getUserMessages();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <header className="compHeaderProjectsPage">
        <div className="container">
          <div className="buttonArea">
            <div
              className="profileCircle"
              onClick={() => history.push("/account")}
            >
              {props.name?.substring(0, 1)}
            </div>
          </div>
          <div className="hazotext">Hazo</div>
          <Tooltip
            placement="top"
            isOpen={tooltipDatabase}
            target={"tooltipDatabase"}
          >
            Está funcionalidade não faz parte do seu plano.
          </Tooltip>

          <div className="buttonMessages">
            {qtdNewMessages > 0 && <div id="badgeMessage">0</div>}
            <Link to="/messages">
              <img className="notification" src={Messages} alt="Messages" />
            </Link>
            <div
              id={
                location.pathname === "/messages" ? "triangle" : "triangleHide"
              }
            ></div>
          </div>

          <button
            className="buttonPremium"
            id="tooltipDatabase"
            onClick={() => {
              if (
                userInformation.subscription !== "Idss" &&
                userInformation.subscription !== "IdssUltra"
              ) {
                history.push("/plans");
              } else {
                setTooltipOpen(true);
                setTimeout(() => {
                  setTooltipOpen(false);
                }, 5000);
              }
            }}
          >
            Upgrade
          </button>
        </div>
      </header>
    </>
  );
};

export default HeaderProjectsPage;
