import React, { useState, useEffect } from "react";
import "./style.scss";
import { ReactComponent as FiRrEye } from "../../Assets/img/regular/fi-rr-eye.svg";
import { ReactComponent as FiRrEyeCrossed } from "../../Assets/img/regular/fi-rr-eye-crossed.svg";
import Alerts from "../../Components/Alerts";
import AuthService from "../../Services/AuthService";
import Button from "../../Components/Button";
import Center2Icon from "../../Components/TopBar/Center2Icon";
import Input from "../../Components/Input";

const ChangePassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [toggleAlert, setToggleAlert] = useState({
    type: "",
    message: "",
    active: false,
  });
  const [valueCurrentPassword, setValueCurrentPassword] = useState("");
  const [valueNewPassord, setValueNewPassord] = useState("");
  const [valueConfirmNewPassord, setValueConfirmNewPassord] = useState("");

  const handleChangePassword = async () => {
    if (valueCurrentPassword === "" || valueNewPassord === "") {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "O campo de senha é obrigatório",
      });
      return false;
    }

    if (valueConfirmNewPassord === "") {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "A confirmação de senha é obrigatória",
      });
      return false;
    }

    if (valueNewPassord !== valueConfirmNewPassord) {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "As senhas estão diferentes",
      });
      return false;
    }

    let response = await AuthService.reAuth(
      valueCurrentPassword,
      valueNewPassord
    );
    if (response.type === "success") {
      setToggleAlert({
        active: true,
        type: "Success",
        message: "Senha alterada com sucesso!",
      });
      return true;
    } else if (
      response.type === "fail" &&
      response.message === "Não foi possível alterar a senha!"
    ) {
      setToggleAlert({
        active: true,
        type: "Error",
        message:
          "Não foi possível alterar sua senha, tente novamente mais tarde!",
      });
      return false;
    } else if (
      response.type === "fail" &&
      response.message === "Senha atual incorreta!"
    ) {
      setToggleAlert({
        active: true,
        type: "Error",
        message: "Senha incorreta",
      });
      return false;
    }
  };

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setToggleAlert({
        ...toggleAlert,
        active: false,
      });
    }, 4000);

    return () => clearTimeout(timer);
  }, [toggleAlert]);

  return (
    <>
      <header className="headerChangePassword">
        <Center2Icon titlePage="Senha" />
      </header>
      <main className="containerMainChangePassword">
        <div className="formMainChangePassword">
          {toggleAlert.active && (
            <div className="alertProfile">
              <Alerts
                type={toggleAlert.type}
                message={toggleAlert.message}
                open={toggleAlert.active}
              />
            </div>
          )}
          <div className="inputsFormChangePassword">
            <p className="textChangePassword">
              Antes de continuar, informe a sua senha atual.
            </p>
            <Input
              label="Senha atual"
              layout={
                valueCurrentPassword.length >= 6 || valueCurrentPassword === ""
                  ? "after"
                  : "error"
              }
              secondIcon={
                passwordVisible ? (
                  <FiRrEye
                    className="eyeicon"
                    data-testid="eye-visible-icon"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <FiRrEyeCrossed
                    className="eyeicon"
                    data-testid="eye-invisible-icon"
                    onClick={handleShowPassword}
                  />
                )
              }
              placeholder="No mínimo 6 caracteres"
              setValue={setValueCurrentPassword}
              type={passwordVisible ? "text" : "password"}
              value={valueCurrentPassword}
            />
            <p className="textChangePassword">
              Agora basta escolher a nova senha de acesso para a plataforma.
            </p>
            <Input
              label="Nova Senha"
              layout={
                valueNewPassord.length >= 6 || valueCurrentPassword === ""
                  ? "default"
                  : "error"
              }
              secondIcon={
                passwordVisible ? (
                  <FiRrEye
                    className="eyeicon"
                    data-testid="eye-visible-icon"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <FiRrEyeCrossed
                    className="eyeicon"
                    data-testid="eye-invisible-icon"
                    onClick={handleShowPassword}
                  />
                )
              }
              placeholder="No mínimo 6 caracteres"
              setValue={setValueNewPassord}
              type={passwordVisible ? "text" : "password"}
              value={valueNewPassord}
            />
            <Input
              label="Confirme a senha"
              layout={
                valueConfirmNewPassord.length >= 6 ||
                valueCurrentPassword === ""
                  ? "default"
                  : "error"
              }
              secondIcon={
                passwordVisible ? (
                  <FiRrEye
                    className="eyeicon"
                    data-testid="eye-visible-icon"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <FiRrEyeCrossed
                    className="eyeicon"
                    data-testid="eye-invisible-icon"
                    onClick={handleShowPassword}
                  />
                )
              }
              placeholder="No mínimo 6 caracteres"
              setValue={setValueConfirmNewPassord}
              type={passwordVisible ? "text" : "password"}
              value={valueConfirmNewPassord}
            />
          </div>
          <Button
            dataTestid="dataTestId-button-save"
            type="default"
            size="large"
            variant="primary"
            children="Salvar Alterações"
            textStyle={{
              fontFamily: "Montserrat-Regular",
              fontSize: "14px",
              fontStyle: "normal",
            }}
            buttonStyle={{ width: "100%" }}
            onClick={handleChangePassword}
          />
        </div>
      </main>
    </>
  );
};

export default ChangePassword;
