import React from "react";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import "./style.scss";

/**
 * @typedef {'default' | 'outline' | 'auxiliary' | 'text'} ButtonType
 * @typedef {'large' | 'medium' | 'small'} ButtonSize
 * @typedef {'primary' | 'cancel' | 'success' | 'disable'} ButtonVariant
 * @typedef {'left' | 'right'} ButtonIconPosition
 */

/**
 * @typedef {Object} ButtonProps
 * @property {ButtonType} type
 * @property {ButtonSize} size
 * @property {ButtonVariant} variant
 * @property {ButtonIconPosition} iconPosition
 */

/**
 * @param {ButtonProps}
 * @returns {JSX.Element}
 */

const Button = ({
  dataTestid,
  type,
  size,
  variant,
  icon,
  innerClassName,
  iconPosition,
  buttonStyle,
  textStyle,
  children,
  onClick,
}) => {
  const buttonClassName = `button ${type} ${variant} ${size} ${innerClassName}`;
  const buttonTextClassName = `buttonText ${size}`;
  const iconClassName = `icon ${size}`;

  return (
    <button
      data-testid={dataTestid}
      className={buttonClassName}
      style={buttonStyle && buttonStyle}
      onClick={onClick}
      disabled={variant === "disable" ? true : false}
    >
      {iconPosition === "left" && icon && (
        <div>
          <ReactSVG
            className={iconClassName}
            data-testid="my-button-icon"
            src={icon}
          />
        </div>
      )}
      <div>
        <span
          data-testid="my-button-text"
          className={buttonTextClassName}
          style={textStyle && textStyle}
        >
          {children}
        </span>
      </div>
      {iconPosition === "right" && icon && (
        <div>
          <ReactSVG
            data-testid="my-button-icon"
            className={iconClassName}
            src={icon}
          />
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["default", "outline", "auxiliary", "text"]).isRequired,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  variant: PropTypes.oneOf(["primary", "cancel", "success", "disable"])
    .isRequired,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  innerClassName: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: "default",
  size: "medium",
  variant: "primary",
  icon: null,
  iconPosition: null,
  innerClassName: "",
  children: null,
  onClick: () => {},
};

export default Button;
