import React, { useState, useEffect, useContext } from "react";
import copy from "copy-to-clipboard";
import { Context } from "../../../Context/Context";
import Center2Icon from "../../TopBar/Center2Icon";
import Alerts from "../../Alerts";
import CardMyLink from "../../CardMyLink";
import SwitchButton from "../../SwitchButton";
import ModalConfigAdvancedLinks from "../ModalConfigAdvancedLinks";
import ModalEmbed from "../ModalEmbed";
import ModalQRCode from "../ModalQRCode";
import ModalQuestions from "../ModalQuestions";

import "./style.scss";

import FiRrCopy from "../../../Assets/img/regular/fi-rr-copy.svg";
import FiRrEnvelope from "../../../Assets/img/regular/fi-rr-envelope.svg";
import Whatsapp from "../../../Assets/img/whatsapp-share.svg";
import Linkedin from "../../../Assets/img/linkedin-share.svg";
import Facebook from "../../../Assets/img/facebook-share.svg";
import Twitter from "../../../Assets/img/twitter-share.svg";
import QrCode from "../../../Assets/img/codigo-qr.svg";
import Embed from "../../../Assets/img/embed-share.svg";

import ProjectsService from "../../../Services/ProjectsService";

const ModalConfigLink = ({ isOpen, setIsOpen, projectId }) => {
  const { userInformation, setCurrentLink } = useContext(Context);
  const [link, setLink] = useState(projectId);
  const [multiplesAnswers, setMultiplesAnswers] = useState(null);
  const [projectLinkStock, setProjectLinkStock] = useState();
  const [searchStatus, setSearchStatus] = useState(null);
  const [shareProjectInFinal, setShareProjectInFinal] = useState(null);
  const [showModalAdvancedLinks, setShowModalAdvancedLinks] = useState(false);
  const [showModalEmbed, setShowModalEmbed] = useState(false);
  const [showModalQrCode, setShowModalQrCode] = useState(false);
  const [showModalQuestions, setShowModalQuestions] = useState(false);
  const [switchAdvancedLinks, setSwitchAdvancedLinks] = useState(false);
  const [switchDefaultLink, setSwitchDefaultLink] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [URLFormated, setURLFormated] = useState("");
  const optionsShare = [
    {
      icon: FiRrCopy,
      name: "Copiar o código",
      onClick: () => {
        copyToClipboard();
        setShowModalQuestions(false);
      },
    },
    {
      icon: FiRrEnvelope,
      name: "Enviar por e-mail",
      onClick: () => {
        window.open(
          `mailto:?subject=Feedback&body=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Whatsapp,
      name: "Enviar pelo Whatsapp (App)",
      onClick: () => {
        window.open(
          `whatsapp://send?text=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Whatsapp,
      name: "Enviar pelo Whatsapp (Web)",
      onClick: () => {
        window.open(
          `https://web.whatsapp.com/send?text=Olá%21%20Acho%20que%20você%20vai%20gostar%20desse%20chatbot%3A%20${URLFormated}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Linkedin,
      name: "Enviar pelo Linkedin",
      onClick: () => {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_BASE_URL}${link}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Facebook,
      name: "Enviar pelo Facebook (App)",
      onClick: () => {
        window.open(
          `fb://faceweb/f?href=https%3A%2F%2Fm.facebook.com%2Fsharer.php%3Fu%3D${URLFormated}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Facebook,
      name: "Enviar pelo Facebook (Web)",
      onClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_BASE_URL}${link}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Twitter,
      name: "Enviar pelo Twitter (App)",
      onClick: () => {
        window.open(
          `twitter://post?text=Olá%21%20Acho%20que%20você%20vai%20gostar%20desse%20chatbot%3A%20${URLFormated}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: Twitter,
      name: "Enviar pelo Twitter (Web)",
      onClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?text=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`,
          "_blank"
        );
        setShowModalQuestions(false);
      },
    },
    {
      icon: QrCode,
      name: "Enviar QR Code",
      onClick: () => {
        setShowModalQrCode(true);
        setShowModalQuestions(false);
      },
    },
    {
      icon: Embed,
      name: "Gerar código embed",
      onClick: () => {
        setShowModalEmbed(true);
        setShowModalQuestions(false);
      },
    },
  ];

  const handleSwitchDefaultLink = (value) => {
    setSwitchDefaultLink(value);
    setSearchStatus(value);

    ProjectsService.setClosingProject(
      projectId,
      userInformation.email,
      !searchStatus ? 0 : 1,
      userInformation.subscription
    );

    if (value && switchAdvancedLinks) {
      setSwitchAdvancedLinks(false);
    }
  };

  const handleSwitchAdvancedLinks = (value) => {
    setSwitchAdvancedLinks(value);

    if (value && switchDefaultLink) {
      setSwitchDefaultLink(false);
      setSearchStatus(false);
    }
  };

  const copyToClipboard = () => {
    copy(`${process.env.REACT_APP_BASE_URL}${link}`);
  };

  const handleMultipleAnswer = async (value) => {
    setMultiplesAnswers(value);
    await ProjectsService.setMultipleAnswer(
      projectId,
      value,
      userInformation.email
    );
  };

  const handleShareProjectInFinal = async (value) => {
    setShareProjectInFinal(value);
    await ProjectsService.setShareProjectInFinal(
      projectId,
      value ? 1 : 0,
      userInformation.email
    );
  };

  useEffect(() => {
    const URL = encodeURIComponent(process.env.REACT_APP_BASE_URL + link);
    setURLFormated(URL);
    setCurrentLink(link);
  }, [link]);

  useEffect(() => {
    const getProjectInformation = async () => {
      let validationProjectIdExists = [];

      if (userInformation.subscription !== "IdssManager") {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element === projectId ? element : null;
          }
        );
      } else {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element.idProject === projectId ? element : null;
          }
        );
      }

      if (
        validationProjectIdExists.length > 0 ||
        userInformation.subscription === "IdssManager"
      ) {
        const userProject = await ProjectsService.getSpecificProject(projectId);

        setMultiplesAnswers(userProject[0].multiple_answer);
        setShareProjectInFinal(userProject[0].share_final);

        if (userProject[0].link) {
          setLink(userProject[0].link);
          setProjectLinkStock(userProject[0].link);
        }

        let response = await ProjectsService.getStatusActiveFromProjects(
          [projectId],
          userInformation.email,
          userInformation.subscription
        );

        if (response !== null) {
          if (response.closed === 1) {
            setTypeAlert("Error");
            setTextAlert("inativo");
            setSearchStatus(false);
          } else {
            setTypeAlert("Success");
            setTextAlert("ativo");
            setSearchStatus(true);
          }
        }
      }
    };

    if (userInformation) {
      getProjectInformation();
    }
  }, [userInformation]);

  useEffect(() => {
    if (searchStatus || switchAdvancedLinks) {
      setTypeAlert("Success");
      setTextAlert("ativo");
    } else {
      setTypeAlert("Error");
      setTextAlert("inativo");
    }
  }, [searchStatus]);

  if (!isOpen) return null;

  return (
    <>
      <ModalConfigAdvancedLinks
        isOpen={showModalAdvancedLinks}
        setIsOpen={setShowModalAdvancedLinks}
      />
      <ModalQRCode
        isOpen={showModalQrCode}
        setIsOpen={setShowModalQrCode}
        url={`${link}`}
      />
      <ModalEmbed isOpen={showModalEmbed} setIsOpen={setShowModalEmbed} />
      <ModalQuestions
        isOpen={showModalQuestions}
        setIsOpen={setShowModalQuestions}
        title="Compartilhar link"
        options={optionsShare}
      />
      <div className="compModalConfigLink">
        <Center2Icon
          titlePage="Configurando link"
          onClickBack={() => setIsOpen(false)}
        />
        <main>
          {typeAlert && textAlert && (
            <Alerts
              type={typeAlert}
              message={`O projeto está ${textAlert} no momento`}
              open={true}
              removeClose={true}
            />
          )}
          <CardMyLink
            title="Link principal do projeto"
            link={link}
            setLink={setLink}
            linkStock={projectLinkStock}
            setLinkStock={setProjectLinkStock}
            switchActive={searchStatus}
            showLink={true}
            onClickButton={() => setShowModalQuestions(true)}
            onClickToogle={handleSwitchDefaultLink}
          />
          <CardMyLink
            title="Links avançados"
            description="Defina diferentes pontos de contato para a sua pesquisa e mapeie melhor os seus resultados."
            infoText="Links avançados criados: 0"
            buttonName="Configurar links"
            switchActive={switchAdvancedLinks}
            onClickToogle={handleSwitchAdvancedLinks}
            onClickButton={() => setShowModalAdvancedLinks(true)}
          />
          <div className="cardVariables">
            <span className="title">Variáveis personalizadas</span>
            <div className="toogle">
              <SwitchButton
                active={multiplesAnswers}
                onChange={handleMultipleAnswer}
              />
              <span>Permitir várias respostas</span>
            </div>
            <div className="toogle">
              <SwitchButton
                active={shareProjectInFinal}
                onChange={(value) => handleShareProjectInFinal(value)}
              />
              <span>Compartilhar via whatsapp na despedida</span>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ModalConfigLink;
