import React, { useEffect, useContext } from "react";
import { Context } from "../../../Context/Context";
import "./style.scss";
import Button from "../../Button";
import PropTypes from "prop-types";
import { ReactComponent as FiRrBell } from "../../../Assets/img/regular/fi-rr-bell.svg";
import { ReactComponent as FiSrMenuBurger } from "../../../Assets/img/solid/fi-sr-menu-burger.svg";
import { ReactComponent as FiSrArrowSmallDown } from "../../../Assets/img/solid/fi-sr-arrow-small-down.svg";
import { ReactComponent as FiRrSearch } from "../../../Assets/img/regular/fi-rr-search.svg";
import MessagesService from "../../../Services/MessagesService";

/*******************************************************************************
 * How to use:
 * <Bottoms
    selectedOptionInteractions="Interações"
    selectedOptionMostRecent="Mais recentes",
    onClickInteractions={() => {
      return null;
    }}
    onClickMostRecent={() => {
      return null;
    }}
    onClickMenuBurger={() => {
      return null;
    }}
    onClickNotifications={() => {
      return null;
    }}
  />
 ******************************************************************************/

function Bottoms(props) {
  const {
    selectedOptionInteractions,
    selectedOptionMostRecent,
    onClickInteractions,
    onClickMostRecent,
    onClickMenuBurger,
    onClickNotifications,
    onClickSearch,
    onClickUpgrade,
  } = props;
  const {
    userMessages,
    setUserMessages,
    getQtdNewMessages,
    qtdNewMessages,
  } = useContext(Context);

  useEffect(() => {
    const getUserMessages = async() => {
      let data = await MessagesService.getMessagesByUser();
      if (data.response === "success") {
        setUserMessages(data.messages);
        getQtdNewMessages(data.messages);
      } else {
        setUserMessages([]);
        getQtdNewMessages(userMessages);
      }
    };

    getUserMessages();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="compBottoms">
      <div className="defaultTop">
        <span className="letteringHazo">HAZO</span>
        <Button
          size="small"
          onClick={onClickUpgrade}
          type="default"
          variant="success"
        >
          Upgrade
        </Button>
        <div className="iconsArea">
          <div className="notificationArea">
            { qtdNewMessages > 0 &&
              <div className="numberNotification">
                <span>{qtdNewMessages}</span>
              </div>
            }
            <FiRrBell
              className="icon"
              data-testid="bell-icon"
              onClick={onClickNotifications}
            />
          </div>
          <FiSrMenuBurger
            className="icon"
            data-testid="menu-burger-icon"
            onClick={onClickMenuBurger}
          />
        </div>
      </div>
      <div className="bottomsArea">
        <div className="firstArea">
          <Button
            onClick={onClickInteractions}
            size="small"
            type="text"
            variant="primary"
          >
            {selectedOptionInteractions}{" "}
            <FiSrArrowSmallDown
              data-testid="arrow-down-icon-interaction"
              className="iconArrowDown"
            />
          </Button>
          <Button
            onClick={onClickMostRecent}
            size="small"
            type="text"
            variant="primary"
            buttonStyle={{
              maxWidth: 160,
            }}
          >
            {selectedOptionMostRecent}{" "}
            <FiSrArrowSmallDown
              data-testid="arrow-down-icon-recent"
              className="iconArrowDown"
            />
          </Button>
        </div>
        <FiRrSearch
          className="iconSearch"
          data-testid="search-icon"
          onClick={onClickSearch}
        />
      </div>
    </div>
  );
}

Bottoms.propTypes = {
  selectedOptionInteractions: PropTypes.string.isRequired,
  selectedOptionMostRecent: PropTypes.string.isRequired,
  onClickInteractions: PropTypes.func.isRequired,
  onClickMostRecent: PropTypes.func.isRequired,
  onClickMenuBurger: PropTypes.func.isRequired,
  onClickNotifications: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  onClickUpgrade: PropTypes.func.isRequired,
};

Bottoms.defaultProps = {
  onClickInteractions: () => {},
  onClickMostRecent: () => {},
  onClickMenuBurger: () => {},
  onClickNotifications: () => {},
  onClickSearch: () => {},
};

export default Bottoms;
