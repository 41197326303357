import React, { useState } from "react";
import "./style.scss";
import ButtonOnOff from "../ButtonOnOff";

import { ReactComponent as FiRrCaretUp } from "../../Assets/img/regular/fi-rr-caret-up.svg";
import { ReactComponent as FiSrCaretDown } from "../../Assets/img/solid/fi-sr-caret-down.svg";

export default function ChartTable(props) {
  const { qNumberString, question, reportData } = props;
  const [showTable, setShowTable] = useState(false);
  const [ordenation, setOrdenation] = useState("qtdAsc");

  return (
    <div className="compChartTable">
      <div className="buttonArea">
        <ButtonOnOff
          handleValue={() => setShowTable(!showTable)}
          on={!showTable}
        />{" "}
        <span>Ocultar tabela de respostas</span>
      </div>
      {showTable && (
        <table className="table" name={question.message}>
          <thead>
            <tr>
              <td>
                <div className="cell">
                  Opção
                  <div className="ordenation">
                    <FiRrCaretUp
                      className="ordenationIcon"
                      onClick={() => setOrdenation("nameAsc")}
                    />
                    <FiSrCaretDown
                      className="ordenationIcon"
                      onClick={() => setOrdenation("nameDesc")}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="cell">
                  Qtd
                  <div className="ordenation">
                    <FiRrCaretUp
                      className="ordenationIcon"
                      onClick={() => setOrdenation("qtdAsc")}
                    />
                    <FiSrCaretDown
                      className="ordenationIcon"
                      onClick={() => setOrdenation("qtdDesc")}
                    />
                  </div>
                </div>
              </td>
              <td>
                <div className="cell">
                  %
                  <div className="ordenation">
                    <FiRrCaretUp
                      className="ordenationIcon"
                      onClick={() => setOrdenation("qtdAsc")}
                    />
                    <FiSrCaretDown
                      className="ordenationIcon"
                      onClick={() => setOrdenation("qtdDesc")}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {reportData[qNumberString] !== undefined &&
              reportData[qNumberString]
                .sort((a, b) => {
                  if (ordenation === "nameAsc") {
                    if (a[qNumberString] < b[qNumberString]) {
                      return -1;
                    }
                    if (a[qNumberString] > b[qNumberString]) {
                      return 1;
                    }
                  }
                  if (ordenation === "nameDesc") {
                    if (a[qNumberString] > b[qNumberString]) {
                      return -1;
                    }
                    if (a[qNumberString] < b[qNumberString]) {
                      return 1;
                    }
                  }
                  if (ordenation === "qtdAsc") {
                    return a.qtd - b.qtd;
                  }
                  if (ordenation === "qtdDesc") {
                    return b.qtd - a.qtd;
                  }
                  return null;
                })
                .map((element, index) => {
                  if (element[qNumberString] !== "Total") {
                    return (
                      <tr key={index}>
                        <td>{element[qNumberString]}</td>
                        <td>{element.qtd}</td>
                        <td>{`${element.percent.toFixed(1)} %`}</td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
            {reportData[qNumberString] !== undefined ? (
              reportData[qNumberString].map((element) => {
                if (element[qNumberString] === "Total") {
                  return (
                    <tr>
                      <td>{element[qNumberString]}</td>
                      <td>{element.qtd}</td>
                      <td>{`${element.percent.toFixed(1)} %`}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <tr>
                <td>Total</td>
                <td>0</td>
                <td>{`0.0 %`}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
