import React from "react";
import "./style.scss";
import { ReactComponent as IconUpload } from "../../Assets/img/regular/fi-rr-upload.svg";
import { ReactComponent as IconFeature } from "../../Assets/img/regular/fi-rr-add.svg";
import { ReactComponent as IconBug } from "../../Assets/img/regular/fi-rr-bug.svg";
import Center2Icon from "../../Components/TopBar/Center2Icon";

const Updates = () => {
  const itemsUpdates = [
    {
      id: 1,
      version: "Version: 5.1.0",
      date: "08/11/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Integração com google sheets",
        },
        {
          type: "feature",
          text: "Novo: Tamanho para perguntas do tipo imagem",
        },
        {
          type: "bug",
          text: "Bug: Corrigido bugs de integração e customomização de chatbot",
        },
      ],
    },
    {
      id: 2,
      version: "Version: 5.1.0",
      date: "18/10/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Scroll automatico ao terminar de editar uma pergunta",
        },
        {
          type: "bug",
          text: "Bug: Corrigidos bugs",
        },
      ],
    },
    {
      id: 3,
      version: "Version: 5.1.0",
      date: "13/10/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Removido plano Founder",
        },
        {
          type: "bug",
          text: "Bug: Corrigidos bugs",
        },
      ],
    },
    {
      id: 4,
      version: "Version: 5.0.0",
      date: "10/10/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Nova lógica condicionais para questões de múltipla escolha",
        },
      ],
    },
    {
      id: 5,
      version: "Version: 4.0.6",
      date: "07/10/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Visualização do tipo tabela nos relatórios",
        },
        {
          type: "feature",
          text: "Novo: Nome do projeto aparecendo na tela de botmaker",
        },
      ],
    },
    {
      id: 6,
      version: "Version: 4.0.5",
      date: "16/09/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Cores autómaticas na perguta de escala numérica",
        },
        {
          type: "feature",
          text: "Novo: Ordenação por nomes na página de projetos",
        },
        {
          type: "feature",
          text: "Novo: Favicon com fundo translucido",
        },
        {
          type: "feature",
          text: "Novo: Estilização para telas com resoluções grandes",
        },
        {
          type: "feature",
          text: "Novo: Limitação de visualização dos relatórios de acordo com o tipo",
        },
        {
          type: "bug",
          text: "Bug: Corrigido download da base de dados retornando vazia",
        },
        {
          type: "bug",
          text: "Bug: Corrigido problema em condicionais atribuindo valores errados em pergunta do tipo like/dislike",
        },
      ],
    },
    {
      id: 7,
      version: "Version: 4.0.4",
      date: "05/09/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Corrigido problema ao abrir chatbot de teste na tela de projetos",
        },
      ],
    },
    {
      id: 8,
      version: "Version: 4.0.3",
      date: "01/09/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Adicionado bloqueio de customizações para contas Free",
        },
      ],
    },
    {
      id: 9,
      version: "Version: 4.0.2",
      date: "25/08/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Adicioniado data de criação do projeto a tela inicial",
        },
        {
          type: "feature",
          text: "Novo: Validação do questionário ao navegar para o compartilhamento",
        },
        {
          type: "bug",
          text: "Bug: Troca de númeração das perguntas ao deletar",
        },
        {
          type: "bug",
          text: "Bug: Modificação em várias multiplas perguntas quando a mesma é",
        },
        {
          type: "bug",
          text: "Bug: Corrigido compartilhamento via whatsapp que estava enviando",
        },
        {
          type: "bug",
          text: "Bug: Corrigido renderização de logo em formatos não válidos",
        },
        {
          type: "bug",
          text: "Bug: Corrigido exportação de PDF em celular",
        },
      ],
    },
    {
      id: 10,
      version: "Version: 4.0.1",
      date: "20/07/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Disponibilizado assinatura de planos via paypal",
        },
      ],
    },
    {
      id: 11,
      version: "Version: 3.0.4",
      date: "18/07/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Remoção de quebra de linha nas opções de resposta",
        },
        {
          type: "bug",
          text: "Fix: Ajustado estilo do tooltip",
        },
        {
          type: "bug",
          text: "Bug: Botão que corta a imagem não aparecia na versão mobile",
        },
      ],
    },
    {
      id: 12,
      version: "Version: 3.0.3",
      date: "13/07/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Adicionado rolagem automatica para perguntar que estão com erro na edição",
        },
      ],
    },
    {
      id: 13,
      version: "Version: 3.0.2",
      date: "11/07/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Novo botão para adicionar pergunta durante a edição de uma interação.",
        },
      ],
    },
    {
      id: 14,
      version: "Version: 3.0.1",
      date: "08/07/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Nova lógica para montagem do chatbot onde não há problema em movimentar e deletar perguntas, e as condicionais se mantém fixadas nas perguntas.",
        },
        {
          type: "feature",
          text: "Novo: Novo nome para alguns gráficos",
        },
      ],
    },
    {
      id: 15,
      version: "Version: 2.0.10",
      date: "29/06/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Corrigido erro ao abrir questionario e retornar página em branco",
        },
        {
          type: "feature",
          text: "Novo: Resize do box de opção de resposta",
        },
      ],
    },
    {
      id: 16,
      version: "Version: 2.0.9",
      date: "23/06/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Corrigido erro ao adicionar opções na pergunta do tipo menu interativo",
        },
      ],
    },
    {
      id: 17,
      version: "Version: 2.0.8",
      date: "22/06/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Corrigido mudança de cores dos botões na escala emoji e estrela",
        },
        {
          type: "bug",
          text: "Bug: Corrigido atualização da URL na página de compartilhamento",
        },
      ],
    },
    {
      id: 18,
      version: "Version: 2.0.7",
      date: "21/06/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: pode-se adicionar opções de respostas em perguntas estruturadas",
        },
      ],
    },
    {
      id: 19,
      version: "Version: 2.0.6",
      date: "15/06/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Corrigido a largura do pdf gerado no botmaker.",
        },
        {
          type: "bug",
          text: "Bug: Corrigido problema de loop ao deletar perguntas do mesmo tipo.",
        },
        {
          type: "feature",
          text: "Novo: Log de atualizações",
        },
      ],
    },
    {
      id: 20,
      version: "Version: 2.0.5",
      date: "14/06/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Movimentação de opções de resposta no botmaker.",
        },
        {
          type: "feature",
          text: "Novo: Aumentado o tamanho da logo no chatbot.",
        },
        {
          type: "feature",
          text: "Novo: Exportação via PDF do botmaker.",
        },
      ],
    },
    {
      id: 21,
      version: "Version: 2.0.4",
      date: "13/06/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Adicionado tela de loading em páginas que aguardam dados.",
        },
        {
          type: "bug",
          text: "Bug: Erro de condicionais em perguntas do tipo like/dislike.",
        },
      ],
    },
    {
      id: 22,
      version: "Version: 2.0.3",
      date: "10/06/2022",
      arrayContents: [
        {
          type: "bug",
          text: "Bug: Ajustado problema de não carregamento dos gráficos após download da base de dados.",
        },
        {
          type: "feature",
          text: "Novo: Agrupamento de respostas em perguntas do tipo de múltipla escolha.",
        },
      ],
    },
    {
      id: 23,
      version: "Version: 2.0.2",
      date: "09/06/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Novo: Restruturação do banco de dados, aumentando a segurança e a performance da aplicação",
        },
        {
          type: "feature",
          text: "Novo: Desbloqueio do limite de perguntas.",
        },
        {
          type: "feature",
          text: "Novo: Aumentado o limite de caracteres em perguntas abertas de 600 para 2000.",
        },
      ],
    },
    {
      id: 24,
      version: "Version: 2.0.1",
      date: "07/06/2022",
      arrayContents: [
        {
          type: "feature",
          text: "Otimizado o core da aplicação",
        },
        {
          type: "feature",
          text: "Novo: Implementado novas medidas de segurança na aplicação",
        },
      ],
    },
  ];

  return (
    <>
      <header className="headerUpdates">
        <Center2Icon titlePage="Updates" />
      </header>
      {itemsUpdates.map((itemUpdate, index) => (
        <section className="containerUpdates" key={index}>
          <div className="versionUpdate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <circle cx="18" cy="18" r="18" fill="#F5F5F5" />
            </svg>
            <IconUpload className="iconUpload" alt="Icon of Upload" />
            <p className="textUpdate">{itemUpdate.version}</p>
            <aside className="dateUpdate">{itemUpdate.date}</aside>
          </div>
          {itemUpdate.arrayContents.map((content, index) =>
            content.type === "feature" ? (
              <div className="contentUpdate" key={index}>
                <IconFeature className="iconFeature" alt="Icon of feature" />
                <p className="textContent">{content.text}</p>
              </div>
            ) : (
              <div className="contentUpdate">
                <IconBug className="iconBug" alt="Icon of bug" />
                <p className="textContent">{content.text}</p>
              </div>
            )
          )}
          <hr />
        </section>
      ))}
    </>
  );
};

export default Updates;
