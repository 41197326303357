import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { ReactComponent as FileAdd } from "../../Assets/img/regular/fi-rr-file-add.svg";

const CardNewProject = (props) => {
  const { onClick } = props;

  return (
    <div
      className="compCardNewProject"
      data-testid="compCardNewProject"
      onClick={onClick}
    >
      <div>
        <FileAdd className="icon" data-testid="add-icon" />
      </div>
      <div>
        <span className="title">Criar projeto em branco</span>
      </div>
    </div>
  );
};

CardNewProject.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CardNewProject;
