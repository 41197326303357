import React, { useContext, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { ReactComponent as HazoAvatarPlanSigned } from "../../Assets/img/animations/hazo-avatar-stylish.svg";
import { ReactComponent as IconFiSrCommentCheck } from "../../Assets/img/solid/fi-sr-comment-check.svg";
import { useHistory } from "react-router-dom";
import Alerts from "../../Components/Alerts";
import CardSigning from "../../Components/CardSigning/";
import CardBenefits from "../../Components/CardBenefits/";
import Center2Icon from "../../Components/TopBar/Center2Icon";
import hazoAvatarPlanFree from "../../Assets/img/animations/hazo-avatar.png";
import Loading from "../../Components/Loading";
import ModalDefault from "../../Components/Modals/ModalDefault";
import ModalUpgrade from "../../Components/Modals/ModalUpgrade/index";
import PaypalService from "../../Services/PaypalService";

const Plans = () => {
  const history = useHistory();
  const { updateUserInformation, userInformation, products } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [isOpenModalToCancelPlan, setIsOpenModalToCancelPlan] = useState(false);
  const [isOpenModalUpgrade, setIsOpenModalUpgrade] = useState(false);
  const [toggleAlert, setToggleAlert] = useState({
    type: "",
    message: "",
    active: false,
  });

  const handleCancelPlan = async () => {
    setLoading(true);
    let response = await PaypalService.cancelSubscription(
      userInformation.idSubscriptionPaypal,
      "Motivo generico"
    );
    await updateUserInformation();
    setLoading(false);
    if (response === "") {
      history.push("/projects");
    } else {
      setIsOpenModalToCancelPlan(false);
      setToggleAlert({
        active: true,
        type: "Error",
        message:
          "Não foi possível cancelar seu plano, entre em contato com o suporte para mais informações",
      });
      return false;
    }
  };

  const getValuePlan = () => {
    if (userInformation.subscription === "Enterprise") {
      return;
    } else {
      let productPrice = products.find(
        (product) => product.account === userInformation.subscription
      );
      // Check for show the correct price, year or month?
      return productPrice.price.year;
    }
  };

  if (userInformation) {
    return (
      (loading && <Loading />) || (
        <>
          <ModalUpgrade
            isOpen={isOpenModalUpgrade}
            setIsOpen={setIsOpenModalUpgrade}
          />
          <header className="headerPlans">
            <Center2Icon titlePage="Plano" />
          </header>
          <main className="containerMainPlans">
            <div className="containerTextPlans">
              {toggleAlert.active && (
                <div className="alertProfile">
                  <Alerts
                    type={toggleAlert.type}
                    message={toggleAlert.message}
                    open={toggleAlert.active}
                  />
                </div>
              )}
              <div className="statusPlans">
                <IconFiSrCommentCheck
                  className="iconStatusPlans"
                  alt="Icon Comment Check"
                />
                <h5 className="h5ContainerPlans">
                  Plano{" "}
                  {userInformation.subscription === "Free"
                    ? "grátis"
                    : "Assinado"}
                </h5>
                {userInformation.subscription === "Free" ? (
                  <img
                    alt="Avatar Hazo"
                    className="imgHazoPlanFree"
                    src={hazoAvatarPlanFree}
                  />
                ) : (
                  <HazoAvatarPlanSigned
                    alt="Avatar Hazo"
                    className="imgHazoPlanSigned"
                  />
                )}
              </div>
              <p className="pContainerPlans">
                {userInformation.subscription === "Free"
                  ? "Começou bem! Aumente os seus resultados com uma assinatura."
                  : "Parabéns! Você possui assinatura ativa no momento."}
              </p>
            </div>
            {userInformation.subscription === "Free" ? (
              <CardSigning
                typePlan={userInformation.subscription}
                value={getValuePlan()}
                renovationDate={new Date(
                  userInformation.subscription_until.seconds * 1000
                ).toLocaleDateString("pt-BR")}
                onClick={() => setIsOpenModalUpgrade(true)}
              />
            ) : (
              <>
                <CardSigning
                  typePlan={userInformation.subscription}
                  value={getValuePlan()}
                  renovationDate={new Date(
                    userInformation.subscription_until.seconds * 1000
                  ).toLocaleDateString("pt-BR")}
                  onClick={() => setIsOpenModalToCancelPlan(true)}
                />
                <CardBenefits
                  typePlan={userInformation.subscription}
                  setIsOpenModalUpgrade={setIsOpenModalUpgrade}
                />
              </>
            )}
          </main>
          <ModalDefault
            title="Cancelar Plano?"
            description="Você perderá os benefícios da assinatura. Tem certeza que deseja prosseguir?"
            isOpen={isOpenModalToCancelPlan}
            modalColor="cancel"
            buttons={[
              {
                name: "Voltar",
                color: "primary",
                type: "auxiliary",
                onClick: () => setIsOpenModalToCancelPlan(false),
              },
              {
                name: "Cancelar Plano",
                color: "cancel",
                type: "default",
                onClick: () => handleCancelPlan(),
              },
            ]}
          />
        </>
      )
    );
  } else {
    return null;
  }
};

export default Plans;
