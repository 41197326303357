import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const ProgressBar = (props) => {
  const { value, max } = props;
  const percentage = (value / max) * 100;

  return (
    <div className="compProgressBar" data-testid="progress-bar">
      <div
        className="progressBarFill"
        data-testid="progress-bar-fill"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  value: 0,
  max: 100,
};

export default ProgressBar;
