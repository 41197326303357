import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import ScheduleService from "../../Services/ScheduleService";
import ProjectsService from "../../Services/ProjectsService";

const Database = ({ dataFirebase }) => {
  const { handleTotalInteractions, userInformation, setSheetLink } =
    useContext(Context);
  let { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [newArr, setNewArr] = useState(null);
  const [allControllers, setAllControlers] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const getDataToTable = async () => {
      await getDatabaseToTable();
    };

    if (userInformation !== null) {
      getDataToTable();
    }
    //eslint-disable-next-line
  }, [userInformation]);

  useEffect(() => {
    const mountTable = async () => {
      let newArray = await handleMountTable();

      setNewArr(newArray);
      await getIntegrationIsOn();
      setLoading(false);
    };

    if (userInformation !== null && allControllers !== null) {
      mountTable();
    }
    //eslint-disable-next-line
  }, [data, allControllers]);

  const changeKeysToEmojis = (element) => {
    switch (element) {
      case "emoji-5":
        return "😀 (5)";
      case "emoji-4":
        return "🙂 (4)";
      case "emoji-3":
        return "😐 (3)";
      case "emoji-2":
        return "🙁 (2)";
      case "emoji-1":
        return "😠 (1)";
      case "star-5":
        return "⭐⭐⭐⭐⭐ (5)";
      case "star-4":
        return "⭐⭐⭐⭐ (4)";
      case "star-3":
        return "⭐⭐⭐ (3)";
      case "star-2":
        return "⭐⭐ (2)";
      case "star-1":
        return "⭐ (1)";
      default:
        return element;
    }
  };

  const handleControllers = async (dataToXls) => {
    let controllers = {};
    for (let i = 1; i < dataToXls.length; i++) {
      controllers[dataToXls[i]["controlador"]] = false;
    }
    setAllControlers(controllers);
  };

  const getDatabaseToTable = async () => {
    let qtdAnswers = handleTotalInteractions();
    let dataToXls = await ProjectsService.getDatabaseToXls(
      JSON.stringify({
        project: projectId,
        numberQuestions: dataFirebase.questions.length,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
        user: userInformation.email,
        idssManager:
          userInformation.subscription === "IdssManager" ? true : false,
      })
    );

    setData(dataToXls);
    await handleControllers(dataToXls);
  };

  const handleMountTable = async () => {
    /***************************************************************************
     * Header
     **************************************************************************/
    let rowHeader = [];
    let counterQuestion = 0;
    for (const key in data[0]) {
      if (data[0][key].substring(0, 2) === "q_") {
        let removeQuebra = dataFirebase.questions[
          counterQuestion
        ].message.replace("__QUEBRA__", " ");
        rowHeader.push(
          <th key={key}>
            {data[0][key]} - {removeQuebra}
          </th>
        );
        counterQuestion++;
      } else {
        rowHeader.push(<th key={key}>{data[0][key]}</th>);
      }
    }

    /***************************************************************************
     * Body
     **************************************************************************/
    let dataBody = [];
    for (let i = 1; i < data.length; i++) {
      let row = [
        <td className="firstTd">
          <input
            type="checkbox"
            checked={allControllers[data[i]["controlador"]]}
            onChange={(event) => handleSelectRow(event, data[i]["controlador"])}
          />
        </td>,
      ];

      data[0].forEach((value, index) => {
        if (value !== "controlador" && value !== "abertura_data") {
          value = getQuestionNumber(index - 2);
        }

        if (data[i][value] !== undefined) {
          row.push(
            <td key={`${value}-${index}`}>
              {changeKeysToEmojis(data[i][value])}
            </td>
          );
        } else {
          row.push(<td key={`${value}-${index}`}></td>);
        }
      });
      dataBody.push(<tr key={data[i]["controlador"]}>{row}</tr>);
    }

    return [
      <thead key="titleDatabase">
        <tr>
          <td className="firstTd">
            <input
              type="checkbox"
              onChange={(event) => handleSelectAllRows(event)}
            />
          </td>
          {rowHeader}
        </tr>
      </thead>,
      <tbody key="bodyDatabase">{dataBody}</tbody>,
    ];
  };

  const getIntegrationIsOn = async () => {
    let response = await ScheduleService.getSchedule({
      user: userInformation.email,
      campaign: projectId,
    });
    if (
      response.data !== "Integration not exists" &&
      response.data !== "Error happened"
    ) {
      setSheetLink(true);
    }
  };

  const getQuestionNumber = (index) => {
    return dataFirebase.questions[index].number < 10
      ? `q_0${dataFirebase.questions[index].number}`
      : `q_${dataFirebase.questions[index].number}`;
  };

  const handleSelectRow = (event, controlador) => {
    if (event.target.checked) {
      setAllControlers((prev) => {
        return { ...prev, [controlador]: true };
      });
    } else {
      setAllControlers((prev) => {
        return { ...prev, [controlador]: false };
      });
    }
  };

  const handleSelectAllRows = (event) => {
    if (event.target.checked) {
      let allControllersTemp = { ...allControllers };
      for (const key in allControllers) {
        allControllersTemp[key] = true;
      }
      setAllControlers(allControllersTemp);
    } else {
      let allControllersTemp = { ...allControllers };
      for (const key in allControllers) {
        allControllersTemp[key] = false;
      }
      setAllControlers(allControllersTemp);
    }
  };

  if (loading === false && allControllers !== null) {
    return (
      <div className="compDatabase">
        <div className="containerData">
          <div className={newArr === null ? `content-without-plan` : `content`}>
            {newArr === null ? (
              <p className="upgrade-plan" style={{ textAlign: "center" }}>
                Está funcionalidade não faz parte do seu plano. Faça upgrade
                para utilizar
              </p>
            ) : (
              <div className="table-area">
                <table>{newArr}</table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Database;
