import React, { useContext, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { ReactComponent as IconArrow } from "../../Assets/img/solid/fi-sr-arrow-small-right.svg";
import { ReactComponent as IconCart } from "../../Assets/img/regular/fi-rr-shopping-cart.svg";
import { ReactComponent as IconDocumentation } from "../../Assets/img/regular/fi-rr-document.svg";
import { ReactComponent as IconFiSrlaugh } from "../../Assets/img/solid/fi-sr-laugh.svg";
import { ReactComponent as IconPassword } from "../../Assets/img/regular/fi-rr-lock.svg";
import { ReactComponent as IconSingOut } from "../../Assets/img/regular/fi-rr-sign-out.svg";
import { ReactComponent as IconUpdate } from "../../Assets/img/regular/fi-rr-soup.svg";
import { ReactComponent as IconUser } from "../../Assets/img/regular/fi-rr-user.svg";
import { useHistory } from "react-router-dom";
import Alert from "../../Components/Alerts";
import AuthService from "../../Services/AuthService";
import Center2Icon from "../../Components/TopBar/Center2Icon";
import hazoAvatar from "../../Assets/img/animations/hazo-avatar.png";
import InteractiveList from "../../Components/InteractiveList";
import Loading from "../../Components/Loading";
import ModalDefault from "../../Components/Modals/ModalDefault";

const Account = () => {
  const history = useHistory();
  const { resetAllInformations, userInformation } = useContext(Context);
  const [isOpenModalDefault, setIsOpenModalDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleAlert, setToggleAlert] = useState({
    type: "",
    message: "",
    active: false,
  });
  const itemsInteractiveList = [
    {
      title: "Dados pessoais",
      description: "Nome, e-mail e telefone",
      firstIcon: <IconUser />,
      firstIconColor: "Blue",
      secondIcon: <IconArrow />,
      secondIconColor: "Blue",
      onClick: () => {
        history.push("profile");
      },
    },
    {
      title: "Senha",
      description: "Gerencie a sua senha quando quiser",
      firstIcon: <IconPassword />,
      firstIconColor: "Blue",
      secondIcon: <IconArrow />,
      secondIconColor: "Blue",
      onClick: () => {
        history.push("changepassword");
      },
    },
    {
      title: "Plano",
      description: "Assine, troque ou cancele plano",
      firstIcon: <IconCart />,
      firstIconColor: "Blue",
      secondIcon: <IconArrow />,
      secondIconColor: "Blue",
      onClick: () => {
        history.push("plans");
      },
    },
    {
      title: "Updates",
      description: "Verifique as atualizações do sistema",
      firstIcon: <IconUpdate />,
      firstIconColor: "Blue",
      secondIcon: <IconArrow />,
      secondIconColor: "Blue",
      onClick: () => {
        history.push("updates");
      },
    },
    {
      title: "Termos de uso",
      description: "Fique por dentro de nossa política",
      firstIcon: <IconDocumentation />,
      firstIconColor: "Blue",
      secondIcon: <IconArrow />,
      secondIconColor: "Blue",
      onClick: () => {
        history.push("privacypolicy");
      },
    },
    {
      title: "Sair",
      description: "Faça logout aqui",
      firstIcon: <IconSingOut />,
      firstIconColor: "Red",
      secondIcon: <IconArrow />,
      secondIconColor: "Red",
      onClick: () => {
        setIsOpenModalDefault(true);
      },
    },
  ];

  const logout = async () => {
    setLoading(true);
    await resetAllInformations().then(async () => {
      await AuthService.signOut()
        .then(() => {
          setLoading(false);
          history.push("/");
        })
        .catch((error) => {
          setToggleAlert({
            active: true,
            type: "Error",
            message: error,
          });
        });
    });
  };

  if (userInformation) {
    return (
      (loading && <Loading />) || (
        <>
          <header className="headerAccount">
            <Center2Icon titlePage="Perfil" />
          </header>
          <main className="containerMainAccount">
            {toggleAlert.active && (
              <div className="alertMainAccount">
                <Alert
                  type={toggleAlert.type}
                  message={toggleAlert.message}
                  open={toggleAlert.active}
                />
              </div>
            )}
            <div className="containerTextAccount">
              <div className="statusAccount">
                <IconFiSrlaugh
                  className="iconStatusAccount"
                  alt="Icon Helper Success"
                />
                <h5 className="h5ContainerAccount">
                  Olá, {userInformation.name.split(" ")[0]}!
                </h5>
                <img
                  src={hazoAvatar}
                  alt="Avatar Hazo"
                  className="imgContainerAccount"
                />
              </div>
              <p className="pContainerAccount">
                Trouxe algumas opções para ajudar no gerenciamento da sua conta.
              </p>
            </div>
            <div className="containerAboutAccount">
              <div className="avatarAccount">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="84"
                  height="84"
                  viewBox="0 0 84 84"
                  fill="none"
                >
                  <circle cx="42" cy="42" r="42" fill="#F0F4FF" />
                </svg>
                <h5 className="h5ContainerAboutAccount">
                  {userInformation.name.charAt(0)}
                </h5>
              </div>
              <p className="pContainerAboutAccount">{userInformation.name}</p>
              <span className="spanContainerAboutAccount">
                {userInformation.email}
              </span>
            </div>
            <div className="containerOptionsAccount">
              {itemsInteractiveList.map((itemInteractiveList) => (
                <div key={itemInteractiveList.title}>
                  <InteractiveList
                    title={itemInteractiveList.title}
                    description={itemInteractiveList.description}
                    firstIcon={itemInteractiveList.firstIcon}
                    firstIconColor={itemInteractiveList.firstIconColor}
                    secondIcon={itemInteractiveList.secondIcon}
                    secondIconColor={itemInteractiveList.secondIconColor}
                    onClick={itemInteractiveList.onClick}
                  />{" "}
                  <hr />
                </div>
              ))}
            </div>
          </main>
          <ModalDefault
            title="Encerrar sessão?"
            description="Sua conta será deslogada! Tem certeza que deseja prosseguir?"
            isOpen={isOpenModalDefault}
            modalColor="primary"
            buttons={[
              {
                name: "Sair",
                color: "cancel",
                type: "outline",
                onClick: async () => {
                  await logout();
                },
              },
              {
                name: "Voltar ao Hazo",
                color: "primary",
                type: "default",
                onClick: () => setIsOpenModalDefault(false),
              },
            ]}
          />
        </>
      )
    );
  } else {
    return null;
  }
};

export default Account;
