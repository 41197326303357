import React from "react";
import OptionConfiguration from "../OptionConfiguration";
import Button from "../../Button";
import "./style.scss";

const ClosedAnswer = (props) => {
  const {
    handleClickInput,
    handleAddOption,
    handleColor,
    handleOptionValue,
    handleRemoveOption,
    moveDownOption,
    moveUpOption,
    questionNumber,
    questionValues,
  } = props;

  return (
    <div className="compClosedAnswer">
      {questionValues.questions[questionNumber].options.map((option, index) => {
        return (
          <div key={index} className="option">
            <OptionConfiguration
              option={option}
              index={index}
              questionNumber={questionNumber}
              handleColor={handleColor}
              handleOptionValue={handleOptionValue}
              handleClickInput={handleClickInput}
              handleRemoveOption={handleRemoveOption}
              moveDownOption={moveDownOption}
              moveUpOption={moveUpOption}
            />
          </div>
        );
      })}
      <Button
        onClick={() => handleAddOption(questionNumber)}
        size="small"
        type="auxiliary"
        variant="primary"
      >
        Adicionar Opção
      </Button>
    </div>
  );
};

export default ClosedAnswer;
