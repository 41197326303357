import React, { useEffect, useState } from "react";
import "./style.scss";
import ReportService from "../../Services/ReportService";
import ButtonOnOff from "../ButtonOnOff";
import Button from "../Button";
import Input from "../Input";

import { ReactComponent as FiSrComments } from "../../Assets/img/solid/fi-sr-comments.svg";
import { ReactComponent as FiSrPaperPlane } from "../../Assets/img/solid/fi-sr-paper-plane.svg";
import { ReactComponent as FiRrEdit } from "../../Assets/img/regular/fi-rr-edit.svg";
import { ReactComponent as FiRrTrash } from "../../Assets/img/regular/fi-rr-trash.svg";

const ChartComment = ({ chartNumber, dataQuestions, reportChartsRef }) => {
  const [textArea, setTextArea] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [commentPosition, setCommentPosition] = useState(-1);
  const [close, setClose] = useState(true);

  const handleChange = (e) => {
    setTextArea(e);
  };

  useEffect(() => {
    if (dataQuestions?.length > 0) {
      const comments = dataQuestions.find((c) => c.number === chartNumber);
      if (comments) {
        setAllComments(comments.comment);
      }
    }
  }, [dataQuestions, chartNumber]);

  const handleSave = () => {
    if (textArea === "") {
      return;
    }

    let comments = [...allComments];
    if (commentPosition === -1) {
      comments = [
        ...allComments,
        { comment: textArea, created_at: Date.now() },
      ];
    } else {
      comments.filter((element, i) =>
        i === commentPosition
          ? (comments[commentPosition].comment = textArea)
          : element
      );
      setCommentPosition(-1);
    }

    ReportService.updateComment(reportChartsRef, {
      number: chartNumber,
      comment: comments,
    });
    setTextArea("");
    setAllComments(comments);
  };

  const handleEdit = (e) => {
    const commentPosition = allComments.findIndex((el) => el === e);

    if (commentPosition === -1) {
      setCommentPosition(-1);
    } else {
      setCommentPosition(commentPosition);
    }
    setTextArea(e.comment);
  };

  const handleDelete = (e) => {
    const comments = allComments.filter((el) => el !== e);
    const dataComment = { number: chartNumber, comment: comments };
    ReportService.updateComment(reportChartsRef, dataComment);

    setAllComments(comments);
  };

  return (
    <div className="compChartComment">
      <div className="buttonArea">
        <ButtonOnOff handleValue={() => setClose(!close)} on={close} />{" "}
        <span>Ocultar observações</span>
      </div>
      {!close && (
        <div className="commentContainer">
          {allComments.length === 0 ? (
            <div className="notComment">
              <FiSrComments className="iconComment" />
              <p>
                Não há observações criados <br />
                até o momento.
              </p>
            </div>
          ) : (
            <section className="commentArea">
              <ul className="ulComment">
                {allComments?.map((element, i) => {
                  const dateObj = new Date(element.created_at);
                  const day = dateObj.getUTCDate();
                  const month = dateObj.getUTCMonth() + 1;
                  const year = dateObj.getUTCFullYear();
                  return (
                    <li key={i} className="li-comment">
                      <span className="date">{`${day}/${month}/${year
                        .toString()
                        .substring(2, 4)}`}</span>
                      <span className="text">{element.comment}</span>
                      <div className="btnsComment">
                        <FiRrEdit
                          className="iconComent"
                          onClick={() => handleEdit(element)}
                        />
                        <FiRrTrash
                          className="iconComent"
                          onClick={() => handleDelete(element)}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
          <div className="inputComment">
            <Input
              layout="default"
              placeholder="Digite a sua observação."
              setValue={handleChange}
              type="text"
              value={textArea}
            />
            <Button
              innerClassName="buttonPresetDefault"
              onClick={() => handleSave()}
              size="medium"
              type="default"
              variant={textArea === "" ? "disable" : "primary"}
            >
              <FiSrPaperPlane className="iconSendComment" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartComment;
