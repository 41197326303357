import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const Box = (props) => {
  const { handleSelect, open, options, selected, style } = props;

  if (open) {
    return (
      <div className="compBox" style={style && style} data-testid="comp-box">
        {options.map((option, index) => {
          return (
            <div
              className={
                selected?.indexOf(option) >= 0 ? "optionSelected" : "option"
              }
              key={`${option}_${index}`}
              onClick={() => handleSelect(option)}
            >
              <p className={
                option === "Excluir"
                ? "optionDelete"
                : null
              }
              >{option}</p>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

Box.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.array.isRequired,
  style: PropTypes.object,
};

export default Box;
