import React from "react";
import "./style.scss";
import "../style.scss";

import Button from "../../Button";
import QRCode from "react-qr-code";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";

import { ReactComponent as FiRrInfo } from "../../../Assets/img/regular/fi-rr-info.svg";

/**
 * @typedef { true | false } isOpen
 */

/**
 * @typedef {Object} ModalQRCodeProps
 * @property {isOpen} isOpen
 */

/**
 * @param {ModalQRCodeProps}
 * @returns {JSX.Element}
 */

/*******************************************************************************
 * How to use:
 * <ModalQRCode isOpen={true} />
 ******************************************************************************/

const ModalQRCode = (props) => {
  const { isOpen, setIsOpen, url } = props;

  const handleDownloadImage = async () => {
    const element = document.getElementById("printQr");
    let canvas = await html2canvas(element);
    let data = canvas.toDataURL("image/jpg");
    let link = document.createElement("a");

    link.href = data;
    link.download = "qr-code.jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (isOpen) {
    return (
      <div className="wrapper">
        <div className="compModalQRCode">
          <h2>Ver QR Code</h2>
          <p>
            QR Code, clique no botão abaixo e salve a imagem diretamente no seu
            computador.
          </p>

          <div className="qrArea">
            <div
              data-testid="div-print-qr"
              id="printQr"
              title={`${process.env.REACT_APP_BASE_URL}${url}`}
            >
              <QRCode
                data-testid="printQr"
                value={`${process.env.REACT_APP_BASE_URL}${url}`}
              />
            </div>
          </div>

          <div className="infoArea">
            <div>
              <FiRrInfo className="infoIcon" data-testid="info-icon" />
            </div>
            <p>
              Fique atento(a), o navegador pode solicitar a sua permissão para
              fazer o baixar a imagem.
            </p>
          </div>

          <div className="buttonArea">
            <Button
              type="auxiliary"
              size="medium"
              variant="primary"
              onClick={toggle}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              size="medium"
              variant="primary"
              onClick={handleDownloadImage}
            >
              Fazer download
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ModalQRCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

ModalQRCode.defaultProps = {
  isOpen: false,
  url: "",
};
export default ModalQRCode;
