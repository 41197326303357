import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardConfigLink from "../../Components/CardConfigLink";
import Center2Icon from "../../Components/TopBar/Center2Icon";
import Hazo from "../../Assets/img/hazo.svg";
import ModalConfigLink from "../../Components/Modals/ModalConfigLink";
import NavigationMenu from "../../Components/NavigationMenu";
import { Context } from "../../Context/Context";
import moment from "moment";
import "moment/locale/pt-br";
import "./style.scss";

const Share = () => {
  const { allUserProjects, currentLink, setCurrentLink } = useContext(Context);
  const [createdDate, setCreatedDate] = useState("");
  const [nameProject, setNameProject] = useState("");
  const [showModalConfig, setShowModalConfig] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    if (allUserProjects.length) {
      const project = allUserProjects.filter(
        (project) => project.idProject === projectId
      );
      const projectDate = moment(project[0].data.toDate()).format("L");

      setNameProject(project[0].nameProject);
      setCreatedDate(projectDate);

      if (project[0].link) {
        setCurrentLink(project[0].link);
      } else {
        setCurrentLink(projectId);
      }
    }
    // eslint-disable-next-line
  }, [allUserProjects]);

  return (
    <div>
      <ModalConfigLink
        isOpen={showModalConfig}
        setIsOpen={setShowModalConfig}
        projectId={projectId}
      />
      <div className="pageShare">
        <Center2Icon titlePage="Compartilhar" />
        <NavigationMenu active="share" projectId={projectId} />
        <main>
          <div className="wrapperInfo">
            <div className="infoProject">
              <span className="projectName">{nameProject}</span>
              <span className="date">Criado em {createdDate}</span>
            </div>
            <div className="boxImg">
              <img className="hazo" src={Hazo} alt="Hazo" />
            </div>
          </div>
          <div>
            <CardConfigLink
              title="Testar o projeto"
              subtitle="Simulação"
              description="Veja a experiência final que o seu cliente terá. Revise e altere, caso precise."
              text="As respostas não serão </br> armazenadas"
              textButton="Simular"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}testing/${currentLink}`,
                  "_blank"
                );
              }}
            />
          </div>
          <div>
            <CardConfigLink
              title="Configurar o meu link"
              subtitle="Configurações"
              description="Configure e compartilhe seu link."
              text={`Link ativo </br> 0 respostas coletadas`}
              textButton="Configurar"
              onClick={() => setShowModalConfig(true)}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Share;
