import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const InteractiveList = ({
  title,
  description,
  firstIcon,
  firstIconColor,
  secondIcon,
  secondIconColor,
  onClick,
}) => {
  return (
    <div className="compInteractiveList" onClick={onClick}>
      <div className="content">
        <div className="circle">
          <div className={`icon${firstIconColor}`} data-testid="first-icon">
            {firstIcon && firstIcon}
          </div>
        </div>
        <div className="text">
          <span className="title">{title}</span>
          <span className="description">{description}</span>
        </div>
      </div>
      <div className={`icon${secondIconColor}`} data-testid="second-icon">
        {secondIcon && secondIcon}
      </div>
    </div>
  );
};

InteractiveList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  firstIcon: PropTypes.element.isRequired,
  firstIconColor: PropTypes.string.isRequired,
  secondIcon: PropTypes.element.isRequired,
  secondIconColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

InteractiveList.defaultProps = {
  title: "",
  description: "",
  firstIcon: <></>,
  firstIconColor: "",
  secondIcon: <></>,
  secondIconColor: "",
  onClick: () => {},
};

export default InteractiveList;
