import React, { useState, useEffect } from "react";
import Button from "../Button";
import "./style.scss";

const CardOverview = (props) => {
  const { questions, reportData } = props;
  const [showQuestions, setShowQuestions] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleQtd = (question) => {
    let questionNumberLetter = `q_${
      question.number > 10 ? question.number : "0" + question.number
    }`;

    if (reportData[questionNumberLetter] === undefined) {
      return 0;
    }

    let objectWithTotal = reportData[questionNumberLetter].find(
      (element) => element[questionNumberLetter] === "Total"
    );

    return objectWithTotal.qtd;
  };

  const handlePercent = (question) => {
    let questionNumberLetter = `q_${
      question.number > 10 ? question.number : "0" + question.number
    }`;

    if (reportData[questionNumberLetter] === undefined) {
      return 0;
    }

    let objectWithTotal = reportData[questionNumberLetter].find(
      (element) => element[questionNumberLetter] === "Total"
    );

    let firstQuestionTotal = reportData["q_01"].find(
      (element) => element["q_01"] === "Total"
    );

    return ((objectWithTotal.qtd / firstQuestionTotal.qtd) * 100).toFixed(0);
  };

  useEffect(() => {
    if (questions !== undefined && reportData !== undefined) {
      setLoading(false);
    }
  }, [questions, reportData]);

  if (loading) {
    return null;
  } else {
    return (
      <div className="compCardOverview">
        <span className="title">Overview</span>
        <div className="header">
          <span>Pergunta</span>
          <span>Qtd. Respostas</span>
          <span>Completo</span>
          {/* <span>Tempo médio</span> */}
        </div>
        {questions.map((question, index) => {
          if (showQuestions === false && index > 9) {
            return null;
          }

          return (
            <div className="content" key={`${question.message}_${index}`}>
              <div className="question">
                <span className="title">{question.message}</span>
                <span className="qtd">{handleQtd(question)}</span>
                <span className="percent">{handlePercent(question)}%</span>
                {/* <span>00min</span> */}
              </div>
            </div>
          );
        })}
        {questions.length > 9 && (
          <Button
            onClick={() => setShowQuestions(!showQuestions)}
            size="small"
            type="auxiliary"
            variant="primary"
          >
            {showQuestions ? "Esconder" : "Ver tudo"}
          </Button>
        )}
      </div>
    );
  }
};

export default CardOverview;
