import React from "react";
import "./style.scss";
import "../style.scss";
import PropTypes from "prop-types";

import { ReactComponent as FiRrCrossSmall } from "../../../Assets/img/regular/fi-rr-cross-small.svg";
import { ReactComponent as FiRrAppsAdd } from "../../../Assets/img/regular/fi-rr-apps-add.svg";
import { ReactComponent as FiRrApps } from "../../../Assets/img/regular/fi-rr-apps.svg";
import { ReactComponent as FiRrCommentAlt } from "../../../Assets/img/regular/fi-rr-comment-alt.svg";
import { ReactComponent as FiRrBilliard } from "../../../Assets/img/regular/fi-rr-billiard.svg";
import { ReactComponent as FiRrStar } from "../../../Assets/img/regular/fi-rr-star.svg";
import { ReactComponent as FiRrThumbsUp } from "../../../Assets/img/regular/fi-rr-thumbs-up.svg";
import { ReactComponent as FiRrSmile } from "../../../Assets/img/regular/fi-rr-smile.svg";
import { ReactComponent as FiRrPicture } from "../../../Assets/img/regular/fi-rr-picture.svg";
import { ReactComponent as FiRrVideoCamera } from "../../../Assets/img/regular/fi-rr-video-camera.svg";
import { ReactComponent as FiSrLink } from "../../../Assets/img/solid/fi-sr-link.svg";
import { ReactComponent as FiRrText } from "../../../Assets/img/regular/fi-rr-text.svg";

/**
 * @typedef { true | false } isOpen
 */

/**
 * @typedef {Object} ModalOptionsProps
 * @property {isOpen} isOpen
 */

/**
 * @param {ModalOptionsProps}
 * @returns {JSX.Element}
 */

/*******************************************************************************
 * How to use:
 * <ModalOptions isOpen={true} />
 ******************************************************************************/

const ModalOptions = (props) => {
  const { isOpen, toggle, questionNumber, questionValues, setQuestionValues } =
    props;

  const options = [
    {
      description: "Uma resposta entre as opções",
      icon: <FiRrAppsAdd className="icon" data-testid="apps-add-icon" />,
      title: "Pergunta fechada",
      type: "closeAnswer",
    },
    {
      description: "Permite várias respostas entre as opções",
      icon: <FiRrApps className="icon" data-testid="apps-icon" />,
      title: "Múltipla escolha",
      type: "multipleChoice",
    },
    {
      description: "Permite textos, números, cadastros",
      icon: <FiRrCommentAlt className="icon" data-testid="comment-alt-icon" />,
      title: "Pergunta aberta",
      type: "openAnswerFreeText",
    },
    {
      description: "Permite escala de números personalizada",
      icon: <FiRrBilliard className="icon" data-testid="billiard-icon" />,
      title: "Escala numérica",
      type: "numericalScale",
    },
    {
      description: "Permite escala de estrelas personalizada",
      icon: <FiRrStar className="icon" data-testid="star-icon" />,
      title: "Escala estrela",
      type: "starThreeScale",
    },
    {
      description: "Curtiu ou não curtiu",
      icon: <FiRrThumbsUp className="icon" data-testid="thumb-sup-icon" />,
      title: "Like e dislike",
      type: "likeDislike",
    },
    {
      description: "Permite escala de emojis personalizada",
      icon: <FiRrSmile className="icon" data-testid="smile-icon" />,
      title: "Escala emoji",
      type: "emojiScale",
    },
    {
      description: "Insira a URL de uma imagem",
      icon: <FiRrPicture className="icon" data-testid="picture-icon" />,
      title: "Link de imagem",
      type: "insertImage",
    },
    {
      description: "Insira a URL de um vídeo do youtube",
      icon: (
        <FiRrVideoCamera className="icon" data-testid="video-camera-icon" />
      ),
      title: "Link de vídeo",
      type: "insertVideo",
    },
    {
      description: "Insira botoes com links",
      icon: <FiSrLink className="icon" data-testid="link-icon" />,
      title: "Menu interativo",
      type: "menuInteractive",
    },
    {
      description: "Insira um box de texto",
      icon: <FiRrText className="icon" data-testid="text-icon" />,
      title: "Parágrafo",
      type: "insertParagraph",
    },
  ];

  const handleSetQuestion = (typeOfAnswer) => {
    let arrayTemp = questionValues.questions;
    switch (typeOfAnswer) {
      case "closeAnswer":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "" }],
        };
        break;
      case "emojiScale":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "emoji-1" },
            { color: "#606c88", next: 0, value: "emoji-2" },
            { color: "#606c88", next: 0, value: "emoji-3" },
            { color: "#606c88", next: 0, value: "emoji-4" },
            { color: "#606c88", next: 0, value: "emoji-5" },
          ],
        };
        break;
      case "insertImage":
      case "insertVideo":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: "",
        };
        break;
      case "insertLink":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "", url: "" }],
        };
        break;
      case "likeDislike":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "Like" },
            { color: "#606c88", next: 0, value: "Dislike" },
          ],
        };
        break;
      case "insertParagraph":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          finish: false,
          type: typeOfAnswer,
        };
        break;
      case "menuInteractive":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            {
              color: "#606c88",
              next: 0,
              value: "",
              url: "",
              type: "link",
            },
          ],
        };
        break;
      case "multipleChoice":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "" }],
        };
        break;
      case "numericalScale":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#B72025", next: 0, value: "0" },
            { color: "#D82028", next: 0, value: "1" },
            { color: "#EE5223", next: 0, value: "2" },
            { color: "#F36E23", next: 0, value: "3" },
            { color: "#FAA723", next: 0, value: "4" },
            { color: "#FFC828", next: 0, value: "5" },
            { color: "#ECDC0D", next: 0, value: "6" },
            { color: "#E8E73D", next: 0, value: "7" },
            { color: "#C6D92F", next: 0, value: "8" },
            { color: "#AFCF3D", next: 0, value: "9" },
            { color: "#64B64F", next: 0, value: "10" },
          ],
          min: 0,
          max: 10,
        };
        break;
      case "openAnswerFreeText":
      case "openAnswerOnlyNumbers":
      case "openAnswerEmail":
      case "openAnswerPhone":
      case "openAnswerCellPhone":
      case "openAnswerCPF":
      case "openAnswerDate":
      case "openAnswerHour":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          size: 200,
          preferNotAnswer: false,
          options: [],
        };
        break;
      case "starThreeScale":
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "star-1" },
            { color: "#606c88", next: 0, value: "star-2" },
            { color: "#606c88", next: 0, value: "star-3" },
          ],
        };
        break;

      default:
        arrayTemp[questionNumber] = {
          ...arrayTemp[questionNumber],
          type: typeOfAnswer,
          error: false,
        };
        break;
    }

    setQuestionValues({
      ...questionValues,
      questions: arrayTemp,
    });
    toggle();
  };

  if (isOpen) {
    return (
      <div className="genericModal">
        <div className="compModalOptions">
          <FiRrCrossSmall
            className="closeIcon"
            data-testid="cross-small-icon"
            onClick={toggle}
          />
          <h2>Adicionar nova pergunta</h2>
          <section>
            {options.map((option, index) => {
              return (
                <div
                  className="item"
                  key={`${option.title}_${index}`}
                  onClick={() => handleSetQuestion(option.type)}
                >
                  {option.icon}
                  <div className="informationArea">
                    <p className="title">{option.title}</p>
                    <p className="description">{option.description}</p>
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ModalOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

ModalOptions.defaultProps = {
  isOpen: false,
};

export default ModalOptions;
