import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as ChevronDown } from "../../Assets/img/chevron-down.svg";
import Box from "../Box";
import Input from "../Input";
import PropTypes from "prop-types";

/*******************************************************************************
 * How to use:
 * <Dropdown
    options={options}
    selected={selected}
    setSelected={setSelected}
  />
 ******************************************************************************/

const Dropdown = (props) => {
  const { label, onlyOne, options, selected, setSelected } = props;
  const [openBox, setOpenBox] = useState(false);

  const toggleBox = () => {
    setOpenBox(!openBox);
  };

  const handleSelect = (option) => {
    if (onlyOne) {
      setSelected([option]);
      toggleBox();
      return;
    }

    if (selected.indexOf(option) === -1) {
      setSelected([...selected, option]);
    } else {
      setSelected(
        selected.filter((element) => {
          return element !== option && element;
        })
      );
    }
    toggleBox();
  };

  return (
    <div className="compDropdown" data-testid="compDropdown">
      <Input
        label={label}
        layout="default"
        placeholder="--Selecione uma opção--"
        secondIcon={
          <div onClick={toggleBox}>
            <ChevronDown data-testid="chevron-down-icon" />
          </div>
        }
        type="text"
        value={
          selected.length === 0
            ? ""
            : selected.sort().reduce((accumulator, currentValue, index) => {
                if (index === 0) {
                  return `${currentValue}`;
                }
                return `${accumulator}, ${currentValue}`;
              })
        }
      />
      <div className="boxArea">
        <Box
          open={openBox}
          options={options}
          selected={selected}
          handleSelect={handleSelect}
        />
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  onlyOne: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  label: "",
  onlyOne: false,
  options: [],
  selected: [],
  setSelected: () => {},
};

export default Dropdown;
