import React from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import Button from "../../Components/Button";
import Carousel from "../../Components/Carousel";
import Hazo from "../../Assets/img/welcome/hazo-avatar.svg";
import Intro1 from "../../Assets/img/welcome/intro-1.svg";
import Intro2 from "../../Assets/img/welcome/intro-2.svg";
import Intro3 from "../../Assets/img/welcome/intro-3.svg";
import Intro4 from "../../Assets/img/welcome/intro-4.svg";

const Welcome = () => {
  const history = useHistory();
  const slides = [
    <div className="carouselPage">
      <img
        alt="hazo"
        className="carouselImg"
        data-testid="hazo-icon"
        src={Hazo}
      />
      <p>
        O Hazo é um jeito <span>fácil e {"\n"}divertido</span> de criar
        pesquisas
      </p>
    </div>,
    <div className="carouselPage">
      <img
        alt="hazo"
        className="carouselImg"
        data-testid="intro1-icon"
        src={Intro1}
      />
      <p>
        Crie novas interações de forma{"\n"}
        <span>simples e rápida</span>
      </p>
    </div>,
    <div className="carouselPage">
      <img
        alt="hazo"
        className="carouselImg"
        data-testid="intro2-icon"
        src={Intro2}
      />
      <p>
        <span>Personalize seu chatbot</span> de {"\n"}acordo com a sua marca
      </p>
    </div>,
    <div className="carouselPage">
      <img
        alt="hazo"
        className="carouselImg"
        data-testid="intro3-icon"
        src={Intro3}
      />
      <p>
        <span> Compartilhe o link</span> original ou {"\n"} crie pontos de
        contatos
      </p>
    </div>,
    <div className="carouselPage">
      <img
        alt="hazo"
        className="carouselImg"
        data-testid="intro4-icon"
        src={Intro4}
      />
      <p>
        Tome a <span>decisão correta </span>com {"\n"}relatórios exclusivos
      </p>
    </div>,
  ];

  return (
    <div className="CompWelcome">
      <div className="header">
        <span className="lettering">HAZO</span>
        <span className="termos" onClick={() => history.push("/terms")}>
          Termos de uso
        </span>
      </div>

      <div className="content">
        <Carousel slides={slides} />
      </div>
      <div className="footer">
        <Button
          type="default"
          size="large"
          variant="primary"
          onClick={() => history.push("/signup")}
        >
          Criar conta grátis
        </Button>
        <Button
          type="auxiliary"
          size="large"
          variant="primary"
          onClick={() => history.push("/signin")}
        >
          Acessar o Hazo
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
