import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

/**
 * @typedef {'default' | 'disabled' } SwitchButtonType
 */

/**
 * @typedef {Object} SwitchButtonProps
 * @property {SwitchButtonType} type
 */

/**
 * @param {SwitchButtonProps}
 * @returns {JSX.Element}
 */

const SwitchButton = ({ active, type, onChange }) => {
  const sliderClassName = `slider ${type}`;

  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <div className="compSwitchButton">
      <label className="switch">
        <input
          type="checkbox"
          disabled={type === "disabled"}
          onChange={handleChange}
          data-testid="checkbox"
          checked={active}
        />
        <span className={sliderClassName}></span>
      </label>
    </div>
  );
};

SwitchButton.propTypes = {
  type: PropTypes.oneOf(["default", "disabled"]).isRequired,
  onChange: PropTypes.func.isRequired,
};

SwitchButton.defaultProps = {
  type: "default",
  onChange: () => {},
};

export default SwitchButton;
