import React, { useEffect, useState } from "react";
import Button from "../../Button";
import MenuEditQuestionOff from "../../MenuEditQuestionOff";
import MenuEditQuestionOn from "../../MenuEditQuestionOn";
import ModalOptions from "../../Modals/ModalOptions";
import ModalPresets2 from "../../Modals/ModalPresets2";
import { ReactComponent as FiRrAngleDown } from "../../../Assets/img/regular/fi-rr-angle-down.svg";

const DefaultQuestion = (props) => {
  const {
    addQuestion,
    currentLastQuestion,
    handleCancel,
    handleDuplicateQuestion,
    handleEdit,
    handleKeyDown,
    handleMessage,
    handleSave,
    handleSaveAndNew,
    lastQuestion,
    moveUpQuestion,
    moveDownQuestion,
    questionNumber,
    questionValues,
    removeQuestion,
    setQuestionValues,
  } = props;
  const [modalAnswersOpen, setModalAnswersOpen] = useState(false);
  const [modalPresetsOpen, setModalPresetsOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleInputToSave = () => {
    handleSave(questionNumber);
  };

  const handleInputToSaveNew = () => {
    handleSaveAndNew(questionNumber);
  };

  const handleToCancel = () => {
    handleCancel(questionNumber);
  };

  useEffect(() => {
    const validError = () => {
      if (questionValues.questions[questionNumber].message === "") {
        setError(true);
      } else if (questionValues.questions[questionNumber].type === "") {
        setError(true);
      }
    };

    if (questionValues.questions[questionNumber].error) {
      validError();
    } else {
      setError(false);
    }
    // eslint-disable-next-line
  }, [questionValues]);

  if (!questionValues.questions[questionNumber].edit) {
    return (
      <div className="defaultQuestionLayout">
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility:
              lastQuestion === questionNumber + 1 ? "visible" : "hidden",
          }}
        />
        <div className="mainAreaDefault">
          <div className="titleDefault">Pergunta {questionNumber + 1}</div>
          <div className="mainSpace">
            <p className="mainText">
              {questionValues.questions[questionNumber].message}
            </p>
          </div>
          <MenuEditQuestionOff
            onClickTrash={removeQuestion}
            onClickCopy={handleDuplicateQuestion}
            onClickArrowDown={moveDownQuestion}
            onClickArrowUp={moveUpQuestion}
            onClickEdit={handleEdit}
            onClickAdd={addQuestion}
            lastQuestion={currentLastQuestion}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="defaultQuestionLayout boxEdit">
        <ModalOptions
          questionNumber={questionNumber}
          questionValues={questionValues}
          setQuestionValues={setQuestionValues}
          isOpen={modalAnswersOpen}
          toggle={() => setModalAnswersOpen(!modalAnswersOpen)}
        />
        <ModalPresets2
          questionNumber={questionNumber}
          questionValues={questionValues}
          setQuestionValues={setQuestionValues}
          isOpen={modalPresetsOpen}
          toggle={() => setModalPresetsOpen(!modalPresetsOpen)}
        />
        <img
          alt="robot"
          className="robotIconDefault"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${questionValues.robot_image_name}`}
          style={{
            visibility: lastQuestion === 0 ? "visible" : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="mainAreaDefault">
            <div className="titleDefault">Pergunta {questionNumber + 1}</div>
            <div className="mainSpace" id={`Pergunta${questionNumber + 1}`}>
              <Button
                innerClassName="buttonPresetDefault"
                onClick={() => setModalPresetsOpen(true)}
                size="small"
                type="auxiliary"
                variant="primary"
              >
                Presets
              </Button>
              <textarea
                rows="2"
                className={
                  error
                    ? "textareaDefault textareaDefaultRed"
                    : "textareaDefault"
                }
                value={questionValues.questions[questionNumber].message}
                placeholder="Digite aqui a sua pergunta"
                onChange={(event) => {
                  handleMessage(questionNumber, event.target.value);
                  handleKeyDown(event);
                }}
              />
              <Button
                onClick={() => {
                  setModalAnswersOpen(true);
                }}
                size="small"
                type="default"
                variant="primary"
              >
                Selecione o tipo de resposta{" "}
                <FiRrAngleDown className="iconButtonSelectQuestionDefault" />
              </Button>
            </div>
            <MenuEditQuestionOn
              onClickCrossCircle={handleToCancel}
              onClickCheck={handleInputToSave}
              onClickAdd={handleInputToSaveNew}
              lastQuestion={currentLastQuestion}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default DefaultQuestion;
