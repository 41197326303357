import React from "react";
import "./style.scss";
import Button from "../../Button";
import OptionConfiguration from "../OptionConfiguration";
import { ReactComponent as FiSrFrown } from "../../../Assets/img/solid/fi-sr-frown.svg";
import { ReactComponent as FiSrSad } from "../../../Assets/img/solid/fi-sr-sad.svg";
import { ReactComponent as FiSrMeh } from "../../../Assets/img/solid/fi-sr-meh.svg";
import { ReactComponent as FiSrSmile } from "../../../Assets/img/solid/fi-sr-smile.svg";
import { ReactComponent as FiSrLaugh } from "../../../Assets/img/solid/fi-sr-laugh.svg";

const EmojiScale = (props) => {
  const {
    handleAddOption,
    handleColor,
    handleClickInput,
    handleKeyDown,
    handleOptionValue,
    handleRemoveOption,
    moveDownOption,
    moveUpOption,
    questionNumber,
    questionValues,
    setTooltipOpen,
  } = props;

  return (
    <div className="compEmoji">
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 1:</div>
        <FiSrFrown className="iconDefault emojiFrownIcon" />
      </div>
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 2:</div>
        <FiSrSad className="iconDefault emojiSadIcon" />
      </div>
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 3:</div>
        <FiSrMeh className="iconDefault emojiMehIcon" />
      </div>
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 4:</div>
        <FiSrSmile className="iconDefault emojiSmileIcon" />
      </div>
      <div className="optionConfigurationArea">
        <div className="elipse"></div>
        <div>Opção 5:</div>
        <FiSrLaugh className="iconDefault emojiLaughIcon" />
      </div>
      {questionValues.questions[questionNumber].options.map((option, index) => {
        if (index > 4) {
          return (
            <div key={index}>
              <OptionConfiguration
                option={option}
                index={index}
                questionNumber={questionNumber}
                handleColor={handleColor}
                setTooltipOpen={setTooltipOpen}
                handleOptionValue={handleOptionValue}
                handleClickInput={handleClickInput}
                handleKeyDown={handleKeyDown}
                handleRemoveOption={handleRemoveOption}
                moveDownOption={moveDownOption}
                moveUpOption={moveUpOption}
              />
            </div>
          );
        }
        return null;
      })}
      <Button
        onClick={() => handleAddOption(questionNumber)}
        size="small"
        type="auxiliary"
        variant="primary"
      >
        Adicionar Opção
      </Button>
    </div>
  );
};

export default EmojiScale;
