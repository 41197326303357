import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import "./style.scss";

const CardTemplate = ({ projectName, imageUrl, onClick }) => {
  return (
    <div
      className="compCardTemplate"
      onClick={onClick}
      data-testid="card"
    >
      <div className="header"
        style={{
          background: `url(${imageUrl}) center / cover no-repeat`,
        }}
        data-testid="header"
      >
        <Button
          className="button"
          type="default"
          variant="primary"
          size="small"
        >
          Escolher
        </Button>
      </div>
      <div className="line"></div>
      <div
        className="footerCard"
        data-testid="footer"
      >
        <span>{projectName}</span>
      </div>
    </div>
  )
}

CardTemplate.propTypes = {
  projectName: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
}

CardTemplate.defaultProps = {
  projectName: '',
  imageUrl: '',
  onClick: () => {},
}

export default CardTemplate;
