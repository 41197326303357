import React, { useEffect, useContext } from "react";
import { Context } from "../../../Context/Context";
import "./style.scss";
import Button from "../../Button";
import PropTypes from "prop-types";
import { ReactComponent as FiRrBell } from "../../../Assets/img/regular/fi-rr-bell.svg";
import { ReactComponent as FiSrMenuBurger } from "../../../Assets/img/solid/fi-sr-menu-burger.svg";
import { ReactComponent as FiRrTrash } from "../../../Assets/img/regular/fi-rr-trash.svg";
import { ReactComponent as FiRrCopy } from "../../../Assets/img/regular/fi-rr-copy.svg";
import { ReactComponent as FiRrFolder } from "../../../Assets/img/regular/fi-rr-folder.svg";
import MessagesService from "../../../Services/MessagesService";

/*******************************************************************************
 * How to use:
 * <Actions
    qtdProject="10"
    onClickTrash={() => {
      return null;
    }}
    onClickCopy={() => {
      return null;
    }}
    onClickFolder={() => {
      return null;
    }}
    onClickMenuBurger={() => {
      return null;
    }}
    onClickNotifications={() => {
      return null;
    }}
  />
 ******************************************************************************/

function Actions(props) {
  const {
    qtdProject,
    onClickTrash,
    onClickCopy,
    onClickFolder,
    onClickMenuBurger,
    onClickNotifications,
    onClickUpgrade,
  } = props;
  const {
    userMessages,
    setUserMessages,
    getQtdNewMessages,
    qtdNewMessages,
  } = useContext(Context);

  useEffect(() => {
    const getUserMessages = async() => {
      let data = await MessagesService.getMessagesByUser();
      if (data.response === "success") {
        setUserMessages(data.messages);
        getQtdNewMessages(data.messages);
      } else {
        setUserMessages([]);
        getQtdNewMessages(userMessages);
      }
    };

    getUserMessages();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="compActions">
      <div className="defaultTop">
        <span className="letteringHazo">HAZO</span>
        <Button
          size="small"
          onClick={onClickUpgrade}
          type="default"
          variant="success"
        >
          Upgrade
        </Button>
        <div className="iconsArea">
          <div className="notificationArea">
            { qtdNewMessages > 0 &&
              <div className="numberNotification">
                <span>{qtdNewMessages}</span>
              </div>
            }
            <FiRrBell
              className="icon"
              data-testid="bell-icon"
              onClick={onClickNotifications}
            />
          </div>
          <FiSrMenuBurger
            className="icon"
            data-testid="menu-burger-icon"
            onClick={onClickMenuBurger}
          />
        </div>
      </div>
      <div className="bottomsArea">
        <div className="firstArea">
          <p>Projetos selecionados: {qtdProject}</p>
        </div>
        <div className="secondArea">
          <FiRrTrash
            className="iconTrash"
            data-testid="trash-icon"
            onClick={onClickTrash}
          />
          <FiRrCopy
            className={
              qtdProject === 1
              ? "iconGray"
              : "iconGrayDisabled"
            }
            data-testid="copy-icon"
            onClick={
              qtdProject === 1
              ? onClickCopy
              : null
            }
          />
          <FiRrFolder
            className="iconGray"
            data-testid="folder-icon"
            onClick={onClickFolder}
          />
        </div>
      </div>
    </div>
  );
}

Actions.propTypes = {
  qtdProject: PropTypes.number.isRequired,
  onClickTrash: PropTypes.func.isRequired,
  onClickCopy: PropTypes.func.isRequired,
  onClickFolder: PropTypes.func.isRequired,
  onClickMenuBurger: PropTypes.func.isRequired,
  onClickNotifications: PropTypes.func.isRequired,
  onClickUpgrade: PropTypes.func.isRequired,
};

export default Actions;
