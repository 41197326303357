import React, { useState, useContext, useEffect } from "react";
import MessagesService from "../../../Services/MessagesService";
import Center2Icon from "../../TopBar/Center2Icon";
import { Context } from "../../../Context/Context";
import Notification from "../../Notification";
import Box from "../../Box";
import "./style.scss";
import { ReactComponent as NotificationIcon } from "../../../Assets/img/notification.svg";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

const ModalMessageCenter = ({ isOpen, setIsOpen }) => {
  const { userMessages, setUserMessages, getQtdNewMessages } =
    useContext(Context);
  const boxOptions = ["Todas", "Lidas", "Não lidas"];
  const [showBox, setShowBox] = useState(false);
  const [showSavedMessages, setShowSavedMessages] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Todas");
  const [contentMessages, setContentMessages] = useState([]);
  const [userMessagesFilter, setUserMessagesFilter] = useState(userMessages);
  const byDate = (a, b) => {
    return new Date(a.date) - new Date(b.date);
  };

  const handleSelectedOption = (option) => {
    setSelectedFilter(option);
  };

  const closeModal = async () => {
    setIsOpen(false);
    let data = await MessagesService.readAllMessages();

    if (data.response === "success") {
      let data = await MessagesService.getMessagesByUser();
      if (data.response === "success") {
        setUserMessages(data.messages);
        getQtdNewMessages(data.messages);
      }
    }
  };

  const handleSaveMessage = async (idMessage) => {
    const messageIndex = userMessages.findIndex(
      (message) => message.id === idMessage
    );

    if (messageIndex !== -1) {
      const { is_saved, id, ...message } = userMessages[messageIndex];
      const updatedIsSaved = parseInt(is_saved) ? 0 : 1;
      const updatedMessage = {
        is_saved: updatedIsSaved,
        id,
        ...message,
      };

      const updatedMessages = [...userMessages];
      updatedMessages[messageIndex] = updatedMessage;

      setUserMessages(updatedMessages);
      setUserMessagesFilter(updatedMessages);
      await MessagesService.saveMessage(idMessage, updatedIsSaved);
    }
  };

  const handleMessages = () => {
    const data = [];
    const filteredMessages = userMessages.filter((message) => {
      const isSaved = parseInt(message.is_saved);
      const isNewMessage = parseInt(message.is_new_message);

      switch (selectedFilter) {
        case "Lidas":
          return showSavedMessages
            ? isSaved && isNewMessage === 0
            : isNewMessage === 0;
        case "Não lidas":
          return showSavedMessages ? isSaved && isNewMessage : isNewMessage;
        default:
          return showSavedMessages ? isSaved : true;
      }
    });

    if (filteredMessages.length > 0) {
      filteredMessages.sort(byDate).map((message, index) => {
        const dateFormated = moment(message.created_at).format("DD/MM/YYYY");
        const hour = moment(message.created_at).format("HH:mm");

        data.push(
          <Notification
            key={index}
            title={message.title}
            message={message.description}
            link={message.link}
            linkName={message.link_name}
            date={`${dateFormated} - ${hour}h`}
            saved={parseInt(message.is_saved)}
            newMessage={parseInt(message.is_new_message)}
            onClickSaved={() => handleSaveMessage(message.id)}
          />
        );
        return null;
      });
    } else {
      data.push(
        <div className="divNoMessages">
          <NotificationIcon className="notificationIcon" />
          <p>
            Não há notificações. {"\n"} Verifique o filtro e tente novamente.
          </p>
        </div>
      );
    }

    return data;
  };

  useEffect(() => {
    setContentMessages(handleMessages());
    // eslint-disable-next-line
  }, [userMessages]);

  useEffect(() => {
    setContentMessages(handleMessages());
    showBox && setShowBox(false);
    // eslint-disable-next-line
  }, [userMessagesFilter, showSavedMessages, selectedFilter]);

  if (!isOpen) return null;

  return (
    <div className="comModalMessageCenter">
      <Center2Icon
        titlePage="Notificações"
        selectedBookmark={showSavedMessages}
        onClickFilter={() => setShowBox(!showBox)}
        onClickBookMark={() => setShowSavedMessages(!showSavedMessages)}
        onClickBack={() => closeModal()}
      />
      <Box
        open={showBox}
        options={boxOptions}
        selected={selectedFilter}
        handleSelect={handleSelectedOption}
        style={{ right: 20 }}
      />
      {userMessages.length > 0 ? (
        contentMessages
      ) : (
        <div className="divNoMessages">
          <NotificationIcon className="notificationIcon" />
          <p>
            Não há notificações. {"\n"} Verifique o filtro e tente novamente.
          </p>
        </div>
      )}
    </div>
  );
};

export default ModalMessageCenter;
