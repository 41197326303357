import { useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as Intro4 } from "../../Assets/img/welcome/intro-4.svg";
import CardReport from "../CardReport";
import CardOverview from "../CardOverview";
import ChartDefault from "../ChartDefault";
import ReportService from "../../Services/ReportService";
import Loading from "../Loading";

export default function Report(props) {
  const { reportData, dataFirebase, projectId } = props;
  const [dataQuestions, setDataQuestions] = useState([]);
  const [typesOfCharts, setTypeOfChart] = useState(null);
  const [reportChartsRef, setReportChartsRef] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllComments = async () => {
      let response = await ReportService.getReportCharts(projectId);

      if (!response) {
        let charts = { answers: "LineChart" };
        for (const question in dataFirebase.questions) {
          charts = {
            ...charts,
            [dataFirebase.questions[question].number]: "BarChart",
          };
        }
        await ReportService.createReportCharts(charts, projectId);
        setTypeOfChart(charts);
        response = await ReportService.getReportCharts(projectId);
        setReportChartsRef(response.docRef);
      } else {
        setTypeOfChart(response.data);
        setReportChartsRef(response.docRef);
      }

      let charts = { answers: "LineChart" };
      if (!response) {
        for (const question in dataFirebase.questions) {
          charts = {
            ...charts,
            [dataFirebase.questions[question].number]: "BarChart",
          };
        }
        setTypeOfChart(charts);
      } else {
        setTypeOfChart(response.data);
      }

      const allCommentsJson = await ReportService.getComments(response.docRef);
      setDataQuestions(allCommentsJson ? allCommentsJson : []);
      setLoading(false);
    };

    if (dataFirebase !== null && reportData !== null) {
      getAllComments(dataFirebase);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    if (reportData?.answers.day.length === 0) {
      return (
        <div className="boxNotAnswer">
          <Intro4 className="boxNotAnswerImg" />
          <p>
            Ainda não há respostas coletadas. <br />
            Tente mais tarde.
          </p>
        </div>
      );
    } else {
      return (
        <div className="chartsContainer">
          <CardReport
            dataFirebase={dataFirebase}
            projectId={projectId}
            reportData={reportData}
          />
          <CardOverview
            questions={dataFirebase.questions}
            reportData={reportData}
          />
          {dataFirebase.questions.map((element, index) => {
            return (
              <ChartDefault
                dataQuestions={dataQuestions}
                key={`${element}_${index}`}
                question={element}
                reportData={reportData}
                reportChartsRef={reportChartsRef}
                typesOfCharts={typesOfCharts}
              />
            );
          })}
        </div>
      );
    }
  }
}
