import React, { createContext, useEffect, useState } from "react";
import { Auth } from "../Services/AuthService";
import CampaignService from "../Services/CampaignService";
import DictionaryService from "../Services/DictionaryService";
import ProjectsService from "../Services/ProjectsService";
import UserService from "../Services/UserService";
import MessagesService from "../Services/MessagesService";

import Hazo from "../Assets/img/animations/hazo-avatar.png";
import Intro1 from "../Assets/img/animations/intro-1.svg";
import Intro2 from "../Assets/img/animations/intro-2.svg";
import Intro3 from "../Assets/img/animations/intro-3.svg";
import Intro4 from "../Assets/img/animations/intro-4.svg";

const Context = createContext();

const Provider = ({ children }) => {
  const [chatbot, setChatbot] = useState({
    robot_name: "Hazo, o Camaleão",
    robot_image_name: "hazo.svg",
    robot_blob: null,
    business_image_name: "lettering-hazo.svg",
    business_blob: null,
    chatbot_header_color: "#609ef1",
    disclaimer_initial: {
      value: "",
      edit: 1,
    },
    project_name: "",
    questions: [],
    multiple_answers: false,
    usedNumbers: [],
  });
  const [currentLink, setCurrentLink] = useState(null);
  const [idProject, setIdProject] = useState(null);
  const [allUserProjects, setAllUserProjects] = useState([]);
  const [userProjectsPage, setUserProjectsPage] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [favoriteProjects, setFavoriteProjects] = useState([]);
  const [userOthersProjects, setUserOthersProjects] = useState([]);
  const [favoriteOtherProjects, setFavoriteOtherProjects] = useState([]);
  const [alert, setAlert] = useState({
    type: "",
    message: "",
    active: false,
  });
  const [userProjectsQtdAnswers, setUserProjectsQtdAnswers] = useState([]);
  const [userInformation, setUserInformation] = useState(null);
  const [sheetLink, setSheetLink] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [qtdNewMessages, setQtdNewMessages] = useState(0);
  const [dataTableXlsx, setDataTableXlsx] = useState(null);
  const products = [
    {
      account: "Free",
      title: "Gratuito",
      description: "Perfeito para o uso pessoal",
      button: "Assinar plano gratuito",
      price: {
        year: 0.0,
        month: 0.0,
      },
      texts: [
        "300 Interações / Respostas",
        "Customização (Avatar, logo e cor do cabeçalho)",
        "Link Personalizado ex: hazo.me/nome-do-seu-projeto",
      ],
      textDisable: "Suporte por e-mail com resposta em até 24h",
      color: "#3DDC97",
      colorShadow: "#6BE8B5",
    },
    {
      account: "Basic",
      title: "Básico",
      description: "Recomendado para equipes pequenas",
      button: "Assinar plano básico",
      plans: {
        year: "P-75U99199JX2653440MLME4TY",
        month: "P-1753127890342753EMLBSJ7A",
      },
      price: {
        year: "49,90",
        month: "59,90",
        discount: "16%",
      },
      texts: [
        "600 Interações / Respostas",
        "Customização (Avatar, logo e cor do cabeçalho)",
        "Link Personalizado ex: hazo.me/nome-do-seu-projeto",
        "Suporte por e-mail com resposta em até 24h",
      ],
      color: "#F25DF5",
      colorShadow: "rgba(243, 22, 176, 0.60)",
    },
    {
      account: "Premium",
      title: "Premium",
      description: "Ideal para empresas de médio porte",
      button: "Assinar plano premium",
      plans: {
        year: "P-0SP333152H913493BMLME5NI",
        month: "P-2PE5116662348874LMJZKPKI",
        discount: "40%",
      },
      price: {
        year: "69,90",
        month: "99,90",
        discount: "30%",
      },
      texts: [
        "1.500 Interações / Respostas",
        "Customização (Avatar, logo e cor do cabeçalho)",
        "Link Personalizado ex: hazo.me/nome-do-seu-projeto",
        "Suporte por e-mail com resposta em até 24h",
      ],
      color: "#609EF1",
      colorShadow: "rgba(96, 158, 241, 0.60)",
    },
    {
      account: "Ultra",
      title: "Ultra",
      description: "Ideal para empresas menores",
      button: "Assinar plano ultra",
      plans: {
        year: "P-62T960193V522335YMJ5IRLA",
        month: "P-5TA2641554985942NMJZKNUY",
      },
      price: {
        year: "199,90",
        month: "335,90",
        discount: "40%",
      },
      texts: [
        "5.000 Interações / Respostas",
        "Customização (Avatar, logo e cor do cabeçalho)",
        "Link Personalizado ex: hazo.me/nome-do-seu-projeto",
        "Suporte por e-mail com resposta em até 24h",
      ],
      color: "#9B51E0",
      colorShadow: "rgba(151, 71, 255, 0.60)",
    },
    {
      account: "Enterprise",
      title: "Enterprise",
      description: "O melhor para grandes empresas",
      button: "Contato vendas",
      text: "Faltou alguma coisa?",
      texts: [
        "Entre em contato com o nosso time de vendas através do email: hazo@hazo.app. Queremos saber mais do seu projeto",
      ],
      color: "#3D3D3D",
      colorShadow: "rgba(41, 41, 41, 0.60)",
    },
  ];

  const dataDivAnimations = [
    {
      urlImage: Hazo,
      text: (
        <p className="text">
          O Hazo é um jeito <span className="decorated">fácil e divertido</span>{" "}
          de criar pesquisas
        </p>
      ),
    },
    {
      urlImage: Intro1,
      text: (
        <p className="text">
          Crie novas interações de forma{" "}
          <span className="decorated"> simples e rápida</span>
        </p>
      ),
    },
    {
      urlImage: Intro2,
      text: (
        <p className="text">
          <span className="decorated">Personalize seu chatbot</span> de acordo
          com a sua marca
        </p>
      ),
    },
    {
      urlImage: Intro3,
      text: (
        <p className="text">
          <span className="decorated">Compartilhe o link</span> original ou crie
          pontos de contatos
        </p>
      ),
    },
    {
      urlImage: Intro4,
      text: (
        <p className="text">
          Tome a <span className="decorated">decisão correta</span> com
          relatórios exclusivos
        </p>
      ),
    },
  ];

  const getProjectsInFirebase = async (userProjects) => {
    let projectsInformation = [];
    if (userInformation.subscription === "IdssManager") {
      projectsInformation = await ProjectsService.getProjectsIdss();
    }

    if (userProjects?.length > 0) {
      projectsInformation = [
        ...projectsInformation,
        { user: userInformation.email, projects: userInformation.projects },
      ];
    }

    const arrayToReturn = [];
    for (let i = 0; i < projectsInformation.length; i++) {
      let projects = await ProjectsService.getProjects(
        projectsInformation[i].projects
      );

      for (let j = 0; j < projects.length; j++) {
        arrayToReturn.push({
          user: projectsInformation[i].user,
          idProject: projects[j].id,
          nameProject: projects[j].name,
          jsonProject: projects[j].json,
          status: projects[j].status,
          multipleAnswer: projects[j].multiple_answer,
          share_final: projects[j].share_final,
          link: projects[j].link,
          data: projects[j].created_at,
          usedNumbers: projects[j].used_numbers,
          folder: projects[j].folder,
          favorited: projects[j].favorited,
        });
      }
    }
    return arrayToReturn;
  };

  const createNewIdCampaign = async () => {
    let response = await CampaignService.createNewIdCampaign();
    setIdProject(response.message);
    return response.message;
  };

  const createNewProject = async (projectName) => {
    let idNewProject = await createNewIdCampaign();
    setIdProject(idNewProject);

    let chatbotStructure = await resetChatbot(projectName);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: projectName,
      structure: chatbotStructure,
    });

    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(chatbotStructure, idNewProject);
    return idNewProject;
  };

  const createDictionary = async (chatbotToAPI, projectId) => {
    let emailToQuery = userInformation.email;
    if (userInformation.subscription === "IdssManager") {
      let response = await ProjectsService.getOwnerProject(
        JSON.stringify({
          idProject: projectId,
        })
      );
      emailToQuery = response.user;
    }

    setChatbot(chatbotToAPI);
    await DictionaryService.createNewDictionary(
      JSON.stringify({
        email: emailToQuery,
        idproject: projectId,
        logic: chatbotToAPI,
      })
    );
  };

  const resetChatbot = async (projectName = "") => {
    let chatbotStructure = {
      robot_name: "Hazo, o Camaleão",
      robot_image_name: "hazo.svg",
      business_image_name: "lettering-hazo.svg",
      chatbot_header_color: "#609ef1",
      disclaimer_initial: {
        value: "",
        edit: 1,
      },
      project_name: projectName,
      questions: [],
    };
    await setChatbot(chatbotStructure);

    return chatbotStructure;
  };

  const saveProject = async (projectId, jsonProject, nameProject) => {
    await ProjectsService.saveProject(projectId, jsonProject, nameProject);
  };

  const duplicateProject = async (project, projectName) => {
    let response = await CampaignService.createNewIdCampaign();
    let idNewProject = response.message;
    let objectProject = JSON.parse(project.jsonProject);

    setIdProject(idNewProject);
    setChatbot(objectProject);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: projectName,
      structure: objectProject,
    });
    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(objectProject, idNewProject);

    let arrayProjects = [...userInformation.projects, idNewProject];
    setUserInformation({
      ...userInformation,
      projects: arrayProjects,
    });

    return idNewProject;
  };

  const duplicateTemplate = async (template, templateName) => {
    let response = await CampaignService.createNewIdCampaign();
    let idNewProject = response.message;
    let objectTemplate = JSON.parse(template.json);

    setIdProject(idNewProject);
    setChatbot(objectTemplate);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: templateName,
      structure: objectTemplate,
    });
    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(objectTemplate, idNewProject);

    let arrayProjects = [...userInformation.projects, idNewProject];
    setUserInformation({
      ...userInformation,
      projects: arrayProjects,
    });

    return idNewProject;
  };

  const updateUserInformation = async () => {
    let userInformation = await UserService.getUserInformation();
    setUserInformation(userInformation);
  };

  useEffect(() => {
    const getUserData = async () => {
      let userInformation = await UserService.getUserInformation();
      setUserInformation(userInformation);
    };

    const getUserMessages = async () => {
      let data = await MessagesService.getMessagesByUser();
      setUserMessages(data.messages);
      getQtdNewMessages(data.messages);
    };

    Auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        getUserData();
        getUserMessages();
      }
    });
  }, []);

  useEffect(() => {
    const getAllUserProjects = async () => {
      const projectsInFirebase = await getProjectsInFirebase(
        userInformation?.projects
      );
      setAllUserProjects(projectsInFirebase);
    };

    if (userInformation) {
      getAllUserProjects();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  const resetAllInformations = async () => {
    setChatbot({});
    setIdProject(null);
    setAllUserProjects([]);
    setUserProjectsPage([]);
    setUserProjects([]);
    setFavoriteProjects([]);
    setUserOthersProjects([]);
    setFavoriteOtherProjects([]);
    setAlert({});
    setUserProjectsQtdAnswers([]);
    setUserInformation({});
    setSheetLink("");
    setUserMessages([]);
    setQtdNewMessages(0);
    setDataTableXlsx(null);
  };

  const handleTotalInteractions = () => {
    switch (userInformation?.subscription) {
      case "Free":
      case "Founder":
        return 300;
      case "Basic":
        return 600;
      case "Premium":
      case "Idss":
        return 1500;
      case "IdssUltra":
      case "Ultra":
        return 5000;
      case "Enterprise":
      case "IdssManager":
        return "ilimitado";
      default:
        break;
    }
  };

  const getQtdNewMessages = (messages) => {
    let qtd = 0;
    for (let i = 0; i < messages.length; i++) {
      if (parseInt(messages[i].is_new_message)) {
        qtd += 1;
      }
    }
    setQtdNewMessages(qtd);
  };

  return (
    <Context.Provider
      value={{
        chatbot,
        userProjects,
        getProjectsInFirebase,
        createNewProject,
        createDictionary,
        idProject,
        resetChatbot,
        setChatbot,
        userInformation,
        sheetLink,
        setSheetLink,
        setIdProject,
        setUserInformation,
        saveProject,
        userProjectsQtdAnswers,
        duplicateProject,
        duplicateTemplate,
        resetAllInformations,
        handleTotalInteractions,
        updateUserInformation,
        setUserProjects,
        userMessages,
        setUserMessages,
        allUserProjects,
        setAllUserProjects,
        qtdNewMessages,
        setQtdNewMessages,
        getQtdNewMessages,
        dataTableXlsx,
        setDataTableXlsx,
        products,
        dataDivAnimations,
        favoriteProjects,
        setFavoriteProjects,
        userOthersProjects,
        setUserOthersProjects,
        favoriteOtherProjects,
        setFavoriteOtherProjects,
        userProjectsPage,
        setUserProjectsPage,
        alert,
        setAlert,
        currentLink,
        setCurrentLink,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Provider, Context };
