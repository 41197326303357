import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../../Context/Context";
import ModalDefault from "../ModalDefault";
import Input from "../../Input";
import { ReactComponent as Check } from "../../../Assets/img/inputcheck.svg";

const ModalDuplicateProject = ({
  isOpen,
  setIsOpen,
  project,
  onSuccessDuplicate,
}) => {
  const {
    duplicateProject,
    setAlert,
  } = useContext(Context);
  const [newProjectName, setNewProjectName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [statusInput, setStatusInput] = useState("success");
  const [description, setDescription] = useState(`Para prosseguir com a cópia do projeto, nomeie o novo projeto.`);
  const buttons = [
    {
      name: "Cancelar",
      color: "primary",
      type: "auxiliary",
      onClick: () => onClickCancel(),
    },
    {
      name: "Duplicar",
      color: buttonDisabled ? "disable" : "primary",
      type: "default",
      onClick: () => onClickConfirm(),
    }
  ];

  const onClickCancel = () => {
    setIsOpen(false);
    setNewProjectName(`Copia ${project?.nameProject}`);
  }

  const onClickConfirm = () => {
    setIsOpen(false);
    handleDuplicateProject();
  }

  const handleTextInput = (text) => {
    setNewProjectName(text);

    if(text === "") {
      setStatusInput("error");
      setButtonDisabled(true);
    } else {
      setStatusInput("success");
      setButtonDisabled(false);
    }
  }

  const handleDuplicateProject = async() => {
    await duplicateProject(project, newProjectName).then(() => {
      onSuccessDuplicate();
      setAlert({
        active: true,
        type: "Success",
        message: "Projeto duplicado com sucesso!"
      });
    }).catch(() => {
      setAlert({
        active: true,
        type: "Error",
        message: "Erro ao duplicar o projeto! Tente novamente mais tarde..."
      });
    })
  }

  useEffect(() => {
    setNewProjectName(`Copia ${project?.nameProject}`);
    setDescription(`Para prosseguir com a cópia do projeto <b>${project?.nameProject}</b> nomeie o novo projeto.`);
  },[project])

  return (
    <ModalDefault
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttons={buttons}
      modalColor="primary"
      title="Duplicar projeto?"
      description={description}
    >
      <Input
        type="text"
        layout={statusInput}
        value={newProjectName}
        setValue={handleTextInput}
        placeholder="ex: Nome do projeto"
        secondIcon={
          statusInput === "success"
          ? <Check className="icon" />
          : null
        }
      />
    </ModalDefault>
  )
}

export default ModalDuplicateProject;
