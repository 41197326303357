import React from "react";
import "./style.scss";
import Button from "../Button";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ReactComponent as FiSrAngleRight } from "../../Assets/img/solid/fi-sr-angle-right.svg";
import { ReactComponent as FiRrHome } from "../../Assets/img/regular/fi-rr-home.svg";
import { ReactComponent as FiRrPaintBrush } from "../../Assets/img/regular/fi-rr-fill.svg";
import { ReactComponent as FiRrComment } from "../../Assets/img/regular/fi-rr-comment-alt.svg";
import { ReactComponent as FiRrShare } from "../../Assets/img/regular/fi-rr-share.svg";
import { ReactComponent as FiRrSignalAlt } from "../../Assets/img/regular/fi-rr-signal-alt.svg";

const NavigationMenu = (props) => {
  const { projectId, active } = props;
  const history = useHistory();

  return (
    <nav className="compNavigationMenu">
      <Button
        onClick={() => history.push(`/projects`)}
        size="small"
        type={active === "main" ? "default" : "auxiliary"}
        variant="primary"
      >
        <FiRrHome
          className={
            active === "main" ? "iconNavigationSelected" : "iconNavigation"
          }
        />
      </Button>
      <FiSrAngleRight className="icon" data-testid="angle-right-icon1" />
      <Button
        onClick={() => history.push(`/customization/${projectId}`)}
        size="small"
        type={active === "customization" ? "default" : "auxiliary"}
        variant="primary"
      >
        <FiRrPaintBrush
          className={
            active === "customization"
              ? "iconNavigationSelected"
              : "iconNavigation"
          }
        />
      </Button>
      <FiSrAngleRight className="icon" data-testid="angle-right-icon2" />
      <Button
        onClick={() => history.push(`/botmaker/${projectId}`)}
        size="small"
        type={active === "botmaker" ? "default" : "auxiliary"}
        variant="primary"
      >
        <FiRrComment
          className={
            active === "botmaker" ? "iconNavigationSelected" : "iconNavigation"
          }
        />
      </Button>
      <FiSrAngleRight className="icon" data-testid="angle-right-icon3" />
      <Button
        onClick={() => history.push(`/share/${projectId}`)}
        size="small"
        type={active === "share" ? "default" : "auxiliary"}
        variant="primary"
      >
        <FiRrShare
          className={
            active === "share" ? "iconNavigationSelected" : "iconNavigation"
          }
        />
      </Button>
      <FiSrAngleRight className="icon" data-testid="angle-right-icon4" />
      <Button
        onClick={() => history.push(`/report/${projectId}`)}
        size="small"
        type={active === "report" ? "default" : "auxiliary"}
        variant="primary"
      >
        <FiRrSignalAlt
          className={
            active === "report" ? "iconNavigationSelected" : "iconNavigation"
          }
        />
      </Button>
    </nav>
  );
};

NavigationMenu.propTypes = {
  projectId: PropTypes.string.isRequired,
  active: PropTypes.oneOf([
    "customization",
    "botmaker",
    "main",
    "report",
    "share",
  ]).isRequired,
};

NavigationMenu.defaultProps = {
  projectId: "",
  active: "",
};

export default NavigationMenu;
