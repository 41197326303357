import "./style.scss";
import Button from "../Button";
import PropTypes from "prop-types";

import { ReactComponent as FiRrAdd } from "../../Assets/img/regular/fi-rr-add.svg";

/*******************************************************************************
 * How to use
 * <BottomTabNewLink
    onClickNewQuestion={() => {}}
    value={50}
  />
 ******************************************************************************/

const BottomTabNewLink = (props) => {
  const { value, onClickNewQuestion } = props;

  return (
    <div className="compBottomTabNewLink">
      <div className="textArea">Links variáveis: {value}</div>
      <Button
        dataTestid="add-icon"
        name="Novo Link"
        onClick={onClickNewQuestion}
        size="medium"
        type="default"
        variant="primary"
      >
        <FiRrAdd className="icon" />
        Novo Link
      </Button>
    </div>
  );
};

BottomTabNewLink.propTypes = {
  value: PropTypes.number.isRequired,
  onClickNewQuestion: PropTypes.func.isRequired,
};

BottomTabNewLink.defaultProps = {
  value: 0,
  onClickNewQuestion: () => {},
};

export default BottomTabNewLink;
