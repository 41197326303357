import React, { useRef } from "react";
import "./style.scss";
import Input from "../../Input";
import { ReactComponent as FiSrArrowDown } from "../../../Assets/img/solid/fi-sr-arrow-down.svg";
import { ReactComponent as FiSrArrowUp } from "../../../Assets/img/solid/fi-sr-arrow-up.svg";
import { ReactComponent as FiRrTrash } from "../../../Assets/img/regular/fi-rr-trash.svg";

const OptionConfiguration = (props) => {
  const {
    handleClickInput,
    handleColor,
    handleOptionValue,
    handleRemoveOption,
    index,
    moveDownOption,
    moveUpOption,
    option,
    questionNumber,
  } = props;
  const colorInputRef = useRef(null);

  const handleBackgroundHexColor = (hexcolor) => {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    handleColor(questionNumber, index, hexcolor, textColor);
  };

  const handleOnChange = (value) => {
    handleOptionValue(questionNumber, index, value);
  };

  return (
    <div className="compOptionConfiguration">
      <div className="elipse"></div>
      <div className="clearInputBackground">
        <Input
          layout="default"
          placeholder="Digite a opção..."
          setValue={handleOnChange}
          type="text"
          value={option.value}
        />
      </div>
      <div
        className="showColor"
        onClick={() => handleClickInput("inputColor" + index)}
        style={{ backgroundColor: option.color }}
      >
        <input
          id={"inputColor" + index}
          ref={colorInputRef}
          onChange={(e) => handleBackgroundHexColor(e.target.value)}
          type="color"
          value={option.color}
        />
      </div>
      <FiSrArrowDown
        className="iconOptionConfiguration"
        onClick={() => moveDownOption(questionNumber, index)}
      />
      <FiSrArrowUp
        className="iconOptionConfiguration"
        onClick={() => moveUpOption(questionNumber, index)}
      />
      <FiRrTrash
        className="iconOptionConfiguration"
        onClick={() => handleRemoveOption(questionNumber, index)}
      />
    </div>
  );
};

export default OptionConfiguration;
