import React, { useState } from "react";
import "./style.scss";

import Button from "../../Button";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import PropTypes from "prop-types";

/**
 * @typedef { true | false } isOpen
 */

/**
 * @typedef {Object} ModalAdvancedLinksProps
 * @property {isOpen} isOpen
 */

/**
 * @param {ModalAdvancedLinksProps}
 * @returns {JSX.Element}
 */

/*******************************************************************************
 * How to use:
 * <ModalAdvancedLinks isOpen={true} />
 ******************************************************************************/

const ModalAdvancedLinks = (props) => {
  const { isOpen, setIsOpen } = props;
  const [gSheetLink, setGSheetLink] = useState("");
  const [selected, setSelected] = useState([]);
  const options = [
    "Código embed",
    "Email",
    "Facebook",
    "Link",
    "Linkedin",
    "QR Code",
    "SMS",
    "Twitter",
    "WhatsApp",
  ];

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (isOpen) {
    return (
      <div className="wrapper">
        <div className="compModalAdvancedLinks">
          <h2>Adicionar link avançado</h2>
          <Input
            label="Nome do ponto de contato"
            layout="default"
            placeholder="Ex: Landing page"
            setValue={setGSheetLink}
            type="text"
            value={gSheetLink}
          />
          <Input
            label="Descrição (Opcional)"
            layout="default"
            placeholder="Ex: Essa pesquisa estará na LP da..."
            setValue={setGSheetLink}
            type="text"
            value={gSheetLink}
          />
          <div className="urlArea">
            <p>https://hazo.me/</p>
            <Input
              label="URL"
              layout="default"
              placeholder="Ex: projeto"
              setValue={setGSheetLink}
              type="text"
              value={gSheetLink}
            />
          </div>
          <Dropdown
            data-testid="dropdown-element"
            label="Escolha o ponto de contato"
            options={options}
            selected={selected}
            setSelected={setSelected}
          />

          <div className="buttonArea">
            <Button
              type="auxiliary"
              size="medium"
              variant="primary"
              onClick={toggle}
            >
              Cancelar
            </Button>
            <Button
              type="default"
              size="medium"
              variant="primary"
              onClick={toggle}
            >
              Adicionar link
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

ModalAdvancedLinks.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

ModalAdvancedLinks.defaultProps = {
  isOpen: false,
};

export default ModalAdvancedLinks;
