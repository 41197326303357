import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwitchButton from "../SwitchButton";
import FieldCard from "../FieldCard";
import Button from "../Button";
import "./style.scss";

const CardMyLink = ({
  title,
  description,
  link,
  setLink,
  linkStock,
  setLinkStock,
  infoText,
  buttonName,
  switchActive,
  switchValue,
  showLink,
  onClickButton,
  onClickToogle,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])

  return (
    <div className="compCardMyLink">
      <div className="header">
        <div>
          <span className="title">{title}</span>
        </div>
        <div className="toogle">
          <SwitchButton
            type="default"
            active={switchActive}
            value={switchValue}
            onChange={onClickToogle}
          />
          <span>Ativo</span>
        </div>
      </div>
      { showLink &&
        <div className="link">
          <span>https://hazo.me/</span>
          <FieldCard
            className="inputLink"
            size={
              windowWidth <= 768
              ? "small"
              : "medium"
            }
            link={link}
            setLink={setLink}
            linkStock={linkStock}
            setLinkStock={setLinkStock}
            disabled={!switchActive}
          />
        </div>
      }
      <div>
        <span className="description">{description}</span>
      </div>
      <div className="footer">
        <span className="infoText">{infoText}</span>
        <Button
          type="default"
          variant={switchActive ? "primary" : "disable"}
          size={ windowWidth <= 768 ? "small" : "medium"}
          onClick={onClickButton}
        >
          {buttonName}
        </Button>
      </div>
    </div>
  )
}

CardMyLink.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  buttonName: PropTypes.string.isRequired,
}

CardMyLink.defaultProps = {
  title: '',
  description: '',
  infoText: '',
  buttonName: 'Compartilhar',
}

export default CardMyLink;
