import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../Context/Context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Center2Icon from "../../TopBar/Center2Icon";
import SwitchPrice from "../../SwitchPrice";
import CardPlan from "../../CardPlan";
import ModalDefault from "../ModalDefault";
import "./style.scss";
import "swiper/swiper-bundle.min.css";

const ModalUpgrade = ({ isOpen, setIsOpen }) => {
  const { products } = useContext(Context);
  const [option, setOption] = useState("anual");
  const [contentCards, setContentCards] = useState([]);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    setOption("anual");
  };

  const handleContentCards = () => {
    const dataToReturn = [];

    dataToReturn.push(
      <div className="contentCards">
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true }}
          direction="horizontal"
          initialSlide={2}
          className="swiper"
        >
          {products.map((plan, index) => (
            <SwiperSlide key={plan.account + index} className="cardUpgrade">
              <CardPlan
                typePlan={plan.account}
                typePrice={option === "anual" ? "year" : "month"}
                handleUserHavePlan={() => setShowModalWarning(true)}
                onError={() => setShowModalError(true)}
                key={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );

    return dataToReturn;
  };

  useEffect(() => {
    setContentCards(handleContentCards());
    // eslint-disable-next-line
  }, [option]);

  if (!isOpen) return null;

  return (
    <>
      <ModalDefault
        isOpen={showModalWarning}
        setIsOpen={setShowModalWarning}
        modalColor="primary"
        title="Atenção"
        description="Você já assinou este plano"
        buttons={[
          {
            type: "default",
            color: "primary",
            name: "Entendi",
            onClick: () => setShowModalWarning(false),
          },
        ]}
      ></ModalDefault>
      <ModalDefault
        isOpen={showModalError}
        setIsOpen={setShowModalError}
        modalColor="cancel"
        title="Erro"
        description="Lamento aconteceu um problema"
        buttons={[
          {
            type: "default",
            color: "cancel",
            name: "Ok",
            onClick: () => setShowModalError(false),
          },
        ]}
      ></ModalDefault>
      <div className="compModalUpgrade">
        <header>
          <Center2Icon titlePage="Upgrade" onClickBack={closeModal} />
        </header>
        <main>
          <SwitchPrice selected={option} setSelected={setOption} />
          <p className="text">
            Potencialize seus resultados e {"\n"}
            tenha maior alcance
          </p>
          {contentCards}
        </main>
      </div>
    </>
  );
};

export default ModalUpgrade;
