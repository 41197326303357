import React from "react";
import { ReactComponent as FiSrArrowDown } from "../../Assets/img/solid/fi-sr-arrow-down.svg";
import { ReactComponent as FiSrStar } from "../../Assets/img/solid/fi-sr-star.svg";
import { ReactComponent as FiSrShumbsDown } from "../../Assets/img/solid/fi-sr-thumbs-down.svg";
import { ReactComponent as FiSrShumbsUp } from "../../Assets/img/solid/fi-sr-thumbs-up.svg";
import Emoji1 from "../../Assets/img/emoji-1.svg";
import Emoji2 from "../../Assets/img/emoji-2.svg";
import Emoji3 from "../../Assets/img/emoji-3.svg";
import Emoji4 from "../../Assets/img/emoji-4.svg";
import Emoji5 from "../../Assets/img/emoji-5.svg";
import "./style.scss";

const Logic = (props) => {
  const handleNext = (option) => {
    let next = "";

    if (option.next === 0 || option.type === "multipleChoice") {
      next = "Próxima pergunta";
    } else {
      const array = props.questionValues.questions.filter(
        ({ number }) => number === option.next
      );
      if (array[0] !== undefined) {
        const newMessage = labelsAdjusted(array[0]);
        const questionNumber =
          props.questionValues.questions.indexOf(array[0]) + 1;
        next = ` Q-${questionNumber}: ${newMessage}`;
      } else {
        next = "Despedida";
      }
    }
    return <div>{next}</div>;
  };

  const labelsAdjusted = (array) => {
    if (array.message.length > 15) {
      const newArr = array.message.slice(0, 15).split(" ");
      return [
        (newArr.slice(0, 2) + " ").replace(",", " "),
        (newArr.slice(2, 4) + "...").replace(",", " "),
      ];
    }
    return array.message;
  };

  const handleType = (option, questionNumber) => {
    switch (option.type) {
      case "insertImage":
        return (
          <div className="imageContainer">
            <img
              alt="Mídia enviada pelo usuário"
              src={props.questionValues.questions[questionNumber].options}
            />
          </div>
        );
      case "insertVideo":
        return (
          <div className="imageVideoContainer">
            <iframe
              title="Mídia enviada pelo usuário"
              width="300"
              height="200"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              src={
                props.questionValues.questions[questionNumber].options
                  .replace("watch?v=", "embed/")
                  .split("&")[0]
              }
            />
          </div>
        );
      case "starThreeScale":
      case "starFiveScale":
        return option.options.map((option, index) => {
          if (option.value.match(/^star-[0-9]$/)) {
            let starQuantity = [];
            const starNumber = option.value.split("-")[1];
            for (let i = 0; i < starNumber; i++) {
              starQuantity.push(
                <FiSrStar className="iconDefault starIcon" key={i} />
              );
            }
            return (
              <div
                key={index}
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {starQuantity}
              </div>
            );
          } else {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {option.value}
              </div>
            );
          }
        });
      case "likeDislike":
        return option.options.map((option) => {
          const value = option.value.toLowerCase();
          const emojiOptions = ["like", "dislike"];
          if (emojiOptions.includes(value) === false)
            return (
              <div
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
                {option.value}
              </div>
            );

          const isLike = option.value === "Like";
          return (
            <div
              style={{
                backgroundColor: option.color,
              }}
              className="showOptionsButton"
            >
              { isLike
                ? <FiSrShumbsUp className="iconDefault likeIcon" />
                : <FiSrShumbsDown className="iconDefault dislikeIcon" />
              }
            </div>
          );
        });
      case "emojiScale":
        return option.options.map((option, index) => {
          const emojiImages = [Emoji1, Emoji2, Emoji3, Emoji4, Emoji5];
          if (index >= 0 && index < emojiImages.length) {
            return (
              <div
                style={{
                  backgroundColor: option.color,
                }}
                className="showOptionsButton"
              >
              <img alt={`Emoji ${index + 1}`} src={emojiImages[index]} />
            </div>
            )
          }
        });
      case "closeAnswer":
      case "multipleChoice":
      case "menuInteractive":
      case "numericalScale":
        return option.options.map((option, index) => {
          return (
            <div
              key={index}
              className="showOptionsButton"
              style={{
                backgroundColor: option.color,
              }}
            >
              {option.value}
            </div>
          );
        });
      default:
        return;
    }
  };

  const handleQuestionType = (element) => {
    const typeMappings = {
      openAnswerCellPhone: "Pergunta Aberta",
      openAnswerCPF: "Pergunta Aberta",
      openAnswerDate: "Pergunta Aberta",
      openAnswerEmail: "Pergunta Aberta",
      openAnswerFreeText: "Pergunta Aberta",
      openAnswerHour: "Pergunta Aberta",
      openAnswerOnlyNumbers: "Pergunta Aberta",
      openAnswerPhone: "Pergunta Aberta",
      starThreeScale: "Escala Estrela",
      starFiveScale: "Escala Estrela",
      emojiScale: "Escala Emoji",
      closeAnswer: "Pergunta Fechada",
      insertImage: "Inserir Link Imagem",
      insertVideo: "Inserir Link Video",
      insertParagraph: "Inserir Parágrafo",
      likeDislike: "Like e Dislike",
      menuInteractive: "Menu Interativo com Links",
      multipleChoice: "Múltipla Escolha",
      numericalScale: "Escala Numérica",
    };

    return typeMappings[element] || "Pergunta Desconhecida";
  };

  const handleContentDisclaimer = (message) => {
    return (
      <div className="compLogic">
        <img
          alt="robot"
          className="robotIconDefault text"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
        />
        <div className="questionArea">
          <div className="mainArea">
            <div className="mainSpace">
              <p
                className="mainText"
                style={{ whiteSpace: "pre-line" }}
              >
                {message}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {handleContentDisclaimer(
        props.questionValues.disclaimer_initial.value
      )}
      { props.questionValues.questions.map((question, index) => {
        let groupByNext = [];
        if (typeof question.options === "object") {
          question.options.forEach((option) => {
            const existingGroup = groupByNext.find((x) => x.next === option.next);
            if (existingGroup) {
              existingGroup.options.push(option);
            } else {
              groupByNext.push({
                next: option.next,
                options: [option],
                type: question.type,
              });
            }
          });
        } else {
          groupByNext.push({
            next: 0,
            options: question.options,
            type: question.type,
          });
        }

        return (
          question.finish
          ? handleContentDisclaimer(question.message) :
          <div key={index} className="compLogic">
            <img
              alt="robot"
              className="robotIconDefault"
              src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
            />
            <div className="questionArea">
              <div className="mainArea">
                <div className="title">
                  {`Pergunta ${index + 1} -
                  ${handleQuestionType(question.type)}`}
                </div>
                <div className="mainSpace">
                  <p
                    className="mainText"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {question.message}
                  </p>
                  { !question.type.includes("openAnswer") &&
                    <div className="mainOptionsArea">
                      {groupByNext.map((option) => {
                        return (
                          <div className="sectionOption">
                            <div className="option">{handleType(option, index)}</div>
                            <FiSrArrowDown
                              className="icon"
                            />
                            <div className="show-next">{handleNext(option)}</div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Logic;
